import React, { useEffect, useState } from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

function MapDirections({ location, destination }) {
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();

    const origin = {
      lat: location.latitude || -17.8323456,
      lng: location.longitude || 31.04768,
    };
    const geoDestination = {
      lat: destination?.latitude,
      lng: destination?.longitude,
    };

    console.log("destination: ", geoDestination);
    console.log("origin: ", origin);

    directionsService.route(
      {
        origin: origin,
        destination: geoDestination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, destination]);

  return (
    <>
      <GoogleMap zoom={10} mapContainerStyle={{ height: "51vh" }}>
        <DirectionsRenderer directions={directions} />
      </GoogleMap>
      <br />
    </>
  );
}

export default MapDirections;
