import { call, put, takeEvery } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import getPaymentTypeRoutine from "./routines";

const axios = require("axios");

/**
 * ============================================================
 * @param {Get Payment Types}
 */
function* getPaymentTypes(action) {
  const { currency } = action.payload;
  const config = getConfig();

  const paymentTypeUrl = `${config.api.base_url}payment/types/${currency}/`;

  const headers = {
    headers: {
      accept: "application/json",
    },
    mode: "cors",
  };

  try {
    // trigger request action
    yield put(getPaymentTypeRoutine.request());

    const response = yield call(axios.get, paymentTypeUrl, headers);
    yield put(getPaymentTypeRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(getPaymentTypeRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(getPaymentTypeRoutine.fulfill());
  }
}

function* paymentSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getPaymentTypeRoutine.TRIGGER, getPaymentTypes);
}

export default paymentSaga;
