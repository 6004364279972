import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/**
 * @summary Render Products Handle component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const IndustryItems = (props) => {
  const { categoryName, picture_1, picture_2 } = props;
  const merchatUrl = `/merchants/${categoryName}/`;
  return (
    <>
      <div class="col-12 col-md-6 col-lg-4 mt-4">
        <Link to={merchatUrl} className="text-decoration-none">
          <div class="category-card-bg text-start box-shadow-2">
            <div
              className="category-as-bg p-4"
              style={{
                backgroundImage: "url(" + picture_1 + ")",
                backgroundPosition: "bottom right",
                backgroundRepeat: "no-repeat",
              }}
            >
              <a
                className="text-white fw-700 d-block mb-5 fs-20px text-decoration-none"
                href
              >
                {categoryName}
                <img
                  className="category-chevrons ms-2 d-none d-sm-inline"
                  src="./assets/shop/img/landing-page-categories/chevrons.png"
                  alt=""
                  height="16"
                />
              </a>

              <img
                className="category-card-icon mt-5"
                alt=""
                src={picture_2}
                height={50}
              />
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

IndustryItems.defaultProps = {};

IndustryItems.propTypes = {
  categroryID: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  picture_1: PropTypes.string.isRequired,
  picture_2: PropTypes.string.isRequired,
};

export default IndustryItems;
