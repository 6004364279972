import React from "react";
import PropTypes from "prop-types";
import Transaction from "../transaction";
import axios from "axios";
import getConfig from "config";

const Transactions = (props) => {
  const { transactions } = props;

  const config = getConfig();
  let transaction_list = [];
  let transactionUrl = "";
  if (transactions) {
    const arrayLength = transactions.data;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      transaction_list.push(transactions.data[i].transaction_id);
    }
    transaction_list = transactions.data;
    transactionUrl = transactions.next;
  }

  const getMoreTransactions = () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.api.token}`,
      },
      mode: "cors",
    };
    axios
      .get(transactionUrl, headers)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="card border-0 box-shadow-1 mt-5">
        <div className="card-body p-5">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="fw-800 text-green-2">Recent Activity</h3>
            <div className="mw-cs-title-line w-50 d-none d-md-inline-block bg-green-2"></div>
          </div>
          <div className="table-responsive mt-4">
            <table className="table table-borderless table-striped">
              <tbody>
                {!!transaction_list &&
                  transaction_list.map((transaction) => {
                    return (
                      <Transaction
                        key={transaction.transaction_id}
                        date={transaction.date}
                        amount={transaction.amount}
                        transaction_type={transaction.transaction_type}
                        reference={transaction.reference_trans}
                      />
                    );
                  })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
              <h5 className="text-green-3 fw-800 mt-3"></h5>

              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href
                onClick={getMoreTransactions}
                className="text-decoration-none"
              >
                <h5 className="text-green-3 fw-800 mt-3">
                  Show More
                  <img
                    className="ms-2"
                    src="assets/img/icons/wallet/chevron-down-green.svg"
                    alt=""
                    width="16"
                  />
                </h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Transactions.defaultProps = {};

Transactions.propTypes = {
  transactions: PropTypes.array,
};

export default Transactions;
