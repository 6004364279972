import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import getPaymentTypeRoutine from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
  reloadRequested: false,
});

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * =============================================================
     * GET PAYMENT TYPES
     * =============================================================
     */
    case getPaymentTypeRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case getPaymentTypeRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case getPaymentTypeRoutine.SUCCESS:
      return state.merge({
        payment_types: action.payload.data.payload,
        containerState: STATE_OK,
      });
    case getPaymentTypeRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
      });
    /**
     * =============================================================
     * END OF GET PAYMENTS
     * =============================================================
     */
    default:
      return state;
  }
}
