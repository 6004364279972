import React from "react";
import { Form } from "antd";
import { Link } from "react-router-dom";

const ForgotPassword = (props) => {

  return (
    <>
      {/* {emptyMessage} */}

      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label text-green-2 fw-700">Email Address</label>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Enter email address!" }]}
            >
              <input
                type="email"
                class="form-control shadow-none border-2-green bg-transparent"
                placeholder="Enter Email Address"
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div class="text-center mb-3">
        <small class="fs-12px">
          Already have an account?{" "}
          <Link to="/login" class="fw-800 text-green-2 text-decoration-none">
            Login
          </Link>
        </small>
      </div>
      <button
        type="submit"
        // onClick={onSubmit}
        class="btn btn-warning w-100 text-uppercase
                    text-white fw-800 letter-spacing-3 orange-gradient-bg-1"
      >
        Reset
      </button>
    </>
  );
};

ForgotPassword.defaultProps = {};

ForgotPassword.propTypes = {
};

export default ForgotPassword;
