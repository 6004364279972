import { call, put, takeEvery } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import getIndustriesRoutine from "./routines";

const axios = require("axios");

function* fetchIndustry(action) {
  const config = getConfig();

  const industryUrl = `${config.api.base_url}merchant/industries/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  try {
    // trigger request action
    yield put(getIndustriesRoutine.request());

    const response = yield call(axios.get, industryUrl, headers);
    yield put(getIndustriesRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(getIndustriesRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(getIndustriesRoutine.fulfill());
  }
}

function* industrySaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getIndustriesRoutine.TRIGGER, fetchIndustry);
}

export default industrySaga;
