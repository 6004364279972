import React from "react";

import PropTypes from "prop-types";
const PaymentDetails = (props) => {
  const { paymentPayload } = props;

  let amount;
  let billpaymentName;
  let phone_number;
  let country;
  let productName;
  let accountNumber;
  let paymentDetails = [];
  let rates = {};
  let currency;
  let amountCurrency;
  let email;
  let receiver_name;
  let national_id;
  let address;

  if (Object.keys(paymentPayload).length > 0) {
    currency = paymentPayload["currency"];
    amount = paymentPayload["amount"];
    phone_number = paymentPayload["phone_number"];
    billpaymentName = paymentPayload["billpaymentName"];
    country = paymentPayload["country"];
    if (paymentPayload["sender_email"]) {
      email = (
        <>
          <div className="mb-2">
            <label className="form-label text-white fw-700 ms-2 fs-14px">
              Email Address
            </label>
            <input
              disabled
              value={paymentPayload["sender_email"]}
              type="text"
              className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
            />
          </div>
        </>
      );
    }
    if (paymentPayload["accountNumber"]) {
      accountNumber = (
        <>
          <div className="mb-2">
            <label className="form-label text-white fw-700 ms-2 fs-14px">
              Account Number
            </label>
            <input
              disabled
              value={paymentPayload["accountNumber"]}
              type="text"
              className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
            />
          </div>
        </>
      );
    }

    if (paymentPayload["paymentDetails"]) {
      if (paymentPayload["paymentDetails"]["paymentDetails"]) {
        paymentDetails = paymentPayload["paymentDetails"]["paymentDetails"];
      }
      if (paymentPayload["paymentDetails"]["productName"]) {
        productName =
          "(" + paymentPayload["paymentDetails"]["productName"] + ")";
      }
    }

    console.log("aaa",paymentDetails)

    if (billpaymentName === "Electricity") {
      paymentDetails = [
        {
          service: paymentPayload["extras"]["billerId"],
          description: "",
          subtotal: 0,
        },
      ];
    }

    if (billpaymentName === "DSTV") {
    }

    if (paymentPayload["verificationDetails"]) {
      if (billpaymentName === "School Fees") {
        paymentDetails = [
          {
            service: "School Fees",
            description: (
              <p>{paymentPayload["verificationDetails"]["customerName"]}</p>
            ),
            subtotal: 0,
          },
        ];
      }
    }
    if (paymentPayload["hasRates"]) {
      if (paymentPayload["paymentDetails"]["rates"]) {
        rates = paymentPayload["paymentDetails"]["rates"];
      }
    }

    if (paymentPayload["recepient_national_id"]) {
      national_id = (
        <div className="mb-2">
          <label className="form-label text-white fw-700 ms-2 fs-14px">
            National ID
          </label>
          <input
            disabled
            value={paymentPayload["recepient_national_id"]}
            type="text"
            className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
          />
        </div>
      );
    }
    if (paymentPayload["recepient_last_name"]) {
      let user_name =
        paymentPayload["recepient_first_name"] +
        " " +
        paymentPayload["recepient_last_name"];
      receiver_name = (
        <div className="mb-2">
          <label className="form-label text-white fw-700 ms-2 fs-14px">
            Receipient Name
          </label>
          <input
            disabled
            value={user_name}
            type="text"
            className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
          />
        </div>
      );
    }
    if (paymentPayload["address"]) {
      let address_text = paymentPayload["address"];
      address = (
        <div className="mb-2">
          <label className="form-label text-white fw-700 ms-2 fs-14px">
            Physical Address
          </label>
          <input
            disabled
            value={address_text}
            type="text"
            className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
          />
        </div>
      );
    }
  }
  const getUsdConvertedRate = () => {
    var search = rates.filter((obj) => obj.to_currency === currency);
    if (currency === "usd") {
      return 1;
    } else {
      if (currency) {
        return search[0].converted_rate;
      } else {
        return 0;
      }
    }
  };

  if (currency && amount) {
    amountCurrency = currency.toUpperCase() + parseFloat(amount).toFixed(2);
  } else {
    window.location.href = "/";
  }

  // let country_component;
  if (country) {
    // country_component = (
    //   <div className="mb-2">
    //     <label className="form-label text-white fw-700 ms-2 fs-14px">
    //       Country
    //     </label>
    //     <input
    //       disabled
    //       value={country}
    //       type="text"
    //       className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
    //     />
    //   </div>
    // );
  }

  let phone_number_component;
  if (phone_number) {
    phone_number_component = (
      <div className="mb-2">
        <label className="form-label text-white fw-700 ms-2 fs-14px">
          Phone Number
        </label>
        <input
          disabled
          value={phone_number}
          type="text"
          className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
        />
      </div>
    );
  }

  return (
    <>
      <div className="col-12 col-lg-6">
        <div className="card border-0">
          <div className="card-body nav-bg rounded-3 text-white p-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <h5 className="text-white fw-800">{billpaymentName}</h5>
                <h6 className="text-white fw-800">{productName} </h6>
              </div>

              <div className="mw-cs-title-line w-60 d-none d-md-inline-block bg-white opacity-50"></div>
            </div>
            {/* 
            {country_component} */}
            {receiver_name}
            {phone_number_component}
            {accountNumber}
            {email}
            {national_id}
            {address}

            <div className="mb-4">
              <label className="form-label text-white fw-700 ms-2 fs-14px">
                Amount
              </label>
              <input
                disabled
                type="text"
                value={amountCurrency}
                className="form-control border-1 rounded-3 bg-transparent fs-10px ps-2 py-2 text-white"
                placeholder="Enter Amount"
              />
            </div>

            <div className="table-responsive">
              <table className="table table-borderless my-4 text-white">
                <thead className="checkout-payments-table-border">
                  <tr>
                    <th>
                      <h6 className="letter-spacing-3 fs-14px text-orange-2 fw-700 text-uppercase">
                        Service
                      </h6>
                    </th>
                    <th>
                      <h6 className="letter-spacing-3 fs-14px text-orange-2 fw-700 text-uppercase">
                        Description
                      </h6>
                    </th>
                    <th>
                      <h6 className="letter-spacing-3 fs-14px text-orange-2 fw-700 text-uppercase">
                        subtotal
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentDetails.map((item, index) => {
                    let subtotal;
                    if (item.subtotal === 0) {
                      subtotal = parseFloat(amount);
                    } else {
                      if (billpaymentName === "DSTV") {
                        subtotal = getUsdConvertedRate() * item.subtotal;
                      } else {
                        subtotal = item["subtotal"];
                      }
                      if (productName) {
                        subtotal = getUsdConvertedRate() * item.subtotal;
                      }
                    }
                    return (
                      <tr key={index}>
                        <td>{item.service}</td>
                        <td>{item.description}</td>
                        <td>
                          {currency.toUpperCase()}
                          {subtotal.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PaymentDetails.defaultProps = {
  paymentPayload: {},
};

PaymentDetails.propTypes = {
  paymentPayload: PropTypes.string,
};

export default PaymentDetails;
