import React from "react";

import FirstBanner from "./side-banner-1.jpg";
import SecondBanner from "./side-banner-2.jpg";
import ThirdBanner from "./side-banner-3.jpg";
import FourthBanner from "./side-banner-4.jpg";


export default function SideBanner() {
  return (
    <div
      id="side-banners"
      className="carousel slide carousel-fade d-none d-lg-block"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={FirstBanner} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={SecondBanner} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={ThirdBanner} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={FourthBanner} className="d-block w-100" alt="..." />
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#side-banners" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#side-banners" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  );
}
