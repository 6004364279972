import React from "react";
import { Form } from "antd";
import { Link } from "react-router-dom";

const ResetPassword = (props) => {
  return (
    <>
      {/* {emptyMessage} */}

      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label text-green-2 fw-700">Password</label>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Enter your password!" }]}
            >
              <input
                type="password"
                class="form-control shadow-none border-2-green bg-transparent"
                placeholder="Enter your new password"
              />
            </Form.Item>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label text-green-2 fw-700">
              Re-Enter password
            </label>
            <Form.Item
              name="password2"
              rules={[{ required: true, message: "Enter your password!" }]}
            >
              <input
                type="password"
                class="form-control shadow-none border-2-green bg-transparent"
                placeholder="Re-Enter your password"
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div class="text-center mb-3">
        <small class="fs-12px">
          Already have an account?{" "}
          <Link to="/login" class="fw-800 text-green-2 text-decoration-none">
            Login
          </Link>
        </small>
      </div>
      <button
        type="submit"
        // onClick={onSubmit}
        class="btn btn-warning w-100 text-uppercase
                    text-white fw-800 letter-spacing-3 orange-gradient-bg-1"
      >
        Reset
      </button>
    </>
  );
};

ResetPassword.defaultProps = {};

ResetPassword.propTypes = {};

export default ResetPassword;
