import React, { useEffect } from "react";
import { connect } from "react-redux";
import Buttons from "components/wallet/buttons";
import Cards from "components/wallet/cards";
import Footer from "components/footer/main";
import { peerLookUpRoutine } from "containers/lookup/routines";
import { getUserDetailsRoutine } from "containers/authentication/routines";

import transactionsRoutine, {
  sendMoneyRoutine,
  removeMessagesRoutine,
} from "./routines";
import Navigation from "components/main/navigation";

function Wallet(props) {
  const { balance, reloadRequested, transactions } = props;

  useEffect(() => {
    const { getTransactions, getUserDetails } = props;
    getTransactions();
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadRequested]);

  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  if (!getUserDetails()) {
    window.location = "/login";
  }

  let salutation;

  if (getUserDetails()) {
    salutation = `Good Day, ${getUserDetails().first_name} ${
      getUserDetails().last_name
    }`;
  } else {
    salutation = "Welcome";
  }

  let render_components;
  if (getUserDetails().has_wallet) {
    render_components = (
      <>
        {" "}
        <Navigation />
        <div className="my-account-header-bg py-5">
          <div className="container d-lg-flex text-center text-lg-start py-3">
            <img
              src="./assets/img/icons/my-account-header-icon.svg"
              alt=""
              width="100"
            />

            <div className="ms-lg-3 mt-3">
              <h1 className="fw-800 text-green">{salutation}</h1>
              <h4 className="text-green">Start Managing Your Account.</h4>
            </div>
          </div>
        </div>
        <Buttons />
        <Cards transactions={transactions} balance={balance} />
        <Footer />
      </>
    );
  } else {
    window.location = "/create-wallet";
  }

  return <>{render_components}</>;
}

Wallet.defaultProps = {
  errorMessage: null,
  successMessage: null,
  transactions: [],
  userDetails: {},
};

Wallet.propTypes = {};

function mapStateToProps(state) {
  return {
    // Transactions
    transactions: state.getIn(["wallet", "transactions"]),
    balance: state.getIn(["wallet", "balance"]),
    reloadRequested: state.getIn(["wallet", "reloadRequested"]),
    showSuccessMessage: state.getIn(["wallet", "showSuccessMessage"]),
    showErrorMessage: state.getIn(["wallet", "showErrorMessage"]),
    errorMessage: state.getIn(["wallet", "errorMessage"]),
    userDetails: state.getIn(["authentication", "userDetails"]),
    hasWallet: state.getIn(["wallet", "hasWallet"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendMoney: (sendMoneyPayload, pageName) =>
      dispatch(sendMoneyRoutine.trigger({ sendMoneyPayload, pageName })),
    removeMessages: () => dispatch(removeMessagesRoutine.trigger({})),
    lookup: (value) => dispatch(peerLookUpRoutine.trigger({ value })),
    getTransactions: () => dispatch(transactionsRoutine.trigger({})),
    getUserDetails: () => dispatch(getUserDetailsRoutine.trigger({})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
