import Logo from "components/logo/svg";
import React, { useState } from "react";
import { connect } from "react-redux";
import Signin from "components/login/signin";
import Signup from "components/login/signup";

import { Form, Button, Modal, notification, Spin } from "antd";
import SuccessMsgIcon from "assets/img/icons/success-message-icon.svg";

import { useHistory } from "react-router";
import axios from "axios";
import getConfig from "config";
import loginRoutine, {
  removeMessagesRoutine,
  registerRoutine,
  getUserDetailsRoutine,
  saveEmailAndPasswordRoutine,
} from "./routines";
import { Helmet } from "react-helmet";

function Login(props) {
  const {
    loginUser,
    registerUser,
    errorMessage,
    removeMessages,
    registeringUser,
    saveEmailAndPassword,
  } = props;

  const [state, setState] = useState({
    activeRow: {},
  });
  const config = getConfig();
  const activeRow = state;
  const [isButtonLoading, setButtonLoad] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const registerUrl = `${config.api.base_url}public/user/registration/`;
  const loginUrl = `${config.api.base_url}login/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  const onFinish = (values) => {
    activeRow.email_address = values.email;
    activeRow.password = values.password;
    setState({ ...state, activeRow });
    setButtonLoad(true);
    if (values.password === values.password2) {
      axios
        .post(registerUrl, values, headers)
        .then((res) => {
          setButtonLoad(false);
          if (res.status === 200) {
            saveEmailAndPassword(values);
            setIsModalVisible(true);
          }
        })
        .catch((error) => {
          setButtonLoad(false);
          setTimeout(() => {}, 1000);
          let errors = [];
          if (error.response.data.message.errors["password"]) {
            errors.push(error.response.data.message.errors.password);
          }
          if (error.response.data.message.errors["email"]) {
            errors.push(error.response.data.message.errors.email + ". ");
          }
          if (error.response.data.message.errors["phone_number"]) {
            errors.push(error.response.data.message.errors.phone_number + ". ");
          }
          notification.error({
            message: "Error",
            description: errors,
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        });
    } else {
      setButtonLoad(false);
      notification.error({
        message: `Error`,
        description: "Make sure your passwords match",
        placement: "topRight",
      });
    }
  };

  const handleCancel = () => {
    window.location.href = "/";
    // const payload = JSON.stringify({
    //   email: activeRow.email_address,
    //   password: activeRow.password,
    // });

    // const headers = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   mode: "cors",
    // };
    // axios
    //   .post(loginUrl, payload, headers)
    //   .then((res) => {
    //     setButtonLoad(false);
    //     if (res.status === 200) {
    //       window.history.back();
    //       localStorage.setItem(
    //         "userInfo",
    //         JSON.stringify({
    //           loginToken: res.data.access,
    //           isLoggedIn: true,
    //         })
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     setButtonLoad(false);
    //     setTimeout(() => {}, 1000);
    //     notification.error({
    //       message: "Error",
    //       description: error.response.data.detail,
    //       className: "custom-class",
    //       style: {
    //         top: 70,
    //       },
    //     });
    //   });
  };

  const history = useHistory();

  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  if (getUserDetails()) {
    notification.success({
      message: "Success",
      description: "You are already logged in",
      className: "custom-class",
      style: {
        top: 70,
      },
    });
    history.push({
      pathname: "/",
    });
    // window.location = "/";
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <title>Login | Tumai</title>
        <meta
          name="description"
          content="Send and"
        />

        <meta
          name="keywords"
          content=", Tumai, remitance, diaspora, payments, bill payments, zimbabwe airtime, econet data, netone data, ZESA token, DSTV payments, Zimbabwe bill payments, N Richards voucher"
        />

        <link rel="canonical" href="https://tumai.to/" />

        <meta property="og:url" content="https://tumai.to/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Tumai" />
        <meta
          property="og:description"
          content="Create an account with Tumai and start making payments on behalf of your loved ones for goods, services and utilities in Africa"
        />
        <meta
          property="og:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="twitter:card"
          content="https://tumai.to/assets/img/logo.svg"
        />
        <meta property="twitter:domain" content="tumai.to" />
        <meta property="twitter:url" content="https://tumai.to/" />
        <meta name="twitter:title" content="Tumai" />
        <meta
          name="twitter:description"
          content="Tumai allows customers living in the diaspora to make payments on behalf of their families for goods, services and utilities in Africa. Instead of a customer sending money for a good or service to their relative, Tumai facilitates direct payment of the service from the customer’s location."
        />
        <meta
          name="twitter:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="”robots”" content="index, follow" />
      </Helmet>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={false}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div className="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              <div className="mb-4">
                <img src={SuccessMsgIcon} alt="" width={100} />
              </div>
              <div className="mb-5">
                <h3 className="text-green-2 fw-800">
                  Your account has been created Successfully.
                </h3>
                <p className="text-green-2 fw-800">
                  Please check your email inbox or spam folder to verify your
                  account
                </p>
              </div>
              {/* <Button
                onClick={handleCancel}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Log me in
              </Button> */}
            </div>
          </div>
        </div>
      </Modal>
      <div class="register-photo" style={{ marginTop: -70 }}>
        <div class="form-container">
          <div class="image-holder d-none d-md-table-cell"></div>

          <div class="login-container">
            <div class="text-center">
              <a href="/">
                <Logo cssclass="mb-4" imagewidth="100" />
              </a>
            </div>

            <ul
              class="nav nav-tabs mb-3 justify-content-between border-0"
              id="ex1"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active text-green-2 fs-25px fw-800 text-uppercase letter-spacing-3"
                  id="ex1-tab-1"
                  data-bs-toggle="tab"
                  href="#ex1-tabs-1"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected="true"
                >
                  Login
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link text-green-2 fs-25px fw-800 text-uppercase letter-spacing-3"
                  id="ex1-tab-2"
                  data-bs-toggle="tab"
                  href="#ex1-tabs-2"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected="false"
                >
                  Sign Up
                </a>
              </li>
            </ul>
            <div class="tab-content" id="ex1-content">
              <div
                class="tab-pane fade show active"
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <Signin
                  loginUser={loginUser}
                  errorMessage={errorMessage}
                  removeMessages={removeMessages}
                  registeringUser={registeringUser}
                />
              </div>
              <div
                class="tab-pane fade"
                id="ex1-tabs-2"
                role="tabpanel"
                aria-labelledby="ex1-tab-2"
              >
                {" "}
                <Spin spinning={isButtonLoading} size="large">
                  <Form
                    style={{ padding: "0px 0px 0px 0px" }}
                    onFinish={onFinish}
                  >
                    <Signup
                      registerUser={registerUser}
                      errorMessage={errorMessage}
                      removeMessages={removeMessages}
                    />
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
          {/* </Form> */}
        </div>
      </div>
    </>
  );
}

Login.defaultProps = {
  errorMessage: {},
};

Login.propTypes = {};

function mapStateToProps(state) {
  return {
    errorMessage: state.getIn(["authentication", "errorMessage"]),
    showErrorMessage: state.getIn(["authentication", "showErrorMessage"]),
    isLoggedIn: state.getIn(["authentication", "isLoggedIn"]),
    isRegistered: state.getIn(["authentication", "isRegistered"]),
    registeringUser: state.getIn(["authentication", "registeringUser"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (values) => dispatch(loginRoutine.trigger({ values })),
    getUserDetails: () => dispatch(getUserDetailsRoutine.trigger({})),
    registerUser: (values) => dispatch(registerRoutine.trigger({ values })),
    removeMessages: () => dispatch(removeMessagesRoutine.trigger({})),
    saveEmailAndPassword: (values) =>
      dispatch(saveEmailAndPasswordRoutine.trigger({ values })),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
