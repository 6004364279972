import mainSaga, {
  getRatesSaga,
  paymentPayloadSaga,
  postTransactionSaga,
} from "containers/main/saga";
import authenticationSaga, {
  removeMessagesSaga,
  registerUserSaga,
  getUserDetailsSaga,
  saveEmailPasswordSaga,
} from "containers/authentication/saga";
import productsSaga, {
  addCartSaga,
  checkoutSaga,
  cartCounterSaga,
  categoriesSaga,
  removeFromCartSaga,
  updateCartSaga,
} from "containers/store/saga";
import merchantSaga from "containers/merchants/saga";
import transactionSaga, { sendMoneySaga } from "containers/wallet/saga";
import lookUpSaga from "containers/lookup/saga";
import paymentSaga from "containers/payments/saga";

import createWalletSaga, { verifyOtpSaga } from "containers/createWallet/saga";
import industrySaga from "containers/industry/saga";

const sagas = [
  mainSaga,
  getRatesSaga,
  postTransactionSaga,
  authenticationSaga,
  removeMessagesSaga,
  registerUserSaga,
  productsSaga,
  addCartSaga,
  checkoutSaga,
  cartCounterSaga,
  categoriesSaga,
  removeFromCartSaga,
  merchantSaga,
  getUserDetailsSaga,
  transactionSaga,
  lookUpSaga,
  sendMoneySaga,
  transactionSaga,
  createWalletSaga,
  verifyOtpSaga,
  paymentPayloadSaga,
  saveEmailPasswordSaga,
  paymentSaga,
  updateCartSaga,
  industrySaga,
];
export default function registerSagaWithMiddleware(middleware) {
  sagas.forEach((saga) => middleware.run(saga));
}
