import React, { useEffect } from "react";
import { connect } from "react-redux";

import HomeIcon from "../../assets/img/shop/icons/home.svg";
import WalletIcon from "../../assets/img/shop/icons/wallet.svg";
import CartIcon from "../../assets/img/shop/icons/cart.svg";

import Sidebar from "components/shop/sidebar";
import RightHandler from "components/shop/rightHandler/";
import { Link, useHistory, useParams } from "react-router-dom";
import { message } from "antd";

import Navigation from "components/main/navigation";
import getProductsRoutine, {
  getCategoriesRoutine,
  addToCartRoutine,
  checkoutRoutine,
  cartCounterRoutine,
  updateCartRoutine,
} from "./routines";

function Store(props) {
  const {
    products,
    categories,
    getProduct,
    getCategories,
    addToCart,
    cart,
    checkout,
    cartCount,
    counter,
    updateCart,
  } = props;

  const { merchant_id, merchant_name, category_id } = useParams();

  const history = useHistory();
  useEffect(() => {

    updateCart()
    if (merchant_id && merchant_name && category_id) {
      getProduct(merchant_id, category_id);
      getCategories(merchant_id);
    } else {
      history.push({
        pathname: `/merchants`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_id]);

  const handleInputChange = (
    productID,
    price,
    quantity,
    picture,
    productName
  ) => {
    if (quantity > 0) {
      message.success({
        content: "Product added to cart",
        className: "custom-class",
        style: {
          marginTop: 70,
        },
      });
      addToCart(productID, cart, quantity, price, picture, productName);
      cartCount(counter + quantity);
    } else {
      message.error("Quantity can not be zero (0)");
    }
  };

  const cart_ = cart;
  const sum = cart_
    .map((item) => item.quantity)
    .reduce((prev, curr) => prev + curr, 0);

  const header_titles = (
    <>
      <li className="nav-item ps-3">
        <a className="nav-link text-uppercase" href="/">
          <img src={HomeIcon} height="25" alt="" />
        </a>
      </li>

      <li className="nav-item ps-3">
        <Link className="nav-link text-uppercase" to="/wallet">
          <img src={WalletIcon} height="25" alt="" />
        </Link>
      </li>

      <li className="nav-item ps-3 position-relative">
        <Link className="nav-link text-uppercase" to="/cart">
          <img src={CartIcon} height="25" alt="" />
        </Link>
        <span className="position-absolute top-30 start-100 translate-middle badge rounded-pill bg-white text-dark">
          {sum}
          <span className="visually-hidden">unread messages</span>
        </span>
      </li>
    </>
  );

  return (
    <>
      <Navigation header_titles={header_titles} />
      <section id="merchant-shop">
        <div className="container py-5">
          <div className="row">
            <Sidebar
              categories={categories}
              merchant_name={merchant_name}
              merchant_id={merchant_id}
            />
            <RightHandler
              products={products}
              checkout={checkout}
              handleInputChange={handleInputChange}
            />
          </div>
        </div>
      </section>
    </>
  );
}

Store.defaultProps = {
  errorMessage: null,
  products: [],
  categories: [],
  cart: [],
  counter: 0,
};

Store.propTypes = {};

function mapStateToProps(state) {
  return {
    products: state.getIn(["store", "products"]),
    categories: state.getIn(["store", "categories"]),
    reloadRequested: state.getIn(["store", "reloadRequested"]),
    cart: state.getIn(["store", "cart"]),
    counter: state.getIn(["store", "counter"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProduct: (merchant_id, category_id) =>
      dispatch(getProductsRoutine.trigger({ merchant_id, category_id })),
    getCategories: (merchant_id) =>
      dispatch(getCategoriesRoutine.trigger({ merchant_id })),
    addToCart: (productID, cart, quantity, price, picture, productName) =>
      dispatch(
        addToCartRoutine.trigger({
          productID,
          cart,
          quantity,
          price,
          picture,
          productName,
        })
      ),
    checkout: (cart) => dispatch(checkoutRoutine.trigger({ cart })),
    updateCart: () => dispatch(updateCartRoutine.trigger({ })),
    cartCount: (counter) => dispatch(cartCounterRoutine.trigger({ counter })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Store);
