import { call, put, takeEvery } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import createWalletRoutine, { verifyOtpRoutine } from "./routines";

const axios = require("axios");

/**
 * ============================================================
 * @param {Get Services}
 */
function* createwallet(action) {
  const { values, id_number } = action.payload;

  const config = getConfig();

  const serviceUrl = `${config.api.base_url}wallet/activate_wallet/`;

  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${config.api.token}`
    },
    mode: "cors",
  };

  let nineLong = values.phone_number.substring(values.phone_number.length - 9);

  // let validatedNationalId = values.national_id.national_id

  const formData = new FormData();

  formData.set('national_id_file', id_number);
  formData.set('phone_number', `263${nineLong}`);
  formData.set('national_id', values.national_id);
  formData.set('pin', values.pin);
  formData.set('confirm_pin', values.confirm_pin);

  try {
    // trigger request action
    yield put(createWalletRoutine.request());
    const response = yield call(axios.post, serviceUrl, formData, headers);
    yield put(createWalletRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(createWalletRoutine.failure(error.response.data));
  } finally {
    // trigger fulfill action
    yield put(createWalletRoutine.fulfill());
  }
}

function* createWalletSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(createWalletRoutine.TRIGGER, createwallet);
}

export default createWalletSaga;

function* verifyOtp(action) {
  const { otp, phone_number } = action.payload;

  const config = getConfig();

  const serviceUrl = `${config.api.base_url}wallet/verify_otp/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${config.api.token}`
    },
    mode: "cors",
  };
  const payload = JSON.stringify({
    otp: otp,
    phone_number: phone_number,
  });



  try {
    // trigger request action
    yield put(verifyOtpRoutine.request());
    const response = yield call(axios.post, serviceUrl, payload, headers);
    yield put(verifyOtpRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(verifyOtpRoutine.failure(error.response.data));
  } finally {
    // trigger fulfill action
    yield put(verifyOtpRoutine.fulfill());
  }
}

export function* verifyOtpSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(verifyOtpRoutine.TRIGGER, verifyOtp);
}
