import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "components/main/header";
import Services from "components/main/services";
import About from "components/main/about";
import Access from "components/main/access";
import Regions from "components/main/regions";
import DownloadBanner from "components/banner/download-banner";
import Footer from "components/footer/main";
import { Modal, Button, Spin } from "antd";
import SuccessMsgIcon from "assets/img/icons/success-message-icon.svg";
import FailureMsgIcon from "assets/img/icons/confirm-transfer-danger-icon.svg";

import getServicesRoutine, {
  getRatesRoutine,
  setPaymentPayloadRoutine,
  postTransactionRoutine,
} from "./routines";
import { getUserDetailsRoutine } from "containers/authentication/routines";
import getConfig from "config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import HeaderTitles from "components/main/headerTitles/HeaderTitles";

import { Helmet } from "react-helmet";

function Main(props) {
  const {
    reloadRequested,
    services,
    rates,
    getRates,
    postTransaction,
    getUserDetails,
    setPaymentPayload,
  } = props;

  useEffect(() => {
    const { getServices } = props;

    localStorage.removeItem("paymentPayload");

    getRates(1);
    getServices();

    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadRequested]);

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [paymentInitiatedModalVisible, setpaymentInitiatedModalVisible] = useState(false);
  const [emailSuccessModalVisible, setEmailSuccessModalVisible] =
    useState(false);
  const [isTransactionResponseLoading, setIsTransactionResponseLoading] =
    useState(true);
  const [paymentCancelModalVisible, setpaymentCancelModalVisible] =
    useState(false);
  const [transactionResponse, setIsTransactionResponse] = useState({
    // status: "",
  });

  const { user_id, status } = useParams();

  const config = getConfig();

  let modalMessage;
  if (user_id) {
    modalMessage = " Your email has been verified Successfully ";
  }

  if (status === "return") {
    modalMessage = " Your payment was successful.";
  }

  const getPaymentReference = () => {
    const paymentInfo = localStorage.getItem("paymentInfo");

    if (paymentInfo !== null) {
      const reference = JSON.parse(paymentInfo).reference;
      return reference;
    }
    return "";
  };

  const getTransactionResponse = () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const getTransactionResponseUrl = `${
      config.api.base_url
    }transaction/status/${getPaymentReference()}/`;

    axios
      .get(getTransactionResponseUrl, headers)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.status) {
            setIsTransactionResponseLoading(false);
            let response;
            let title;
            if (res.data.data.extras.upstream.Token) {
              response = res.data.data.extras.upstream.Token;
              title = "Token :";
            } else if (res.data.data.extras.upstream.voucher) {
              response = res.data.data.extras.upstream.voucher;
              title = "Voucher :";
            } else if (res.data.data.extras.upstream.pin) {
              response = res.data.data.extras.upstream.pin;
              title = "Pin :";
            } else {
              response = "";
              title =
                "Your transaction was successful. Check your email and sms for transaction confirmation";
            }
            setIsTransactionResponse({
              title: title,
              response: response,
              hasHomeButton: true,
            });
          } else if (res.data.data.status === null) {
            getTransactionResponse();
            setIsTransactionResponseLoading(true);
          } else if (!res.data.data.status) {
            setIsTransactionResponseLoading(false);
            setIsTransactionResponse({
              title: "Sorry the transaction could not be processed.",
              response: "",
              hasContactButton: true,
            });
          }
        } else {
          setIsTransactionResponseLoading(false);
          setIsTransactionResponse({
            title: "Sorry the transaction could not be processed.",
            response: "",
            hasContactButton: true,
          });
        }
      })
      .catch((error) => {
        setIsTransactionResponseLoading(false);
        setIsTransactionResponse({
          title: "Sorry something went wrong",
          response: "",
          hasContactButton: true,
        });
      });
    setpaymentCancelModalVisible(true);
  };

  useEffect(() => {
    if (window.location !== window.parent.location) {
      // The page is in an iframe
      if (window.location.pathname === "/transaction/cancel/") {
        window.parent.location.href = `${config.api.tumai}/transaction/cancel`;
      } else if (window.location.pathname === "/transaction/return/") {
        window.parent.location.href = `${config.api.tumai}/transaction/return/`;
      }
    }
    if (user_id) {
      setEmailSuccessModalVisible(true);
      const verifyEmailUrl = `${config.api.base_url}public/user/verify/${user_id}/`;
      axios
        .get(verifyEmailUrl)
        .then((res) => {
          if (res.status === 200) {
          }
        })
        .catch((error) => {});
    }

    if (status) {
      if (status === "return") {
        setSuccessModalVisible(true);
        if (isTransactionResponseLoading) {
          getTransactionResponse();
        }
      } else if(status === "initiated"){
        setpaymentInitiatedModalVisible(true);
      } else {
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const updateTransactionUrl = `${config.api.base_url}transaction/status_update/`;
        const payload = {
          transaction_id: getPaymentReference(),
          status: "cancelled",
        };
        axios
          .post(updateTransactionUrl, payload, headers)
          .then((res) => {
            if (res.status === 200) {
            }
          })
          .catch((error) => {});
        setpaymentCancelModalVisible(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, status]);

  const header_titles = (
    <>
      {" "}
      <HeaderTitles />
    </>
  );

  const handleSuccessCancel = () => {
    window.location = "/";
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <title>Send And Receive Value | Tumai</title>
        <meta
          name="description"
          content="Tumai allows customers living in the diaspora to make payments on behalf of their families for goods, services and utilities in Africa. Instead of a customer sending money for a good or service to their relative, Tumai facilitates direct payment of the service from the customer’s location."
        />

        <meta
          name="keywords"
          content=", Tumai, remitance, diaspora, payments, bill payments, zimbabwe airtime, econet data, netone data, ZESA token, DSTV payments, Zimbabwe bill payments, N Richards voucher"
        />

        <link rel="canonical" href="https://tumai.to/" />

        <meta property="og:url" content="https://tumai.to/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Tumai" />
        <meta
          property="og:description"
          content="Tumai allows customers living in the diaspora to make payments on behalf of their families for goods, services and utilities in Africa. Instead of a customer sending money for a good or service to their relative, Tumai facilitates direct payment of the service from the customer’s location."
        />
        <meta
          property="og:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="twitter:card"
          content="https://tumai.to/assets/img/logo.svg"
        />
        <meta property="twitter:domain" content="tumai.to" />
        <meta property="twitter:url" content="https://tumai.to/" />
        <meta name="twitter:title" content="Tumai" />
        <meta
          name="twitter:description"
          content="Tumai allows customers living in the diaspora to make payments on behalf of their families for goods, services and utilities in Africa. Instead of a customer sending money for a good or service to their relative, Tumai facilitates direct payment of the service from the customer’s location."
        />
        <meta
          name="twitter:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="”robots”" content="index, follow" />
      </Helmet>
      <Modal
        footer={false}
        closable={true}
        onCancel={handleSuccessCancel}
        visible={paymentCancelModalVisible}
        width={750}
      >
        <div>
          <div className="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              <div className="mb-4">
                <img src={FailureMsgIcon} alt="" class="img-fluid" width="70" />
              </div>
              <div className="mb-5">
                <h3 className="text-green-2 fw-800">
                  Your payment has been cancelled
                </h3>
              </div>
              <Button
                onClick={handleSuccessCancel}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Home
              </Button>
            </div>
          </div>
        </div>
      </Modal>{" "}
      <Modal
        footer={false}
        closable={true}
        onCancel={handleSuccessCancel}
        visible={paymentInitiatedModalVisible}
        width={750}
      >
        <div>
          <div className="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              <div className="mb-4">
                <img src={SuccessMsgIcon} alt="" class="img-fluid" width="70" />
              </div>
              <div className="mb-5">
                <h3 className="text-green-2 fw-800">
                  Your payment with transaction reference <br/> {getPaymentReference()} <br/> has been inititated
                </h3>
              </div>
              <Button
                onClick={handleSuccessCancel}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Home
              </Button>
            </div>
          </div>
        </div>
      </Modal>{" "}
      <Modal
        footer={false}
        closable={true}
        onCancel={handleSuccessCancel}
        visible={emailSuccessModalVisible}
        width={750}
      >
        <div>
          <div className="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              <div className="mb-4">
                <img src={SuccessMsgIcon} alt="" class="img-fluid" width="70" />
              </div>
              <div className="mb-5">
                <h3 className="text-green-2 fw-800">{modalMessage}</h3>
              </div>
              <Button
                onClick={handleSuccessCancel}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Home
              </Button>
            </div>
          </div>
        </div>
      </Modal>{" "}
      <Modal
        footer={false}
        closable={true}
        onCancel={handleSuccessCancel}
        visible={successModalVisible}
        width={750}
        style={{ marginTop: "20px" }}
      >
        <div className="card p-0 border-0">
          <div className="card-body messages-bg p-0 text-white">
            <div className="row">
              <div className="col-12 col-lg-7">
                <div className="card mx-5 my-4 rounded-3 border-0">
                  <div className="card-body messages-card-bg rounded-3">
                    <div className="text-center px-3">
                      <div className="mb-4">
                        <img src={SuccessMsgIcon} alt="" width={60} />
                      </div>
                      <div className="mb-3">
                        <h5 className="fw-800 text-white">{modalMessage}</h5>
                      </div>
                      {!!!transactionResponse ? <br /> : null}
                      <br />
                      <Spin
                        style={{ color: "white" }}
                        spinning={isTransactionResponseLoading}
                        tip="Please wait while we process your transaction"
                      >
                        {transactionResponse &&
                        !!!isTransactionResponseLoading ? (
                          <>
                            <table className="table table-borderless">
                              <tbody>
                                <tr className="text-white border-bottom text-start">
                                  <th>Transaction ID</th>
                                  <td>{getPaymentReference()}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table className="table table-borderless">
                              <tbody>
                                <tr className="text-white border-bottom text-start">
                                  <th>{transactionResponse.title}</th>
                                  <td>{transactionResponse.response}</td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        ) : null}
                      </Spin>
                      <div className="mt-2">
                        {transactionResponse.hasHomeButton ? (
                          <>
                            <Button
                              onClick={handleSuccessCancel}
                              type="primary"
                              key="console"
                              className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
                            >
                              Home
                            </Button>
                          </>
                        ) : transactionResponse.hasContactButton ? (
                          <>
                            {/* <Link
                              to="/contact"
                              className="btn fs-14px px-3 blue-gradient-bg-1 border-0 text-uppercase rounded-pill text-white fw-800 letter-spacing-3 box-shadow-btn"
                            >
                              Contact Us
                            </Link> */}
                            <br />
                            <br />
                          </>
                        ) : (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5" />
            </div>
          </div>
        </div>
      </Modal>
      <Header header_titles={header_titles} />
      <Services
        services={services}
        rates={rates}
        getRates={getRates}
        postTransaction={postTransaction}
        setPaymentPayload={setPaymentPayload}
      />
      <About />
      <Access />
      <Regions />
      <DownloadBanner />
      <Footer />
    </>
  );
}

Main.defaultProps = {
  services: [],
  rates: {},
};

Main.propTypes = {};

function mapStateToProps(state) {
  return {
    services: state.getIn(["main", "services"]),
    rates: state.getIn(["main", "rates"]),
    reloadRequested: state.getIn(["main", "reloadRequested"]),
    isLoggedIn: state.getIn(["authentication", "isLoggedIn"]),
    userDetails: state.getIn(["authentication", "userDetails"]),
    paymentPayload: state.getIn(["main", "paymentPayload"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getServices: () => dispatch(getServicesRoutine.trigger({})),
    getUserDetails: () => dispatch(getUserDetailsRoutine.trigger({})),
    getRates: (amount) => dispatch(getRatesRoutine.trigger({ amount })),
    setPaymentPayload: (values) =>
      dispatch(setPaymentPayloadRoutine.trigger({ values })),
    postTransaction: (values) =>
      dispatch(postTransactionRoutine.trigger({ values })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
