import React from "react";
import PropTypes from "prop-types";
import BalanceComponent from "./balance";
import Links from "./links";

const Balance = (props) => {
  const { balance } = props;
  return (
    <>
      <BalanceComponent balance={balance} />
      <Links />
    </>
  );
};

Balance.defaultProps = {};

Balance.propTypes = {
  balance: PropTypes.string,
};

export default Balance;
