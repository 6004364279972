import { call, put, takeEvery } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import getMerchantsRoutine from "./routines";

const axios = require("axios");

function* fetchMerchants(action) {
  const { industryName } = action.payload;
  const config = getConfig();

  const merchantUrl = `${config.api.base_url}merchant/industry/${industryName}/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };
  try {
    // trigger request action
    yield put(getMerchantsRoutine.request());

    const response = yield call(axios.get, merchantUrl, headers);
    yield put(getMerchantsRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(getMerchantsRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(getMerchantsRoutine.fulfill());
  }
}

function* merchantSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getMerchantsRoutine.TRIGGER, fetchMerchants);
}

export default merchantSaga;
