import React from "react";
import PropTypes from "prop-types";
import Banner from "../../banner/merchantsBanner";
import Navigation from "../../../main/navigation";
/**
 * @summary Render Products Handle component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const Header = (props) => {
  const { header_titles } = props;
  return (
    <>
      <header>
        <Navigation header_titles={header_titles}/>

        <Banner />
      </header>
    </>
  );
};

Header.defaultProps = {
  count: 0,
};

Header.propTypes = {
  header_titles: PropTypes.string,
};

export default Header;
