import React from "react";
import Logo from "components/shop/header/logo";
import Menu from "components/shop/header/menu";
/**
 * @summary Render Products Handle component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const Header = (props) => {
  return (
    <>
      <nav className="navbar navbar-expand-sm">
        <div className="container-fluid">
          <Logo />

          {/*<Search />*/}

          <Menu />
        </div>
      </nav>
    </>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
