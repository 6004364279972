import React from "react";
import PropTypes from "prop-types";
import ChevronRight from "assets/img/icons/double-chevron-right-green.svg"
// import bars from "./assets/shop/img/icons/mps-chevron-right.png";
import { Link } from "react-router-dom";

/**
 * @summary Render Products Handle component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const SidebarCategory = (props) => {
  const { category_name, merchant_id, merchant_name, category_id } = props;

  const storeUrl = `/store/${merchant_id}/${merchant_name}/${category_id}`;

  return (
    <>
      <li className="list-unstyled d-flex justify-content-between align-items-center my-2">
        <Link to={storeUrl} className="text-decoration-none fw-600 fs-20px text-green-2 letter-spacing-3">
          {category_name}
        </Link>
        <img src={ChevronRight} alt="" className="img-fluid me-3 d-none d-md-inline" width={16} />
      </li>
    </>
  );
};

SidebarCategory.defaultProps = {};

SidebarCategory.propTypes = {
  category_id: PropTypes.string,
  category_name: PropTypes.string,
  image: PropTypes.string,
  merchant_id: PropTypes.string,
  merchant_name: PropTypes.string,
};

export default SidebarCategory;
