import { call, put, takeEvery } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import transactionsRoutine, {
  sendMoneyRoutine,
  removeMessagesRoutine,
} from "./routines";

const axios = require("axios");

function* fetchTransactions(action) {
  const config = getConfig();

  const transactionUrl = `${config.api.base_url}wallet/info/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${config.api.token}`,
    },
    mode: "cors",
  };
  try {
    // trigger request action
    yield put(transactionsRoutine.request());

    const response = yield call(axios.get, transactionUrl, headers);
    yield put(transactionsRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(transactionsRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(transactionsRoutine.fulfill());
  }
}

function* transactionSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(transactionsRoutine.TRIGGER, fetchTransactions);
}

function* sendMoney(action) {
  const config = getConfig();
  const { sendMoneyPayload, pageName, pin } = action.payload;

  let payload;
  if (pageName === "p2p" || pageName === "p2m") {
    payload = JSON.stringify({
      recipient_id: sendMoneyPayload.phoneNumber,
      amount: sendMoneyPayload.amount,
      reference: sendMoneyPayload.reference,
      pin: pin,
    });
  }

  if (pageName === "load") {
    payload = JSON.stringify({
      amount: sendMoneyPayload,
      transactionType: "012",
      extras: {},
    });
  }

  let sendMoneyUrl;

  if (pageName === "p2p") {
    sendMoneyUrl = `${config.api.base_url}wallet/p2p/`;
  } else if (pageName === "load") {
    sendMoneyUrl = `${config.api.base_url}transaction/post/`;
  } else {
    sendMoneyUrl = `${config.api.base_url}wallet/p2m/`;
  }

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${config.api.token}`,
    },
    mode: "cors",
  };

  try {
    // trigger request action
    yield put(sendMoneyRoutine.request());

    const response = yield call(axios.post, sendMoneyUrl, payload, headers);
    // if request successfully finished

    yield put(sendMoneyRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(sendMoneyRoutine.failure(error.response.data.info.errors));
  } finally {
    // trigger fulfill action
    yield put(sendMoneyRoutine.fulfill());
  }
}

export function* sendMoneySaga() {
  yield takeEvery([sendMoneyRoutine.TRIGGER], sendMoney);
}

function* removeMessages(action) {
  try {
    // trigger request action
    yield put(removeMessagesRoutine.request());

    yield put(removeMessagesRoutine.success("remove"));
  } catch (error) {
    // if request failed
    yield put(removeMessagesRoutine.failure(error));
  } finally {
    // trigger fulfill action
    yield put(removeMessagesRoutine.fulfill());
  }
}

export function* removeMessagesSaga() {
  yield takeEvery([removeMessagesRoutine.TRIGGER], removeMessages);
}

export default transactionSaga;
