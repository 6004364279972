import React, { useEffect } from "react";
import { connect } from "react-redux";

import Footer from "components/footer/main";
import Header from "components/shop/header/merchantsHeader";
import Container from "components/shop/merchant";

import getMerchantsRoutine from "./routines";
import { Link, useParams } from "react-router-dom";

import HomeIcon from "assets/img/icons/home.svg";
import WalletIcon from "assets/img/icons/wallet.svg";
import CartIcon from "assets/img/icons/cart.svg";

function Merchants(props) {
  const { getMerchants, merchants, reloadRequested, cart } = props;

  const { industryName } = useParams();

  useEffect(() => {
    getMerchants(industryName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadRequested]);

  const cart_ = cart;
  const sum = cart_
    .map((item) => item.quantity)
    .reduce((prev, curr) => prev + curr, 0);

  const header_titles = (
    <>
      <li className="nav-item ps-3">
        <a className="nav-link text-uppercase" href="/">
          <img src={HomeIcon} height="25" alt="" />
        </a>
      </li>

      <li className="nav-item ps-3">
        <Link className="nav-link text-uppercase" to="/wallet">
          <img src={WalletIcon} height="25" alt="" />
        </Link>
      </li>

      <li className="nav-item ps-3 position-relative">
        <Link className="nav-link text-uppercase" to="/cart">
          <img src={CartIcon} height="25" alt="" />
        </Link>
        <span className="position-absolute top-30 start-100 translate-middle badge rounded-pill bg-white text-dark">
          {sum}
        </span>
      </li>
    </>
  );

  return (
    <>
      <>
        <Header header_titles={header_titles} />
        <Container merchants={merchants} />
        <Footer />
      </>
    </>
  );
}

Merchants.defaultProps = {
  cart: [],
};

Merchants.propTypes = {};

function mapStateToProps(state) {
  return {
    merchants: state.getIn(["merchant", "merchants"]),
    reloadRequested: state.getIn(["merchant", "reloadRequested"]),
    cart: state.getIn(["store", "cart"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMerchants: (industryName) => dispatch(getMerchantsRoutine.trigger({industryName})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Merchants);
