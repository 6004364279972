import React from "react";
import { Popover } from "antd";
import { Link } from "react-router-dom";

const DownloadBanner = (props) => {
  return (
    <>
      <section id="download-banner">
        <div className="download-banner-parent">
          <div className="container py-5 text-center text-lg-start">
            <h1 className="text-green-2 fw-800">
              Download our iOS and Android App
            </h1>

            <div className="d-lg-flex align-items-center">
              <div className="my-4 my-lg-5">
                <Popover content="Coming soon" title="">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    to="#"
                    className="btn blue-gradient-bg-1 fw-800 rounded-pill border-0 text-white px-4 py-2 box-shadow-btn"
                  >
                    Download
                    <img
                      className="ms-2"
                      src="./assets/img/icons/double-chevron-right-white.svg"
                      alt=""
                      width="16"
                    />
                  </Link>
                </Popover>
              </div>

              <div className="ms-lg-3 mb-3 mb-lg-0">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="https://play.google.com/store/apps/details?id=com.intelliafricasolutions.tumai" className="">
                  <img
                    src="./assets/img/footer/play-store.svg"
                    alt=""
                    className="img-fluid"
                    width="120"
                  />
                </Link>
              </div>

              <div className="ms-lg-3 mb-3 mb-lg-0">
                <Popover content="Coming soon" title="">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    to="#" className="">
                    <img
                      src="./assets/img/footer/app-store.svg"
                      alt=""
                      className="img-fluid"
                      width="120"
                    />
                  </Link>
                </Popover>
              </div>

              <div className="ms-lg-3">
                <img
                  src="./assets/img/logo.svg"
                  alt=""
                  className="img-fluid d-none d-lg-inline-block "
                  width="80"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

DownloadBanner.defaultProps = {};

DownloadBanner.propTypes = {};

export default DownloadBanner;
