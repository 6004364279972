import React from 'react'
import PropTypes from 'prop-types'
import Nav from 'components/main/nav'
import ImageLogo from 'components/logo/image'
import GradBars from 'assets/img/shop/icons/bars-icon.svg'
import { Link } from 'react-router-dom'

const Navigation = (props) => {
    const { header_titles } = props

    return (
        <>
            <nav className='navbar navbar-expand-xl navbar-dark nav-bg fixed-top box-shadow-1'>
                <div className='container'>
                    <Link className='navbar-brand' to='/'>
                        <ImageLogo />
                    </Link>

                    <button
                        className='navbar-toggler'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span>
                            <img src={GradBars} height='20' alt='' />
                        </span>
                    </button>

                    <Nav header_titles={header_titles} />
                </div>
            </nav>
        </>
    )
}

Navigation.defaultProps = {}

Navigation.propTypes = {
    header_titles: PropTypes.object,
    logoutUser: PropTypes.func
}

export default Navigation
