import React, { useEffect } from "react";
import { connect } from "react-redux";
import Buttons from "components/wallet/buttons";
import Footer from "components/footer/main";

import Loader from "components/wallet/loadBalance";
import { peerLookUpRoutine } from "containers/lookup/routines";

import transactionsRoutine, {
  sendMoneyRoutine,
  removeMessagesRoutine,
} from "containers/wallet/routines";
import Navigation from "components/main/navigation";

function MakePayment(props) {
  const {
    sendMoney,
    showSuccessMessage,
    removeMessages,
    showErrorMessage,
    makePayment,
    lookup,
    paymentLookup,
    successMessage,
    errorMessage,
    balance,
    reloadRequested,
    transactions,
    foundUser,
    foundMerchant,
    removeMessageWallet,
    paymentUrl,
  } = props;

  useEffect(() => {
    const { getTransactions } = props;
    getTransactions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadRequested]);

  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  if (!getUserDetails()) {
    window.location = "/login";
  }
  if (!getUserDetails().has_wallet) {
    window.location = "/login";
  }

  return (
    <>
      <Navigation />
      <Buttons />
      <Loader
        makePayment={makePayment}
        showErrorMessage={showErrorMessage}
        balance={balance}
        sendMoney={sendMoney}
        lookup={lookup}
        paymentLookup={paymentLookup}
        successMessage={successMessage}
        errorMessage={errorMessage}
        transactions={transactions}
        foundUser={foundUser}
        foundMerchant={foundMerchant}
        showSuccessMessage={showSuccessMessage}
        removeMessages={removeMessages}
        removeMessageWallet={removeMessageWallet}
        paymentUrl={paymentUrl}
      />
      <Footer />
    </>
  );
}

MakePayment.defaultProps = {
  errorMessage: null,
  successMessage: null,
  transactions: [],
};

MakePayment.propTypes = {};

function mapStateToProps(state) {
  return {
    // Transactions
    transactions: state.getIn(["wallet", "transactions"]),
    balance: state.getIn(["wallet", "balance"]),
    reloadRequested: state.getIn(["wallet", "reloadRequested"]),
    showSuccessMessage: state.getIn(["wallet", "showSuccessMessage"]),
    showErrorMessage: state.getIn(["wallet", "showErrorMessage"]),
    errorMessage: state.getIn(["wallet", "errorMessage"]),
    successMessage: state.getIn(["wallet", "successMessage"]),
    paymentUrl: state.getIn(["wallet", "paymentUrl"]),
    foundUser: state.getIn(["lookup", "foundUser"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendMoney: (sendMoneyPayload, pageName, pin) =>
      dispatch(sendMoneyRoutine.trigger({ sendMoneyPayload, pageName, pin })),
    removeMessages: () => dispatch(removeMessagesRoutine.trigger({})),
    removeMessageWallet: () => dispatch(removeMessagesRoutine.trigger({})),
    lookup: (value) => dispatch(peerLookUpRoutine.trigger({ value })),
    getTransactions: () => dispatch(transactionsRoutine.trigger({})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MakePayment);
