import { call, put, takeLatest } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import { peerLookUpRoutine } from "./routines";

const axios = require("axios");


function* lookUp(action) {
  const config = getConfig();
  const { value } = action.payload;

  const lookUpUrl = `${config.api.base_url}wallet/lookup/${value}/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${config.api.token}`,
    },
    mode: "cors",
  };

  try {
    // trigger request action
    yield put(peerLookUpRoutine.request());

    const response = yield call(axios.get, lookUpUrl, headers);
    // if request successfully finished

    yield put(peerLookUpRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(peerLookUpRoutine.failure(error.response.data.info.errors));
  } finally {
    // trigger fulfill action
    yield put(peerLookUpRoutine.fulfill());
  }
}

function* lookUpSaga() {
  yield takeLatest([peerLookUpRoutine.TRIGGER], lookUp);
}

export default lookUpSaga;
