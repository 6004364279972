import { Link } from 'react-router-dom'

const HeaderTitles = () => {
    return (
        <>
            <li className='nav-item ms-lg-3'>
                <Link className='nav-link nav-li-a' to='/'>
                    Home
                </Link>
            </li>
            <li className='nav-item ms-lg-3'>
                <Link className='nav-link nav-li-a' to='/our-services'>
                    Our Services
                </Link>
            </li>
            <li className='nav-item ms-lg-3'>
                <Link className='nav-link nav-li-a' to='/offerings'>
                    How it works
                </Link>
            </li>
            <li className='nav-item ms-lg-3'>
                <Link className='nav-link nav-li-a' to='/contact'>
                    Contact Us
                </Link>
            </li>
            {/* <li className='nav-item ms-lg-3'>
                <Link className='nav-link nav-li-a' to='/tumai-kiosks'>
                    Tumai Kiosks
                </Link>
            </li> */}
            {/* <li className='nav-item ms-lg-3'>
                <a className='nav-link nav-li-a' href='https://api.tumai.to/'>
                    Api Documentation
                </a>
            </li> */}
        </>
    )
}

export default HeaderTitles
