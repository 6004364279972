import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import createWalletRoutine, { verifyOtpRoutine } from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
  reloadRequested: false,
});

export default function createWalletReducer(state = initialState, action) {
  let errors = [];
  switch (action.type) {
    /**
     * =============================================================
     * CREATE WALLET
     * =============================================================
     */
    case createWalletRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case createWalletRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case createWalletRoutine.SUCCESS:
      localStorage.removeItem("userDetails");
      return state.merge({
        isWalletcreated: true,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case createWalletRoutine.FAILURE:
      if (action.payload.info.errors["email"]) {
        errors.push(action.payload.info.errors.email);
      }
      if (action.payload.info.errors["national_id"]) {
        errors.push(action.payload.info.errors.national_id);
      }
      if (action.payload.info.errors["phone_number"]) {
        errors.push(action.payload.info.errors.phone_number);
      }
      return state.merge({
        containerState: STATE_ERROR,
        isWalletcreated: false,
        errorMessage: errors,
      });
    /**
     * =============================================================
     * VERIFY OTP
     * =============================================================
     */
    case verifyOtpRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case verifyOtpRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case verifyOtpRoutine.SUCCESS:
      return state.merge({
        isVerified: true,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case verifyOtpRoutine.FAILURE:
      if (action.payload.info.errors["message"]) {
        errors.push(action.payload.info.errors.message);
      }

      if (action.payload.info.errors["otp"]) {
        errors.push(action.payload.info.errors.otp);
      }
      return state.merge({
        containerState: STATE_ERROR,
        isVerified: false,
        errorMessage: errors,
      });
    default:
      return state;
  }
}
