import React from "react";
import PropTypes from "prop-types";
const Logo = (props) => {
  const { cssclass, imagewidth } = props;
  return (
    <>
      <img
        src="./assets/img/logo.svg"
        alt=""
        className={cssclass}
        width={imagewidth}
      />
    </>
  );
};

Logo.defaultProps = {};

Logo.propTypes = {
  cssclass: PropTypes.string,
  imagewidth: PropTypes.string,
};

export default Logo;
