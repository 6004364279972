import React from "react";
// import { Link } from "react-router-dom";

export default function Banner() {
  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  let salutation;

  if (getUserDetails()) {
    salutation = `Good Day, ${getUserDetails().first_name} ${getUserDetails().last_name
      }`;
  } else {
    salutation = "Welcome";
  }
  return (
    <>
      <div className="merchants-header-bg py-5">
        <div className="container py-5">
          <h1 className="text-green fw-800 text-center text-lg-start text-capitalize">
            {salutation}
          </h1>
          <br />
          <a
            href="https://bpa.tumai.to/"
            className="btn blue-gradient-bg-1 fw-800 rounded-pill border-0 text-white px-5 py-2 box-shadow-btn text-uppercase letter-spacing-3"
          >
            Register merchant
            <img
              src="./assets/img/icons/white-chevron-right.svg"
              alt=""
              width="10"
              className="ms-2 d-none d-sm-inline"
            />
          </a>
        </div>
      </div>
    </>
  );
}
