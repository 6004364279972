import React from "react";

/**
 * @summary Render Products Handle component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const Menu = (props) => {
  return (
    <>
      <div className="justify-content-end opacity-0">
        <ul className="navbar-nav lp-ul-margin-fix">
          <li className="nav-item ps-3">
            <a className="nav-link text-uppercase" href="/">
              <img src="./assets/shop/img/icons/home.svg" height="25" alt="" />
            </a>
          </li>

          <li className="nav-item ps-3">
            <a className="nav-link text-uppercase" href="/cart">
              <img src="./assets/shop/img/icons/cart.svg" height="25" alt="" />
            </a>
          </li>

          {/* <li className="nav-item ps-3">
            <a className="nav-link text-uppercase" href="#">
              <img src="./assets/shop/img/icons/user.svg" height="25" alt="" />
            </a>
          </li> */}

          <li className="nav-item ps-3">
             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="nav-link text-uppercase" href="#">
              <img src="./assets/shop/img/icons/bars.svg" height="25" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

Menu.defaultProps = {};

Menu.propTypes = {};

export default Menu;
