import React from "react";
import TopNav from "components/shop/header/topnav";
import Navigation from "../../../main/navigation";

const Main = (props) => {
  return (
    <>
      <header>
        <div className="carousel-img d-none d-lg-block" id="carousel-img">
          <div className="bg-img-illustration">
            <TopNav />
            <div className="header-content">
              <h3 className="text-white fw-700">Welcome To</h3>
              <h1 className="text-white fw-800">Tumai Shop</h1>
              <p className="text-white fw-bold">
                Your one stop shop for <br />
                grocery, contruction & <br />
                wholesale products <br />
                on the go.
              </p>

              <div className="pb-5">
                <div className="mb-5">
                  <a href="/industries" className="btn btn-lg border-0 rounded-pill letter-spacing-3 text-green-2 bg-white box-shadow-btn text-uppercase fw-800 py-3 px-4 fs-14px">
                    Start Shopping
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-block d-lg-none">
          <div className="bg-img-illustration-mobile" style={{ marginTop: 70 }}>
            <div className="container">
              <Navigation />
              <div className="text-green-2 pt-5">
                <h3 className="fw-700 text-green-2 pt-md-5">Welcome To</h3>
                <h1 className="fw-800 text-green-2">Tumai Shop</h1>
                <p className="fw-bold">
                  Your one stop shop for <br />
                  grocery, contruction & <br />
                  wholesale products <br />
                  on the go.
                </p>

                <div className="pb-4">
                  <a href="/industries" className="btn btn-lg border-0 rounded-pill letter-spacing-3 text-green-2 bg-white box-shadow-btn text-uppercase fw-800 py-3 px-4 fs-14px">
                    Start Shopping
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Main.defaultProps = {};

Main.propTypes = {};

export default Main;
