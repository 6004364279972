import React from "react";
import PropTypes from "prop-types";
import SidebarCategory from "components/shop/categories/category";
import SideNavFooter from "../sideNavFooter";
import SideBanner from "../sideBanner";

const SiderBar = (props) => {
  const { categories, merchant_name, merchant_id } = props;

  return (
    <>
      <div className="col-12 col-lg-3">
        <div className="card box-shadow-1 border-0 mb-0 mb-sm-4">
          <div className="card-body p-3 text-center">
            <h5 className="text-green-2 fw-800 letter-spacing-3 text-uppercase">
              {merchant_name}
            </h5>
          </div>
        </div>

        <SideBanner />

        <div className="mb-4 mt-4 position-relative">
          <div className="vertical-line-1 h-100 position-absolute top-0 end-0 bg-green-2 opacity-50" />
          <ul className="ps-0 mt-4 d-none d-sm-inline">
            {!!categories &&
              categories.map((item) => (
                <SidebarCategory
                  key={item.id}
                  category_id={item.id}
                  category_name={item.name}
                  image={item.image}
                  merchant_id={merchant_id}
                  merchant_name={merchant_name}
                />
              ))}
          </ul>
          <SideNavFooter />
        </div>
      </div>
    </>
  );
};

SiderBar.defaultProps = {};

SiderBar.propTypes = {
  categories: PropTypes.array.isRequired,
  merchant_name: PropTypes.string,
  merchant_id: PropTypes.string,
};

export default SiderBar;
