import React from "react";

const Access = (props) => {
  return (
    <>
      <section id="access">
        <div className="lp-access-tumai mt-5 pb-4 pt-1">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mt-5">
              <h4 className="mw-cs-title-name text-white">
                Where can one access Tumai?
              </h4>

              <div className="mw-cs-title-line w-50 d-none d-md-inline-block bg-white"></div>
            </div>

            <p className="lp-about-message-1 text-white mt-4 text-align-justify">
              Our WebApp allows customers to select the goods or services they
              wish to send to their loved ones, and make a payment via our Visa
              or MasterCard gateway all from the ease of their home. The
              receiver will be provided with a confirmation of payment, a token
              or a voucher allowing them to immediately enjoy real value.
            </p>

            <p className="lp-about-message-1 text-white mt-4 text-align-justify">
              We have also partnered with leading international payment partners
              who assist us in providing our customers with the convenience of
              sending value to their family and friends with ease. Through our
              growing network, customers can access our services via digital
              channels or at physical locations where our partners will be
              prepared to assist them to continue to support those at home.
            </p>

            {/* <div className="text-center py-5">
              <a href="#" className="location-btn">
                Pick Up Locations
                <img
                  src="./assets/img/icons/location-pin-icon.svg"
                  alt=""
                  width="16"
                />
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

Access.defaultProps = {};

Access.propTypes = {};

export default Access;
