import React from "react";
import PropTypes from "prop-types";

const Balance = (props) => {
  const { balance } = props;
  return (
    <>
      <div class="col-sm-12 col-lg-6">
        <div class="card mt-3 border-1-green">
          <div class="card-header nav-bg">
            <h5 class="text-white fw-800">Balance</h5>
          </div>

          <div class="card-body p-2">
            <div class="bg-light-green-gray p-4 m-0">
              <h1 class="fw-800 text-green-2">${balance}</h1>

              <hr class="bg-green-2" />

              <div class="row text-center">
                <div class="col-6 col-sm-6 col-lg-3">
                  <a href="/send-money">
                    <img
                      class="img-fluid"
                      src="assets/img/icons/wallet/ca-send.svg"
                      alt=""
                      width="80"
                    />
                  </a>
                  <h5 class="fw-700 text-green-2 mt-2">Send</h5>
                </div>

                <div class="col-6 col-sm-6 col-lg-3">
                  <a href>
                    <img
                      class="img-fluid"
                      src="assets/img/icons/wallet/ca-request.svg"
                      alt=""
                      width="80"
                    />
                  </a>
                  <h5 class="fw-700 text-green-2 mt-2">Request</h5>
                </div>

                <div class="col-6 col-sm-6 col-lg-3">
                  <a href="/make-payment">
                    <img
                      class="img-fluid"
                      src="assets/img/icons/wallet/ca-pay.svg"
                      alt=""
                      width="80"
                    />
                  </a>
                  <h5 class="fw-700 text-green-2 mt-2">Pay</h5>
                </div>

                <div class="col-6 col-sm-6 col-lg-3">
                  <a href>
                    <img
                      class="img-fluid"
                      src="assets/img/icons/wallet/ca-settings.svg"
                      alt=""
                      width="80"
                    />
                  </a>
                  <h5 class="fw-700 text-green-2 mt-2">Settings</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Balance.defaultProps = {};

Balance.propTypes = {
  balance: PropTypes.string,
};

export default Balance;
