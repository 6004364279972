import { combineReducers } from "redux-immutable";

import mainReducer from "containers/main/reducer";
import authenticationReducer from "containers/authentication/reducer";
import storeReducer from "containers/store/reducer";
import shopReducer from "containers/shop/reducer";
import merchantReducer from "containers/merchants/reducer";
import createWalletReducer from "containers/createWallet/reducer";
import walletReducer from "containers/wallet/reducer";
import lookupReducer from "containers/lookup/reducer";
import paymentReducer from "containers/payments/reducer";
import industryReducer from "containers/industry/reducer";

/**
 * Create root reducer, containing
 * all features of the application
 */
const rootReducer = combineReducers({
  main: mainReducer,
  authentication: authenticationReducer,
  store: storeReducer,
  shop: shopReducer,
  merchant: merchantReducer,
  wallet: walletReducer,
  lookup: lookupReducer,
  createWallet: createWalletReducer,
  payments: paymentReducer,
  industry: industryReducer,
});

export default rootReducer;
