import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "antd";
import Navigation from "components/main/navigation";
import VoucherCard from "components/checkout/vouchercard";
import {
  setPaymentPayloadRoutine,
  getRatesRoutine,
} from "containers/main/routines";
import { useHistory } from "react-router";
function Checkout(props) {
  const { vouchers, setPaymentPayload, rates, getRates } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    getRates(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let voucher_count = 0;
  let transaction_amount = 0;
  let transaction_id;
  let cart_voucher = [];
  if (Object.keys(vouchers).length > 0) {
    voucher_count = vouchers.vouchers.length;
    cart_voucher = vouchers.vouchers;
    transaction_amount = vouchers.transaction_amount;
    transaction_id = vouchers.transaction_id;
  } else {
    window.location.href = "/industries";
  }

  let ratesOptions;
  if (Object.keys(rates).length > 0) {
    ratesOptions = (
      <>
        {!!rates &&
          rates.map((items) => {
            return (
              <option key={items.from_currency} value={items.from_currency}>
                {items.from_currency.toUpperCase()}
              </option>
            );
          })}
      </>
    );
  }

  const getPhoneNumber = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const phone_number = JSON.parse(userDetails).user.phone_number;
      return phone_number;
    }
    return false;
  };

  const history = useHistory();

  const payTotal = () => {
    let paymentDetails = [];
    paymentDetails.push({
      service: "Voucher",
      description: "total",
      subtotal: transaction_amount,
    });

    const payload = {
      amount: transaction_amount,
      billpaymentName: "Voucher",
      hasRates: false,
      transactionType: "010",
      currency: currency,
      extras: { transactionId: transaction_id, phoneNumber: getPhoneNumber() },
      paymentDetails: {
        paymentDetails: paymentDetails,
      },
    };

    setPaymentPayload(payload);

    history.push({
      pathname: "/payments",
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCurrency = () => {
    setIsModalVisible(true);
  };

  const currencyChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={450}
      >
        <div>
          <div className="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              <div className="mb-4"></div>
              <div className="mb-5">
                <h6 className="text-green-2">Currency</h6>
                <br />
                <select
                  onChange={(e) => currencyChange(e)}
                  name="currency"
                  className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
                >
                  <option value="">Select currency</option>
                  {ratesOptions}
                </select>
              </div>
              <Button
                onClick={payTotal}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Navigation />

      <section id="cards">
        <div className="wallet-cards-bg py-5">
          <div className="container">
            <div className="card border-0 box-shadow-1">
              <div className="card-header nav-bg border-0 py-4 px-md-5 d-flex align-items-center justify-content-between">
                <h3 className="text-white fw-800">Shop Checkout</h3>

                <div className="d-flex align-items-center">
                  <h4 className="text-white fw-800 me-3">
                    Vouchers ({voucher_count})
                  </h4>
                </div>
              </div>
              <div className="card-body px-lg-5 pb-5 light-grey-bg">
                <div className="d-lg-flex">
                  <div className="flex-fill">
                    {cart_voucher.map((item) => (
                      <VoucherCard
                        key={item.id}
                        amount={item.balance}
                        logo={item.merchant.logo}
                        id={item.voucher_id}
                        voucherNo={item.voucher_no}
                        rates={rates}
                        setPaymentPayload={setPaymentPayload}
                      />
                    ))}
                  </div>

                  <div className="ms-0 ms-lg-5 mt-4 ms-auto flex-fill">
                    <center>
                      <div className="card border-0 box-shadow-1 rounded-3">
                        <div className="card-header nav-bg text-white">
                          <h5 className="text-white fw-800">Pay Total</h5>
                        </div>

                        <div className="card-body">
                          <h5 className="text-green-2 fw-400">Sub Total</h5>
                          <h1 className="text-green-2 fw-800">
                            ${transaction_amount}
                          </h1>
                        </div>
                      </div>

                      <div className="my-3">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          href
                          onClick={getCurrency}
                          className="btn btn-lg w-100 blue-gradient-bg-1 fw-800 border-0 text-uppercase fs-14px box-shadow-btn letter-spacing-3 text-white"
                        >
                          Pay Total
                        </a>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Checkout.defaultProps = {
  vouchers: [],
  rates: [],
};

Checkout.propTypes = {};

function mapStateToProps(state) {
  return {
    vouchers: state.getIn(["store", "vouchers"]),
    rates: state.getIn(["main", "rates"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPaymentPayload: (values) =>
      dispatch(setPaymentPayloadRoutine.trigger({ values })),
    getRates: (amount) => dispatch(getRatesRoutine.trigger({ amount })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
