import { call, put, takeEvery } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import loginRoutine, {
  removeMessagesRoutine,
  registerRoutine,
  saveEmailAndPasswordRoutine,
  getUserDetailsRoutine,
} from "./routines";

const axios = require("axios");

/**
 * ============================================================
 * @param {Login}
 */
function* login(action) {
  const config = getConfig();

  const { values } = action.payload;

  const loginUrl = `${config.api.base_url}login/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  const payload = JSON.stringify({
    email: values.email_address,
    password: values.password,
  });
  try {
    // trigger request action
    yield put(loginRoutine.request());

    const response = yield call(axios.post, loginUrl, payload, headers);

    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        loginToken: response.data.access,
        isLoggedIn: true,
      })
    );
    yield put(loginRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(loginRoutine.failure(error.response.data.detail));
  } finally {
    // trigger fulfill action
    yield put(loginRoutine.fulfill());
  }
}

function* authenticationSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(loginRoutine.TRIGGER, login);
}

export default authenticationSaga;

/**
 * =====================================================================================
 * @param {REMOVE MESSAGES FROM REDUX STORE} action
 */
function* removeMessages(action) {
  try {
    // trigger request action
    yield put(removeMessagesRoutine.request());

    yield put(removeMessagesRoutine.success("remove"));
  } catch (error) {
    // if request failed
    yield put(removeMessagesRoutine.failure(error));
  } finally {
    // trigger fulfill action
    yield put(removeMessagesRoutine.fulfill());
  }
}

export function* removeMessagesSaga() {
  yield takeEvery([removeMessagesRoutine.TRIGGER], removeMessages);
}

/**
 * =====================================================================================
 * @param {USER REGISTRATION} action
 * =====================================================================================
 */
function* registerUser(action) {
  const config = getConfig();

  const { values } = action.payload;

  const registerUrl = `${config.api.base_url}public/user/registration/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  try {
    // trigger request action
    yield put(registerRoutine.request());
    const response = yield call(axios.post, registerUrl, values, headers);

    yield put(registerRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(registerRoutine.failure(error.response));
  } finally {
    // trigger fulfill action
    yield put(registerRoutine.fulfill());
  }
}

export function* registerUserSaga() {
  yield takeEvery([registerRoutine.TRIGGER], registerUser);
}
/**
 * =====================================================================================
 * @param {USER REGISTRATION} action
 * =====================================================================================
 */
function* saveEmailPassword(action) {
  const { values } = action.payload;

  const payload = JSON.stringify({
    email: values.email,
    password: values.password,
  });
  try {
    // trigger request action
    yield put(saveEmailAndPasswordRoutine.request());

    yield put(saveEmailAndPasswordRoutine.success(payload));
  } catch (error) {
    // if request failed
    yield put(saveEmailAndPasswordRoutine.failure(error.response));
  } finally {
    // trigger fulfill action
    yield put(saveEmailAndPasswordRoutine.fulfill());
  }
}

export function* saveEmailPasswordSaga() {
  yield takeEvery([saveEmailAndPasswordRoutine.TRIGGER], saveEmailPassword);
}

/**
 * =====================================================================================
 * @param {GET LOGGED IN USER DETAILS} action
 * =====================================================================================
 */
function* getUserDetails(action) {
  const config = getConfig();

  const getUserUrl = `${config.api.base_url}request/user/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${config.api.token}`,
    },
    mode: "cors",
  };

  try {
    // trigger request action
    yield put(getUserDetailsRoutine.request());
    const response = yield call(axios.get, getUserUrl, headers);

    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        user: response.data.payload,
        isLoggedIn: true,
      })
    );

    yield put(getUserDetailsRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(getUserDetailsRoutine.failure(error.response));
  } finally {
    // trigger fulfill action
    yield put(getUserDetailsRoutine.fulfill());
  }
}

export function* getUserDetailsSaga() {
  yield takeEvery([getUserDetailsRoutine.TRIGGER], getUserDetails);
}
