import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import getIndustriesRoutine from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
});

export default function industryReducer(state = initialState, action) {
  switch (action.type) {
    case getIndustriesRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case getIndustriesRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case getIndustriesRoutine.SUCCESS:
      return state.merge({
        industries: action.payload.data,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case getIndustriesRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
      });

    default:
      return state;
  }
}
