import React, { useState } from "react";
import PropTypes from "prop-types";
import "assets/otp.css";
import { Form, notification, Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import getConfig from "config";
import axios from "axios";
import Country from "components/main/country";
import { Link } from "react-router-dom";

const CreateWalletForm = (props) => {
  const { openVerify, openOtp } = props;
  const countries = ["zw"];

  const [state, setState] = useState({ activeRow: {} });
  const [isCountry, setCountry] = useState("");
  // const [isWalletcreated, setIsWalletcreated] = useState(false);
  const { activeRow } = state;
  const [CreateWalletLoad, setCreateWalletLoad] = useState(false);

  const config = getConfig();
  const serviceUrl = `${config.api.base_url}wallet/activate_wallet/`;

  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${config.api.token}`,
    },
    mode: "cors",
  };

  const formData = new FormData();

  const onFinish = (values) => {
    setCreateWalletLoad(true);
    let nineLong = values.phone_number.substring(
      values.phone_number.length - 9
    );

    if (activeRow.terms_n_services) {
      formData.set("national_id_file", activeRow.id_number);
      formData.set("phone_number", `263${nineLong}`);
      formData.set("national_id", values.national_id);
      formData.set("passphrase", values.passphrase);
      formData.set("address", values.address);
      formData.set("country", isCountry);
      formData.set("pin", values.pin);
      formData.set("confirm_pin", values.confirm_pin);
      // createUserWallet(values, activeRow.id_number);

      if (values.pin === values.confirm_pin) {
        setState({ ...state, activeRow });
        axios
          .post(serviceUrl, formData, headers)
          .then((res) => {
            if (res.status === 201) {
              openVerify(values.phone_number);
              setCreateWalletLoad(false);
            } else {
              notification.error({
                message: "Error",
                description: res.data.info.errors.message,
                className: "custom-class",
                style: {
                  top: 70,
                },
              });
            }
          })
          .catch((error) => {
            let errors = [];
            if (error.response.data.info.errors["email"]) {
              errors.push(error.response.data.info.errors["email"] + ". ");
            }
            if (error.response.data.info.errors["national_id"]) {
              errors.push(
                error.response.data.info.errors["national_id"] + ". "
              );
            }
            if (error.response.data.info.errors["passphrase"]) {
              errors.push(error.response.data.info.errors["passphrase"] + ". ");
            }
            if (error.response.data.info.errors["phone_number"]) {
              errors.push(
                error.response.data.info.errors["phone_number"] + ". "
              );
            }
            notification.error({
              message: "Error",
              description: errors,
              className: "custom-class",
              style: {
                top: 70,
              },
            });
            setTimeout(() => {
              setCreateWalletLoad(false);
            }, 3000);
          });
      } else {
        setCreateWalletLoad(false);
        notification.error({
          message: "Error",
          description: "Make sure you entered matching pins",
          className: "custom-class",
          style: {
            top: 70,
          },
        });
      }
    } else {
      setCreateWalletLoad(false);
      notification.error({
        message: "Error",
        description: "Please accept the terms and services",
        className: "custom-class",
        style: {
          top: 70,
        },
      });
    }
  };

  const onFileChange = (event) => {
    activeRow.id_number = event.target.files[0];

    setState({ ...state, activeRow });
  };

  const onChange = (event) => {
    activeRow.terms_n_services = event.target.checked;

    setState({ ...state, activeRow });
  };

  return (
    <>
      <div className="create-wallet-header-bg py-5">
        <div className="container">
          <Form name="basic" onFinish={onFinish}>
            <div className="row">
              <div className="d-none d-lg-inline col-lg-7" />

              <div className="col-12 col-lg-5">
                <div className="text-center">
                  <h1 className="text-green fw-800">Create Wallet</h1>
                </div>
                <Spin
                  spinning={CreateWalletLoad}
                  delay={300}
                  size="large"
                  style={{ marginTop: "100px" }}
                >
                  <div className="card border-0 box-shadow-1 border-radius-1 nav-bg p-2">
                    <div className="card-body">
                      <div>
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          Phone Number
                        </label>
                        <Form.Item
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone number!",
                            },
                          ]}
                        >
                          <PhoneInput
                            style={{
                              color: "white",
                              borderRadius: "20px !important",
                            }}
                            className="form-control border-1 border-radius-1 bg-transparent fs-10px ps-3 py-2 text-white"
                            country={"zw"}
                            onlyCountries={countries}
                            placeholder="263779525756"
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          National ID
                        </label>
                        <Form.Item
                          name="national_id"
                          rules={[
                            {
                              required: true,
                              message: "Please input your National ID number!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            className="form-control border-1 border-radius-1 bg-transparent fs-10px ps-3 py-2 text-white"
                            placeholder="e.g. 71-667345L56"
                          />
                        </Form.Item>
                      </div>

                      <div className="mb-2">
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          Address
                        </label>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please input your address!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            className="form-control border-1 border-radius-1 bg-transparent fs-10px ps-3 py-2 text-white"
                            placeholder="Enter Address"
                          />
                        </Form.Item>
                      </div>

                      <div className="mb-2">
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          Country
                        </label>
                        <Country
                          option_color="text-black"
                          class_name="form-control border-1 border-radius-1 bg-transparent fs-10px ps-3 py-2 text-white"
                          setCountry={setCountry}
                        />
                      </div>

                      <div className="mb-2">
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          Enter Pass Phrase
                        </label>
                        <Form.Item
                          name="passphrase"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Pass Phrase",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            className="form-control border-1 border-radius-1 bg-transparent fs-10px ps-3 py-2 text-white"
                            placeholder="Enter Pass Phrase"
                          />
                        </Form.Item>
                      </div>

                      <div className="mb-2">
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          Choose National ID File
                        </label>

                        <Form.Item
                          name="file"
                          rules={[
                            {
                              required: true,
                              message: "Please upload a file!",
                            },
                          ]}
                        >
                          <input
                            type="file"
                            onChange={onFileChange}
                            className="form-control border-1 border-radius-1 bg-transparent fs-10px ps-4 py-2 text-white"
                          />
                        </Form.Item>
                      </div>

                      <div className="mb-2">
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          New Pin
                        </label>
                        <Form.Item
                          name="pin"
                          rules={[
                            {
                              required: true,
                              message: "Please input pin!",
                            },
                          ]}
                        >
                          <input
                            type="password"
                            className="form-control border-1 border-radius-1 bg-transparent fs-10px ps-3 py-2 text-white letter-spacing-5"
                            placeholder="* * * * * *"
                            maxlength="6"
                            pattern="^[0-9]{6}$"
                          />
                        </Form.Item>
                      </div>

                      <div className="mb-3">
                        <label className="form-label text-white fw-700 ms-3 fs-14px">
                          Confirm Pin
                        </label>
                        <Form.Item
                          name="confirm_pin"
                          rules={[
                            {
                              required: true,
                              message: "Please confirm pin!",
                            },
                          ]}
                        >
                          <input
                            type="password"
                            className="form-control border-1 border-radius-1 bg-transparent fs-10px ps-3 py-2 text-white letter-spacing-5"
                            placeholder="* * * * * *"
                            maxlength="6"
                            pattern="^[0-9]{6}$"
                          />
                        </Form.Item>
                      </div>

                      <div className="form-check mb-4">
                        <input
                          onChange={onChange}
                          className="form-check-input shadow-none border-1-white-transparent border-radius-1 bg-transparent p-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        />
                        <small className="text-white fs-10px ms-3">
                          I have read and agree to the
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            to="/terms-and-conditions"
                            className="text-light-green text-decoration-none ms-2"
                          >
                            Terms of Service
                          </Link>
                        </small>
                      </div>

                      <div className="form-check mb-4">
                        <small className="text-white fs-10px ms-3">
                          I have already received an OTP
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            to="#"
                            onClick={openOtp}
                            className="text-light-green text-decoration-none ms-2"
                          >
                            Verify here
                          </Link>
                        </small>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-warning w-100 text-uppercase text-white fw-800 border-radius-1 letter-spacing-3 orange-gradient-bg-1 border-0"
                      >
                        Create wallet
                      </button>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

CreateWalletForm.defaultProps = {};

CreateWalletForm.propTypes = {
  onFinish: PropTypes.func,
  onFileChange: PropTypes.func,
  openVerify: PropTypes.func,
  openOtp: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default CreateWalletForm;
