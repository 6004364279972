import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import getMerchantsRoutine from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
});

export default function merchantReducer(state = initialState, action) {
  switch (action.type) {
    case getMerchantsRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case getMerchantsRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case getMerchantsRoutine.SUCCESS:
      return state.merge({
        merchants: action.payload.data,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case getMerchantsRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
      });

    default:
      return state;
  }
}
