import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Carousel from "../courasel";
import Product from "../storeProduct";
import { Empty } from "antd";
import getConfig from "config";
import axios from "axios";
import { Spin } from "antd";

import { useParams } from "react-router-dom";

const RightHandler = (props) => {
  const { handleInputChange } = props;
  const config = getConfig();

  const { merchant_id } = useParams();
  const [products, setProducts] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(true);

  const getProducts = () => {
    const productUrl = `${config.api.base_url}store/merchants/products/${merchant_id}/`;
    axios
      .get(productUrl)
      .then((res) => {
        if (res.status === 200) {
          setIsProductLoading(false);
          setProducts(res.data.data.payload);
        }
      })
      .catch((error) => {
        setIsProductLoading(false);
        setProducts([]);
      });
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let render;
  if (products.length > 0 && isProductLoading === false) {
    render = (
      <>
        {products.map((item) => (
          <Product
            key={item.id}
            productID={item.id}
            productName={item.name}
            picture={item.picture_1}
            price={item.price}
            handleInputChange={handleInputChange}
          />
        ))}
      </>
    );
  } else if (isProductLoading === false) {
    render = (
      <>
        <br /> <br />{" "}
        <Empty
          imageStyle={{
            height: 120,
          }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          description={<h6>Sorry there are no products at the moment</h6>}
        />
      </>
    );
  }

  return (
    <>
      <div className="col-12 col-lg-9">
        <Carousel />

        <div className="row">
          {render}
          <Spin style={{marginTop: "50px"}} spinning={isProductLoading} />
        </div>
      </div>
    </>
  );
};

RightHandler.defaultProps = {
  products: [],
};

RightHandler.propTypes = {
  products: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default RightHandler;
