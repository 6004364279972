import React from "react";
import { connect } from "react-redux";

import Main from "components/shop/header/main";
// import Banner from "components/banner/download-banner";
// import Footer from "components/footer/main";

function MainStore(props) {
  return (
    <div style={{ marginTop: -70 }}>
      <Main />
      {/* <Banner /> */}
      {/* <Footer /> */}
    </div>
  );
}

MainStore.defaultProps = {};

MainStore.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainStore);
