import Logo from "components/logo/svg";
import React, { useState } from "react";
import { connect } from "react-redux";
import ForgotPasswordContainer from "components/login/forgotpassword";

import { Form, Button, Modal, notification, Spin } from "antd";
import SuccessMsgIcon from "assets/img/icons/success-message-icon.svg";

import { useHistory } from "react-router";
import axios from "axios";
import getConfig from "config";

function ForgotPassword(props) {

  const [state, setState] = useState({
    activeRow: {},
  });
  const config = getConfig();
  const activeRow = state;
  const [isButtonLoading, setButtonLoad] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const forgotPasswordUrl = `${config.api.base_url}public/user/forgot-password/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  const onFinish = (values) => {
    const payload = JSON.stringify({
      email: values.email,
    });
    setState({ ...state, activeRow });
    setButtonLoad(true);
    axios
      .post(forgotPasswordUrl, payload, headers)
      .then((res) => {
        setButtonLoad(false);
        if (res.status === 200) {
          setIsModalVisible(true);
        } else {
          notification.error({
            message: "Error",
            description: "Failed to reset password",
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        }
      })
      .catch((error) => {
        setButtonLoad(false);
        setTimeout(() => {}, 1000);
        notification.error({
          message: "Error",
          description: error.response.data.message.errors,
          className: "custom-class",
          style: {
            top: 70,
          },
        });
      });
  };

  const handleCancel = () => {
    window.location.href = "/";
  };

  const history = useHistory();

  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  if (getUserDetails()) {
    history.push({
      pathname: "/",
    });
    // window.location = "/";
  }

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div className="card-body lp-buttons-bg p-0 text-white">
            <div className="text-center py-5">
              <div className="mb-4">
                <img src={SuccessMsgIcon} alt="" width={100} />
              </div>
              <div className="mb-5">
                <h3 className="text-white fw-800">
                  A reset link was sent to your email.
                </h3>
              </div>
              <Button
                onClick={handleCancel}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Home
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div class="register-photo" style={{ marginTop: -70 }}>
        <div class="form-container">
          <div class="image-holder d-none d-md-table-cell"></div>

          <div class="login-container">
            <br />
            <br />

            <div class="text-center">
              <Logo cssclass="mb-4" imagewidth="100" />
            </div>

            <ul
              class="nav nav-tabs mb-3 justify-content-between border-0"
              id="ex1"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active text-green-2 fs-25px fw-800 text-uppercase letter-spacing-3"
                  id="ex1-tab-1"
                  data-bs-toggle="tab"
                  href="#ex1-tabs-1"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected="true"
                >
                  Forgot Password
                </a>
              </li>
            </ul>
            <div class="tab-content" id="ex1-content">
              <div
                class="tab-pane fade show active"
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <Spin spinning={isButtonLoading} size="large">
                  <Form
                    style={{ padding: "0px 0px 0px 0px" }}
                    onFinish={onFinish}
                  >
                    <ForgotPasswordContainer
                    />
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
          {/* </Form> */}
        </div>
      </div>
    </>
  );
}

ForgotPassword.defaultProps = {
  errorMessage: {},
};

ForgotPassword.propTypes = {};

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
