import React from 'react'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RegionsBillersSlider = props => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Slider {...settings}>
                <div className="mx-2">
                    <div className="card border-0 rounded-top-1 rounded-bottom-1 box-shadow-1 d-inline-block">
                        <div className="card-header rounded-top-1 blue-gradient-bg-2">
                            <img src="assets/img/flags/zim-flag.png" alt="" className="img-fluid me-3 d-inline-block"
                                width={25}
                            />

                            <p className="text-white mb-0 fw-700 fs-12px d-inline-block">
                                Billers in Zimbabwe
                            </p>
                        </div>

                        <div className="card-body p-3">
                            <div className="rounded-circle box-shadow-1 d-inline-block"
                                style={{
                                    backgroundImage: 'url("assets/img/regions/edgars.png")',
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: 'url("assets/img/regions/dstv.png")',
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: 'url("assets/img/regions/council.png")',
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-2">
                    <div className="card border-0 rounded-top-1 rounded-bottom-1 box-shadow-1 d-inline-block">
                        <div className="card-header rounded-top-1 blue-gradient-bg-2">
                            <img src="assets/img/flags/south-africa.png" alt="" className="img-fluid me-3 d-inline-block"
                                width={25}
                            />

                            <p className="text-white mb-0 fw-700 fs-12px d-inline-block">
                                Billers in South Africa
                            </p>
                        </div>

                        <div className="card-body p-3">
                            <div className="rounded-circle box-shadow-1 d-inline-block"
                                style={{
                                    backgroundImage: "url('assets/img/regions/mtn.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/telkom.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/vodacom.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 60,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-2">
                    <div className="card border-0 rounded-top-1 rounded-bottom-1 box-shadow-1 d-inline-block">
                        <div className="card-header rounded-top-1 blue-gradient-bg-2">
                            <img src="assets/img/flags/nigeria.png" alt="" className="img-fluid me-3 d-inline-block"
                                width={25}
                            />

                            <p className="text-white mb-0 fw-700 fs-12px d-inline-block">
                                Billers in Nigeria
                            </p>
                        </div>

                        <div className="card-body p-3">
                            <div className="rounded-circle box-shadow-1 d-inline-block"
                                style={{
                                    backgroundImage: "url('assets/img/regions/mtn.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/glo.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/airtel.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 45,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-2">
                    <div className="card border-0 rounded-top-1 rounded-bottom-1 box-shadow-1 d-inline-block">
                        <div className="card-header rounded-top-1 blue-gradient-bg-2">
                            <img src="assets/img/flags/kenya.png" alt="" className="img-fluid me-3 d-inline-block"
                                width={25}
                            />

                            <p className="text-white mb-0 fw-700 fs-12px d-inline-block">
                                Billers in Kenya
                            </p>
                        </div>

                        <div className="card-body p-3">
                            <div className="rounded-circle box-shadow-1 d-inline-block"
                                style={{
                                    backgroundImage: "url('assets/img/regions/safaricom.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 55,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/telkom.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/airtel.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 45,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-2">
                    <div className="card border-0 rounded-top-1 rounded-bottom-1 box-shadow-1 d-inline-block">
                        <div className="card-header rounded-top-1 blue-gradient-bg-2">
                            <img src="assets/img/flags/uganda.png" alt="" className="img-fluid me-3 d-inline-block"
                                width={25}
                            />

                            <p className="text-white mb-0 fw-700 fs-12px d-inline-block">
                                Billers in Uganda
                            </p>
                        </div>

                        <div className="card-body p-3">
                            <div className="rounded-circle box-shadow-1 d-inline-block"
                                style={{
                                    backgroundImage: "url('assets/img/regions/smile.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/mtn.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 40,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                            <div className="rounded-circle box-shadow-1 d-inline-block ms-2"
                                style={{
                                    backgroundImage: "url('assets/img/regions/airtel.png')",
                                    backgroundPosition: "center center",
                                    backgroundSize: 45,
                                    backgroundRepeat: "no-repeat",
                                    height: 60,
                                    width: 60
                                }}>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

RegionsBillersSlider.propTypes = {

}

export default RegionsBillersSlider
