import React, { useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

import locationIcon from "assets/kiosks/location_pin.png";
import filterCoodinates from "utils/filterCoodinates";
import { useEffect } from "react";

const center = {
  lat: -19.2102354,
  lng: 30.4544138,
};

function Map({
  location,
  branches,
  setIsDirectionsModalVisible,
  setDestination,
}) {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const filteredBranch = filterCoodinates(branches, null);
  const markers = filteredBranch?.map((branch) => {
    return {
      id: branch.id,
      name: branch.name,
      contact: branch.contact,
      position: { lat: branch.latitude || 0, lng: branch.longitude || 0 },
    };
  });

  if (location?.latitude && location?.longitude) {
    markers.push({
      id: 6,
      name: "Your location",
      position: {
        lat: location.latitude || 0,
        lng: location.longitude || 0,
      },

      icon: locationIcon,
      animation: 1,
      type: true,
    });
  } else {
    markers.push({
      id: 6,
      name: "Capital City",
      position: {
        lat: location.latitude || -17.8323456,
        lng: location.longitude || 31.04768,
      },

      icon: locationIcon,
      animation: 1,
      type: true,
    });
  }

  useEffect(() => {
    setActiveMarker(null);
  }, [location]);

  const handleOnLoad = (map) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // markers.forEach(({ position }) => bounds.extend(position));
    // map.fitBounds(bounds);
  };

  return (
    <>
      <GoogleMap
        zoom={6}
        center={center}
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ height: "290px" }}
      >
        {/* <DirectionsRenderer directions={directions} /> */}
        {markers.map(
          ({ id, name, contact, position, icon, animation, type }) => (
            <>
              {id !== 23 ? (
                <Marker
                  key={id}
                  position={position}
                  icon={icon}
                  animation={animation}
                  onClick={() => handleActiveMarker(id)}
                >
                  {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <>
                        <center>
                          <div className="text-green-2 fw-600">
                            {name.toUpperCase()}
                          </div>
                          {!type ? (
                            <>
                              {" "}
                              <br />
                              <div ext-green-2 fw-600>
                                {contact}
                              </div>
                              <br />
                              <div>
                                <a
                                  href
                                  onClick={() => {
                                    setIsDirectionsModalVisible(true);
                                    setDestination({
                                      latitude: position.lat,
                                      longitude: position.lng,
                                    });
                                  }}
                                  className="
                                            btn btn-sm
                                            text-uppercase
                                            fw-600
                                            rounde
                                            letter-spacing-2
                                             blue-gradient-bg-1
                                            border-0
                                            text-white
                                          "
                                >
                                  Show directions
                                </a>
                              </div>
                            </>
                          ) : null}
                        </center>
                      </>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ) : null}
            </>
          )
        )}
      </GoogleMap>
      <br />
    </>
  );
}

export default Map;
