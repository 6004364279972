import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import getProductsRoutine, {
  addToCartRoutine,
  getCategoriesRoutine,
  checkoutRoutine,
  cartCounterRoutine,
  removeFromCartRoutine,
  updateCartRoutine,
} from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
  reloadRequested: false,
  isVoucherCreated: false,
});

export default function storeFrontReducer(state = initialState, action) {
  switch (action.type) {
    case getProductsRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case getProductsRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });

    case getProductsRoutine.SUCCESS:
      return state.merge({
        products: action.payload.data.payload,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case getProductsRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
      });

    case getCategoriesRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case getCategoriesRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });

    case getCategoriesRoutine.SUCCESS:
      return state.merge({
        categories: action.payload.data.results,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case getCategoriesRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
      });

    // add to cart routines
    case addToCartRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case addToCartRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case addToCartRoutine.SUCCESS:
      return state.merge({
        cart: action.payload,
      });
    case addToCartRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: action.payload ? action.payload.message : "",
      });

    // remove from cart routines
    case removeFromCartRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case removeFromCartRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case removeFromCartRoutine.SUCCESS:
      return state.merge({
        cart: action.payload,
      });
    case removeFromCartRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: action.payload ? action.payload.message : "",
      });

    // add to cart routines
    case checkoutRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case checkoutRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case checkoutRoutine.SUCCESS:
      localStorage.removeItem("cartInfo");
      return state.merge({
        cart: [],
        isVoucherCreated: true,
        vouchers: action.payload.data,
      });
    case checkoutRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: action.payload ? action.payload.message : "",
        isVoucherCreated: false,
      });

    // cart count
    case cartCounterRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case cartCounterRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case cartCounterRoutine.SUCCESS:
      return state.merge({
        counter: action.payload,
      });
    case cartCounterRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: action.payload ? action.payload.message : "",
      });

    // get cart from localstorage
    case updateCartRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case updateCartRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case updateCartRoutine.SUCCESS:
      return state.merge({
        cart: action.payload,
      });
    case updateCartRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: action.payload ? action.payload.message : "",
      });
    default:
      return state;
  }
}
