import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import add from "./assets/shop/img/icons/plus.png";
// import minus from "./assets/shop/img/icons/minus.png";
import getConfig from "config";
import PlusIcon from "assets/img/icons/plus.svg"
import MinusIcon from "assets/img/icons/minus.svg"
/**
 * @summary Render Products Handle component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const Product = (props) => {
  const config = getConfig();
  const { productName, picture, price, productID, handleInputChange } = props;

  const [count, setCount] = useState(0);

  const increaseCounter = () => {
    setCount(count + 1);
  };

  const dereaseCounter = () => {
    if (count > 0) {
      setCount(count - 1);
    } else {
      setCount(0);
    }
  };

  var pictureUrl = picture.replace(
    "http://0.0.0.0",
    `${config.api.ip_address}`
  );

  return (
    <>
      <div className="col-12 col-lg-4">
        <div className="card border-0 mt-4 box-shadow-1">
          <div className="card-body light-grey-bg text-center p-0">
            <div className="p-4">
              <div className="height-50">
                <h6 className="fw-500 letter-spacing-3 text-green-2">
                  {productName}
                </h6>
                <h6 className="fw-800 letter-spacing-3 text-green-2">
                  ${price.toFixed(2)}
                </h6>
              </div>
              <div className="py-4" style={{ height: 140 }}>
                <img src={pictureUrl} alt="" className="img-fluid" width={130} />
              </div>
              <div className="add-buttons d-flex mx-5 mx-lg-3 mt-2 justify-content-between align-items-center">
                <Link to="#" onClick={() => increaseCounter()} className="btn border-0 bg-green-2 p-2 rounded-0">
                  <img src={PlusIcon} alt="" width={20} />
                </Link>
                <span className="text-green-2 fw-700 fs-20px mx-lg-3">{count}</span>
                <Link to="#" onClick={() => dereaseCounter()} className="btn border-0 bg-green-2 p-2 rounded-0">
                  <img src={MinusIcon} alt="" width={20} />
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <Link
                  to="#"
                  onClick={() =>
                      handleInputChange(
                          productID,
                          price,
                          count,
                          pictureUrl,
                          productName
                      )
                  }
                  className="w-100 py-3 btn rounded-0 fs-20px fw-800 letter-spacing-3 text-white orange-gradient-bg-2 text-uppercase border-0">
                add to cart
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Product.defaultProps = {};

Product.propTypes = {
  productName: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  productID: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default Product;
