import React from "react";
import LogoSvg from "assets/img/logo-sm.svg"

const Logo = (props) => {
  return (
    <>
      <img src={LogoSvg} alt="" width="100" />
    </>
  );
};

Logo.defaultProps = {};

Logo.propTypes = {};

export default Logo;
