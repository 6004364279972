import React from "react";
import PlayStoreIcon from "assets/img/footer/play-store.svg";
import AppStoreIcon from "assets/img/footer/app-store.svg";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Footer = (props) => {
  return (
    <>
      <footer className="box-shadow-2">
        <div className="container py-5">
          <div className="d-lg-flex justify-content-between text-center text-lg-start">
            <div>
              <h5 className="footer-follow-us">Follow us</h5>

              <div className="mb-3">
                <a
                  target="blank"
                  href="https://www.facebook.com/tumaizw"
                  className="footer-social-icon"
                >
                  <img
                    src="./assets/img/icons/social/facebook-icon.svg"
                    alt=""
                    className="img-fluid"
                    width="35"
                  />
                </a>

                <a
                  target="blank"
                  href="https://www.instagram.com/tumai.to/"
                  className="footer-social-icon ms-2"
                >
                  <img
                    src="./assets/img/icons/social/instagram-icon.svg"
                    alt=""
                    className="img-fluid"
                    width="35"
                  />
                </a>

                <a
                  target="blank"
                  href="https://www.linkedin.com/company/tumai-zw"
                  className="footer-social-icon ms-2"
                >
                  <img
                    src="./assets/img/icons/social/linkedin-icon.svg"
                    alt=""
                    className="img-fluid"
                    width="35"
                  />
                </a>

                <a
                  target="blank"
                  href="https://twitter.com/tumai_to"
                  className="footer-social-icon ms-2"
                >
                  <img
                    src="./assets/img/icons/social/twitter-icon.svg"
                    alt=""
                    className="img-fluid"
                    width="35"
                  />
                </a>
              </div>
            </div>

            <div>
              <h5 className="footer-follow-us">Quick Links</h5>

              <ul className="footer-follow-us-ul">
                <li className="footer-ql-li">
                  <ScrollLink
                    to="contactUs"
                    spy={true}
                    smooth={true}
                    offset={-72}
                    duration={200}
                  >
                    Contact Us
                  </ScrollLink>
                </li>

                <li className="footer-ql-li">
                  <ScrollLink
                    to="howItWorks"
                    spy={true}
                    smooth={true}
                    offset={-72}
                    duration={200}
                  >
                    About us
                  </ScrollLink>
                </li>
              </ul>
            </div>

            <div>
              {/* <Popover content="Coming soon" title=""> */}
                <a
                  href="https://apps.apple.com/tt/app/tumai-mobile-app/id1601235263"
                  className="footer-app-store-link"
                >
                  <img
                    src={AppStoreIcon}
                    alt=""
                    className="img-fluid"
                    width={150}
                  />
                </a>
              {/* </Popover> */}

              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="https://play.google.com/store/apps/details?id=com.intelliafricasolutions.tumai"
                className="footer-play-store-link mt-2"
              >
                <img
                  src={PlayStoreIcon}
                  alt=""
                  className="img-fluid"
                  width={150}
                />
              </a>
            </div>

            <div className="pt-4 pt-lg-0 text-center">
              <ul className="footer-follow-us-ul mt-4">
                <li className="footer-ql-li">
                  <Link to="/cookies-policy">Cookies Policy</Link>
                </li>
                <li className="footer-ql-li">
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li className="footer-ql-li">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
