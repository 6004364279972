import { createRoutine } from 'redux-saga-routines';

const loginRoutine = createRoutine('LOGIN');
export default loginRoutine;

export const removeMessagesRoutine = createRoutine('REMOVE_MESSAGES');
export const registerRoutine = createRoutine('REGISTER_USER');
export const getUserDetailsRoutine = createRoutine('GET_USER_DETAILS');
export const saveEmailAndPasswordRoutine = createRoutine('SAVE_EMAIL_PASSWORD');

