import React, { useState, useEffect } from "react";
import { Button, Empty, Form, Modal, notification, Spin } from "antd";

import { MdKeyboardArrowRight } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import locationIcon from "assets/kiosks/location_icon.png";
import Map from "./maps/Map";
import MapDirections from "./maps/MapDirections";
import { useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import getConfig from "config";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import ContactUs from "./ContactUs";

// import { Bounce, Flip } from "react-reveal";

export default function Locations({ id }) {
  const config = getConfig();
  const mapApiKey = config?.api?.mapApiKey;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: mapApiKey,
    libraries: ["places"],
  });

  const [location, setLocation] = useState({});
  const [destination, setDestination] = useState({});
  const [branches, setBranches] = useState([]);
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);
  const [distance, setDistance] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [onChangeTriggered, setOnChangeTriggered] = useState(false);
  const [isDirectionsModalVisible, setIsDirectionsModalVisible] =
    useState(false);

  const noBoothFoundMessage = `No booths were found ${distance}km away from your location`;

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocations = () => {
    setIsLoading(true);
    const url = `${config.api.agentsUrl}remittance/approved/kiosk/`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);

          setBranches(res.data.outlets);
        }
      })
      .catch((error) => {
        // setButtonLoad(false);
        setIsLoading(false);
      });
  };

  const findKiosks = (values) => {
    setIsLocationsLoading(true);
    if (onChangeTriggered) {
      setIsLocationsLoading(false);
      notification.warning({
        message: "Error",
        description: "Please select your location from the suggested locations",
        className: "custom-class",
        style: {
          top: 70,
        },
      });
    }
    const url = `${config.api.agentsUrl}company/search/branches/`;

    const payload = {
      latitude: location?.latitude,
      longitude: location?.longitude,
      distance: values.distance,
    };

    setLocation({
      latitude: location?.latitude,
      longitude: location?.longitude,
    });

    setDistance(values.distance);

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    };

    axios
      .post(url, payload, headers)
      .then((res) => {
        // setButtonLoad(false);
        if (res.status === 200) {
          setIsLocationsLoading(false);
          setBranches(res.data.branches);
        }
      })
      .catch((error) => {
        setIsLocationsLoading(false);
      });
  };

  const saveGeoLocations = (lat, lng) => {
    setLocation({
      latitude: lat ? lat : location?.latitude,
      longitude: lng ? lng : location?.longitude,
    });
  };

  return (
    <>
      <section className="kiosks-bg-6" >
        <div className="container py-5"id={id}>
          <div className=" align-items-center mb-3">
            <div class="row">
              <div class="col-md-1">
                {/* <Flip cascade> */}
                <img src={locationIcon} height="100" alt="" />
                {/* </Flip> */}
              </div>
              <div class="col-md-11" style={{ paddingLeft: "2em" }}>
                {" "}
                <h1 className="text-white fw-800">Kiosk Locator</h1>
                {/* <div className="kiosk-hr bg-white w-50 opacity-75 d-none d-md-inline-block" /> */}
                <h3 className="text-white fw-500">
                  Find a Tumai Kiosk near you.
                </h3>
              </div>{" "}
            </div>
          </div>

          <br />
          <br />
          <div className="row">
            {/* <Bounce left> */}
            <div className="col-md-6 mb-5">
              <div className="card border-2 border-radius-5 p-3">
                <div className="card-body">
                  <Spin spinning={isLocationsLoading}>
                    <Form
                      style={{ padding: "0px 0px 0px 0px" }}
                      onFinish={findKiosks}
                      initialValues={{ distance: "10" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-5">
                            {branches.length === 0 ? (
                              <>
                                <p className="text-orange-1">
                                  {noBoothFoundMessage}
                                </p>
                              </>
                            ) : (
                              <br />
                            )}
                            <h6 className="form-label text-green-2 fw-500">
                              Your Location
                            </h6>
                            {isLoaded ? (
                              <ReactGoogleAutocomplete
                                placeholder="Type your location here"
                                className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                                onPlaceSelected={(place) => {
                                  setOnChangeTriggered(false);
                                  saveGeoLocations(
                                    place?.geometry?.location.lat(),
                                    place?.geometry?.location.lng()
                                  );
                                }}
                                onChange={(event) => {
                                  setOnChangeTriggered(true);
                                }}
                              />
                            ) : (
                              <center>
                                <Spin />
                              </center>
                            )}
                          </div>
                          <div className="mb-5">
                            <h6 className="form-label text-green-2 fw-500">
                              Select Radius
                            </h6>

                            <Form.Item
                              name="distance"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Email Address!",
                                },
                              ]}
                            >
                              <select
                                // onChange={(event) => {
                                //   setDistance(event?.target?.value);
                                // }}
                                className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                              >
                                <option value="10">10km</option>
                                <option value="50">50km</option>
                                <option value="100">100km</option>
                                <option value="200">200km</option>
                                <option value="300">300km</option>
                                <option value="400">400km</option>
                              </select>
                            </Form.Item>
                          </div>

                          <button
                            type="submit"
                            className="
                                            btn btn-lg
                                            w-100
                                            text-uppercase
                                            fw-800
                                            rounde
                                            letter-spacing-3
                                             orange-gradient-bg-2
                                            border-0
                                            text-white
                                          "
                          >
                            <FaSearch style={{ marginRight: "12px" }} />
                            Find a kiosk
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Spin>
                </div>
              </div>
            </div>
            {/* </Bounce> */}
            {/* <Bounce right> */}
            <div className="col-md-6 mb-5">
              {isLoaded ? (
                <>
                  <div className="card border-2 ">
                    <div className="card-body">
                      <Map
                        location={location}
                        branches={branches}
                        setIsDirectionsModalVisible={
                          setIsDirectionsModalVisible
                        }
                        setDestination={setDestination}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <center>
                  <br />
                  <br />
                  <br />
                  <Spin />
                </center>
              )}
            </div>
            {/* </Bounce> */}
            <div className="col-md-12"></div>
            <div className="col-md-12">
              <Spin spinning={isLoading}>
                <div className="card border-2 border-radius-5">
                  <div className="card-body scroll-container  mw-card-ervices-card-bg">
                    <div className="row">
                      {!!branches &&
                        branches?.map((branch) => {
                          return (
                            <>
                              {branch.id !== 23 ? (
                                <>
                                  <div className="col-12 p-4 mb-2">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                      {/* <div className="mb-5"> */}
                                      <h4
                                        className="text-green-2 fw-800"
                                        style={{ marginRight: "12px" }}
                                      >
                                        {branch?.name}
                                      </h4>
                                      <div className="kiosk-hr green-gradient-bg-1 w-75 opacity-50 d-none d-md-inline-block" />
                                    </div>
                                    {/* </div> */}
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h6 className="text-green-2 fw-800 text-align-justify mb-1">
                                          Address
                                        </h6>
                                        <h6 className="text-green-2 fw-600 text-align-justify mb-1">
                                          {branch?.location}
                                        </h6>
                                        <br />

                                        <h6 className="text-green-2 fw-800 text-align-justify mb-1">
                                          Contact
                                        </h6>
                                        <h6 className="text-green-2 fw-600 text-align-justify mb-1">
                                          {branch?.contact}
                                        </h6>
                                        <br />
                                        <a
                                          href
                                          onClick={() => {
                                            if (
                                              branch.latitude &&
                                              branch.longitude
                                            ) {
                                              setDestination({
                                                latitude: branch.latitude,
                                                longitude: branch.longitude,
                                              });
                                              setIsDirectionsModalVisible(true);
                                            } else {
                                              notification.error({
                                                message: "Error",
                                                description:
                                                  "Branch geo location not found. Please try again later",
                                                className: "custom-class",
                                                style: {
                                                  top: 70,
                                                },
                                              });
                                            }
                                          }}
                                          className="
                                            btn btn-lg
                                            w-80
                                            text-uppercase
                                            fw-800
                                            rounde
                                            letter-spacing-3
                                             blue-gradient-bg-1
                                            border-0
                                            text-white
                                          "
                                        >
                                          Get Directions
                                          <MdKeyboardArrowRight
                                            size="1.7em"
                                            style={{ marginRight: "12px" }}
                                          />
                                        </a>
                                      </div>
                                      <div class="col-md-6">
                                        <h5 className="text-green-2 fw-800 text-align-justify mb-1">
                                          Operating Hours
                                        </h5>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              Monday
                                            </h6>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              <span
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                              >
                                                8:00AM - 5:00PM
                                              </span>
                                            </h6>
                                          </div>
                                        </div>

                                        <div class="row">
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              Tuesday
                                            </h6>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              <span
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                              >
                                                8:00AM - 5:00PM
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              Wednesday
                                            </h6>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              <span
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                              >
                                                8:00AM - 5:00PM
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              Thursday
                                            </h6>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              <span
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                              >
                                                8:00AM - 5:00PM
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              Friday
                                            </h6>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              <span
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                              >
                                                8:00AM - 5:00PM
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              Saturday
                                            </h6>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              <span
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                              >
                                                8:00AM - 3:00PM
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              Sunday
                                            </h6>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 className="text-green-2 fw-500 text-align-justify mb-2">
                                              <span
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                              >
                                                Closed
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          );
                        })}

                      {branches.length === 0 ? (
                        <>
                          <Empty description={noBoothFoundMessage} />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
          </div>

          {/* <LocationCards /> */}
        </div>

        {/* <div className="col-md-12">
          <div className="container  ">
            <div className="col-12">
              <div className="card border-2  kiosks-bg-3 border-radius-5">
                <div className="card-body ">
                  <div className="row">
                    <div className="container py-4">
                      <div className="row py-lg-3">
                        <div className="col-lg-5 col-xxl-4">
                          <h1
                            className="text-white fw-800 kiosk-gradient-text "
                            style={{ marginTop: "-20px" }}
                          >
                            Fast & Affordable local cash remittances @ 5%
                          </h1>
                        </div> */}

                         {/* <div className='col-8'>
                            <div
                                className='card border-0 box-shadow-1 mt-4 bg-transparent'
                                style={{ borderRadius: 25 }}
                            >
                                <div
                                    className='card-body p-0 bg-transparent'
                                    style={{ borderRadius: 25 }}
                                >
                                    <div className='charges-card-header py-3'>
                                        <h2 className='text-white text-center fw-800 mb-0 fs-16px'>
                                            Charges
                                        </h2>
                                    </div>

                                    <div
                                        className='p-2 charges-overlay'
                                        style={{
                                            borderBottomLeftRadius: 25,
                                            borderBottomRightRadius: 25
                                        }}
                                    >
                                        <table className='table table-borderless'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-600'>
                                                            Sending $1 - $30
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            ($1)
                                                        </h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px fw-600'>
                                                            Sending $31 - $60
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            ($3)
                                                        </h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-600'>
                                                            Sending $61 - $100
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            ($5)
                                                        </h4>
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-600'>
                                                            Sending over $100
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            (5%)
                                                        </h4>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>  */}
                      {/* </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <br />
        <div className="col-md-12">
          <div className="container  ">
            <div className='kiosks-bg-3' id='charges'>
                <   div className='container'>
                        <div className='row align-items-center'>
                        <div className='row py-lg-3'>
                            <div className='col-lg-5 col-xxl-4'>
                            <h1
                              className="text-white fw-800 kiosk-gradient-text "
                              style={{ marginTop: "-20px" }}
                            >
                              Fast & Affordable
                            </h1>
                                <h2 className='text-green-2 fw-600'>
                                    <em>local</em> cash transfers
                                </h2>

                                <div
                                    className='card border-0 box-shadow-1 mt-4'
                                    style={{ borderRadius: 25 }}
                                >
                                    <div className='card-body p-0'>
                                        <div className='charges-card-header py-3'>
                                            <h2 className='text-white text-center fw-800 mb-0'>
                                                Charges
                                            </h2>
                                        </div>

                                        <div className='p-4'>
                                        <table className='table table-borderless'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-600'>
                                                            Sending $1 - $30
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            ($1)
                                                        </h4>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px fw-600'>
                                                            Sending $31 - $60
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            ($3)
                                                        </h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-600'>
                                                            Sending $61 - $100
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            ($5)
                                                        </h4>
                                                    </td>
                                                    
                                                </tr> */}
                                                <tr>
                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-600'>
                                                            Sending over $30
                                                        </h4>
                                                    </td>

                                                    <td>
                                                        <h4 className='text-green-2 text fs-14px mb-0 fw-800'>
                                                            (5%)
                                                        </h4>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        <br/>

        <div className="col-md-12">
          <div className="container  ">
            <ContactUs id="contactUs" />
          </div>
        </div>
        <br />
      </section>

      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        onCancel={() => {
          setIsDirectionsModalVisible(false);
        }}
        visible={isDirectionsModalVisible}
        width={750}
      >
        <div>
          <div class="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              {isLoaded ? (
                <>
                  <MapDirections
                    location={location}
                    destination={destination}
                  />
                </>
              ) : (
                <center>
                  <br />
                  <br />
                  <br />
                  <Spin />
                </center>
              )}
              <Button
                onClick={() => {
                  setIsDirectionsModalVisible(false);
                }}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
