/** @constant
    @type {object}
    @default
*/

const getAccessToken = () => {
  const userInfo = localStorage.getItem("userInfo");

  if (userInfo !== null) {
    const loginToken = JSON.parse(userInfo).loginToken;
    return loginToken;
  }
  return false;
};

const access_token = getAccessToken();

const apiKey = "ias@tumai";
const apiUsername = "ias@tumai";
const url = "https://www.api.tumai.to/api/v1/";
const remmitance_url = "https://webservice.tumai.to/api/v1/";
const tumai_url = "/api/v1/";
const tumai = "https://www.tumai.to";
const ip_address = "https://www.voucher.tumai.to";
const port = "";
const googleID =
  "194828570914-7bh6tf5s4m2perjekrlul466hghoetjf.apps.googleusercontent.com";
const facebookID = "1298169097313295";

// const agentsUrl = "http://192.81.211.54:9024/api/v1/";
const agentsUrl = "https://webservice.tumai.to/api/v1/";

// const mapApiKey = "AIzaSyCTLU5e5hXLy2sCe8eyjb2aX0T0lbAKWio";
const mapApiKey = "AIzaSyDDMQWv5eTA_-ELUip6MLFppythTofgQMs";
// const mapApiKey = "AIzaSyD3TSE_e-urDLM_3w2QB33Q01mABAadRn8";

// sudo cp -R build/ /var/www/tumai-react/
const config = {
  production: {
    api: {
      agentsUrl: agentsUrl,
      mapApiKey: mapApiKey,
      tumai: tumai,
      ip_address: ip_address,
      googleID: googleID,
      facebookID: facebookID,
      base_url: ip_address + port + tumai_url,
      tumai_url: url,
      token: access_token,
      apiKey: apiKey,
      apiUsername: apiUsername,
      remmitance_url: remmitance_url,
    },
  },
  local: {
    api: {
      agentsUrl: agentsUrl,
      tumai: tumai,
      mapApiKey: mapApiKey,
      ip_address: ip_address,
      googleID: googleID,
      facebookID: facebookID,
      base_url: ip_address + port + tumai_url,
      tumai_url: url,
      token: access_token,
      apiKey: apiKey,
      apiUsername: apiUsername,
      remmitance_url: remmitance_url,
    },
  },
  development: {
    api: {
      agentsUrl: agentsUrl,
      tumai: tumai,
      mapApiKey: mapApiKey,
      ip_address: ip_address,
      googleID: googleID,
      facebookID: facebookID,
      base_url: ip_address + port + tumai_url,
      tumai_url: url,
      token: access_token,
      apiKey: apiKey,
      apiUsername: apiUsername,
      remmitance_url: remmitance_url,
    },
  },
  staging: {
    api: {
      agentsUrl: agentsUrl,
      tumai: tumai,
      mapApiKey: mapApiKey,
      ip_address: ip_address,
      googleID: googleID,
      facebookID: facebookID,
      base_url: ip_address + port + tumai_url,
      tumai_url: url,
      token: access_token,
      apiKey: apiKey,
      apiUsername: apiUsername,
      remmitance_url: remmitance_url,
    },
  },
};

/**
 * Get env based configuration
 * @constructor
 * @returns {configuration}
 */
export default function getConfig() {
  const environment = process.env.NODE_ENV;

  if (["production", "development", "staging", "local"].includes(environment)) {
    const configuration = config[environment];
    return configuration;
  }
  throw new Error(`invalid value for NODE_ENV: ${environment}`);
}
