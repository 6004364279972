import React from "react";
import PropTypes from "prop-types";
import Balance from "./balance";
import Transactions from "./transactions";

const Cards = (props) => {
  const { transactions, balance } = props;
  return (
    <>
      <section id="cards">
        <div class="wallet-cards-bg py-5">
          <div class="container">
            <div class="card border-0 box-shadow-1">
              <div class="card-body p-5">
                <div class="d-flex align-items-center justify-content-between">
                  <h3 class="fw-800 text-green-2">My Account</h3>

                  <div class="mw-cs-title-line w-75 d-none d-md-inline-block bg-green-2"></div>
                </div>

                <div className="row">
                  <Balance balance={balance} />
                </div>
              </div>
            </div>
            <Transactions transactions={transactions} />
          </div>
        </div>
      </section>
    </>
  );
};

Cards.defaultProps = {};

Cards.propTypes = {
  transactions: PropTypes.array,
  balance: PropTypes.string,
};

export default Cards;
