import React from "react";
import Nav from "components/main/navigation";
import Footer from "components/footer/main";
import Form from "components/contact/form";
import Buttons from "components/contact/buttons";
import PropTypes from "prop-types";

const Logo = (props) => {
  const { header_titles } = props;
  return (
    <>
      <header>
        <Nav header_titles={header_titles} />
        <Form />
      </header>
      <Buttons />
      <Footer />
    </>
  );
};

Logo.defaultProps = {};

Logo.propTypes = {
  header_titles: PropTypes.string,
};

export default Logo;
