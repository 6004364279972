/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import ServiceItems from "components/main/services/serviceitems";
import PhoneNumber from "components/main/services/servicecomponents/phonenumber";
import { Form, Select } from "antd";
import Country from "../country";
import { Popover } from "antd";

import zesa from "assets/kiosks/icons/zesa.svg";
import grocery from "assets/kiosks/icons/grocery.svg";
import value from "assets/kiosks/icons/value.svg";
import bills from "assets/kiosks/icons/bills.svg";
import fees from "assets/kiosks/icons/fees.svg";
import airtime from "assets/kiosks/icons/airtime.svg";
import dstv from "assets/kiosks/icons/dstv.svg";

import Typical from "react-typical";
// import {rollIn} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CreateOrder from "./serviceitems/CreateOrder";

const { Option } = Select;

const Services = (props) => {
  const { services, rates, postTransaction, setPaymentPayload, id } = props;
  const [state, setState] = useState({
    activeRow: {},
  });

  const [isCountry, setCountry] = useState("");
  const [dstvCurrency, setDSTVCurrency] = useState("usd");
  const [paymentDetails, setPaymentDetails] = useState({
    paymentDetailsPayload: [],
  });
  const [converted_amount, setConvertedAmount] = useState(0);
  const [dstvAmount, setDstvAmount] = useState(0);
  const [dataAmount, setDataAmount] = useState({});
  const [selectedBiller, setSelectedBiller] = useState("");
  const [isCreateOrderModalVisible, setIsCreateOrderModalVisible] =
    useState(false);

  const activeRow = state;
  const paymentDetailsPayload = paymentDetails;

  const clearRatesCalculator = () => {
    setState({
      activeRow: {},
    });
    setDstvAmount(0);
    setConvertedAmount(0);
    setDSTVCurrency("usd");
    setCountry("");
  };

  // ===========================================================================
  //  Rate Calculator
  // ===========================================================================

  const getConvertedRate = (currency) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });

    var search = rates.filter((obj) => obj.from_currency === currency);
    // return search[0].converted_rate;
    return 1;
  };

  const getZarConvertedRate = (currency) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });

    var search = rates.filter((obj) => obj.from_currency === currency);
    return search[0].rate;
  };

  const getUsdConvertedRate = (currency) => {
    var search = rates.filter((obj) => obj.to_currency === currency);

    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    if (currency === "usd") {
      return 1;
    } else {
      // return 1;
      if (currency) {
        return search[0].converted_rate;
      } else {
        return 0;
      }
    }
  };

  // ============================================================================
  //  On Change Handlers
  // ============================================================================
  const onZwlCurrencyHandlerChange = (event) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    if (event.target.localName === "select") {
      activeRow[event.target.name] = event.target.value;
    }

    if (activeRow.amount) {
      setConvertedAmount((1 * activeRow.amount).toFixed(2));
    }

    setDSTVCurrency(event.target.value);

    setState({ ...state, activeRow });
  };

  //  On Change Handlers
  // ============================================================================
  const onZarCurrencyHandlerChange = (event) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    if (event.target.localName === "select") {
      activeRow[event.target.name] = event.target.value;
    }

    if (event.target.value) {
      if (activeRow.amount) {
        setConvertedAmount(
          (getZarConvertedRate(event.target.value) * activeRow.amount).toFixed(
            2
          )
        );
      }

      setDSTVCurrency(event.target.value);

      setState({ ...state, activeRow });
    }
  };

  const onchangeTransactionType = (event) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    if (event.target.localName === "select") {
      activeRow[event.target.name] = event.target.value;
    }

    const arrayLength = airtimeServices.length;
    let arraySearch = [];
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        name: airtimeServices[i].name,
        code: airtimeServices[i].code,
      });
    }

    var searchResults = filterItems(event.target.value, arraySearch, "code");

    let paymentPayload = [];
    paymentPayload.push({
      service: searchResults[0].name,
      description: "",
      subtotal: 0,
    });

    paymentDetailsPayload.paymentDetails = paymentPayload;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
  };

  const onUsdCurrencyHandlerChange = (event) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    if (event.target.localName === "select") {
      activeRow[event.target.name] = event.target.value;
    }

    setDSTVCurrency(event.target.value);

    if (event.target.value === "zwl") {
      if (activeRow.amount) {
        setConvertedAmount(
          (getUsdConvertedRate(event.target.value) * activeRow.amount).toFixed(
            2
          )
        );
      }
      if (activeRow.dstv_amount) {
        setConvertedAmount(
          (
            getUsdConvertedRate(event.target.value) * activeRow.dstv_amount
          ).toFixed(2)
        );
      }

      if (dstvAmount) {
        if (event.target.value) {
          setConvertedAmount(
            (getUsdConvertedRate(event.target.value) * dstvAmount).toFixed(2)
          );
        } else {
          setConvertedAmount(0);
        }
      }

      setState({ ...state, activeRow });
    } else {
      if (activeRow.amount) {
        setConvertedAmount(
          (getUsdConvertedRate(event.target.value) * activeRow.amount).toFixed(
            2
          )
        );
      }
      if (activeRow.dstv_amount) {
        setConvertedAmount(
          (
            getUsdConvertedRate(event.target.value) * activeRow.dstv_amount
          ).toFixed(2)
        );
      }

      if (dstvAmount) {
        if (event.target.value) {
          setConvertedAmount(
            (getUsdConvertedRate(event.target.value) * dstvAmount).toFixed(2)
          );
        } else {
          setConvertedAmount(0);
        }
      }

      setState({ ...state, activeRow });
    }
  };

  const onAmountChange = (event) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    let amount;

    if (dstvCurrency) {
      amount = event.target.value * getConvertedRate(dstvCurrency);
      setConvertedAmount(amount.toFixed(2));
    }

    setConvertedAmount(
      (getUsdConvertedRate(dstvCurrency) * event.target.value).toFixed(2)
    );

    activeRow.amount = event.target.value;

    setState({ ...state, activeRow });
  };

  const onZarAmountChange = (event) => {
    paymentDetailsPayload.rates = rates;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    let amount;

    if (dstvCurrency) {
      amount = event.target.value * getZarConvertedRate(dstvCurrency);
      setConvertedAmount(amount.toFixed(2));
    }

    setConvertedAmount(
      (getZarConvertedRate(dstvCurrency) * event.target.value).toFixed(2)
    );

    activeRow.amount = event.target.value;

    setState({ ...state, activeRow });
  };
  // ============================================================================
  //  End On Change Handlers
  // ============================================================================

  function filterItems(needle, heystack, nat) {
    var query = needle.toLowerCase();
    return heystack.filter(function (item) {
      return item[nat].toLowerCase().indexOf(query) >= 0;
    });
  }

  const isLoggedIn = () => {
    const userInfo = localStorage.getItem("userInfo");

    if (userInfo !== null) {
      return true;
    }
    return false;
  };

  // Todo set this if u only logged in users to access the shop
  let shopUrl;
  if (isLoggedIn()) {
    // shopUrl = "/shop";
  } else {
    // shopUrl = "/shop";
  }

  let airtimeServices = [];
  let schoolServices = [];
  let billerServices = [];
  let dstvBoquetServices = [];
  let dstvAddonsServices = [];
  let purposeServices = [];
  let voucherServices = [];

  if (services.SERVICES) {
    dstvBoquetServices = services.SERVICES["006"].additional_data.BOUQUETS;
    dstvAddonsServices = services.SERVICES["006"].additional_data.ADDONS;
    schoolServices = services.SERVICES["004"].additional_data.schools;
    purposeServices = services.SERVICES["004"].additional_data.purposes;
    billerServices = services.SERVICES["007"].additional_data.Billers;
    voucherServices = services.SERVICES["016"].additional_data.Billers;

    if (isCountry) {
      airtimeServices = services.SERVICES["00X"].additional_data;
      let airtimeArraySearch = [];
      const airtimeArrayLength = airtimeServices.length;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < airtimeArrayLength; i++) {
        airtimeArraySearch.push({
          code: airtimeServices[i].code,
          name: airtimeServices[i].name,
          country: airtimeServices[i].country,
          transaction_type: airtimeServices[i].transaction_type,
          additional_data: airtimeServices[i].additional_data,
        });
      }

      airtimeServices = filterItems(isCountry, airtimeArraySearch, "country");
    }
  }

  const handleSearch = (search) => {
    let arraySearch = [];
    const arrayLength = schoolServices.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        name: schoolServices[i].name,
        code: schoolServices[i].code,
      });
    }

    var searchResults = filterItems(search, arraySearch, "code");

    paymentDetailsPayload.productName = searchResults[0].name;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
  };

  const onSchoolChange = (search) => {
    let arraySearch = [];
    const arrayLength = schoolServices.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        name: schoolServices[i].name,
        code: schoolServices[i].code,
      });
    }

    var searchResults = filterItems(search, arraySearch, "code");

    paymentDetailsPayload.productName = searchResults[0].name;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
  };

  const onBillerChange = (search) => {
    setSelectedBiller(search);
    let arraySearch = [];
    const arrayLength = billerServices.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        code: billerServices[i].code,
        name: billerServices[i].name,
      });
    }

    var searchResults = filterItems(search, arraySearch, "code");

    let paymentPayload = [];
    paymentPayload.push({
      service: searchResults[0].name,
      description: "",
      subtotal: 0,
    });

    paymentDetailsPayload.paymentDetails = paymentPayload;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });

    let amount;
    amount = searchResults[0].usd_price;

    activeRow.dataAmount = amount;

    if (amount) {
      setConvertedAmount(
        (getUsdConvertedRate(dstvCurrency) * amount).toFixed(2)
      );
    }
    activeRow.dstv_amount = amount;
    setDstvAmount(amount);

    setState({ ...state, activeRow });
  };

  const getDataChange = (event) => {
    let arraySearch = [];
    let selectedData = activeRow.data;
    const arrayLength = selectedData.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        usd_price: selectedData[i].usd_price,
        price: selectedData[i].price,
        code: selectedData[i].code,
        name: selectedData[i].name,
      });
    }

    var searchResults = filterItems(event.target.value, arraySearch, "code");

    let paymentPayload = [];
    paymentPayload.push({
      service: searchResults[0].name,
      description: "",
      subtotal: searchResults[0].usd_price,
    });

    setDataAmount({
      usd_total: searchResults[0].usd_price,
      zwl_total: searchResults[0].price,
    });

    paymentDetailsPayload.paymentDetails = paymentPayload;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });

    let amount;
    amount = searchResults[0].usd_price;

    activeRow.dataAmount = amount;

    if (amount) {
      setConvertedAmount(
        (getUsdConvertedRate(dstvCurrency) * amount).toFixed(2)
      );
    }
    activeRow.dstv_amount = amount;
    setDstvAmount(amount);

    setState({ ...state, activeRow });
  };

  const getBouquet = (event) => {
    let arraySearch = [];
    const arrayLength = dstvBoquetServices.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        name: dstvBoquetServices[i].productName,
        cost: dstvBoquetServices[i].cost,
        productId: dstvBoquetServices[i].productId,
      });
    }

    var searchResults = filterItems(
      event.target.value,
      arraySearch,
      "productId"
    );

    let paymentPayload = [];
    paymentPayload.push({
      service: "Bouquet",
      description: searchResults[0].name,
      subtotal: searchResults[0].cost,
      rates: rates,
    });

    paymentDetailsPayload.paymentDetails = paymentPayload;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });

    activeRow.boquetAmount = searchResults[0].cost;

    setState({ ...state, activeRow });

    let total_price = 0;
    if (activeRow.addonAmount) {
      total_price = activeRow.addonAmount
        .map((item) => item.cost)
        .reduce((prev, curr) => prev + curr, 0);
    }

    let amount;

    amount = total_price + searchResults[0].cost;

    setDstvAmount(amount);

    setConvertedAmount((getUsdConvertedRate(dstvCurrency) * amount).toFixed(2));

    activeRow.dstv_amount = amount;

    setState({ ...state, activeRow });
  };

  const getAddons = (addonList) => {
    let arraySearch = [];
    const arrayLength = dstvAddonsServices.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        cost: dstvAddonsServices[i].COST,
        addonid: dstvAddonsServices[i].ADDONID,
        name: dstvAddonsServices[i].ADDONNAME,
      });
    }

    let addonCost = [];
    const addonLength = addonList.length;
    for (let i = 0; i < addonLength; i++) {
      let search = arraySearch.filter((obj) => obj.addonid === addonList[i]);
      addonCost.push(search[0]);
      var searchAddon = paymentDetailsPayload.paymentDetails.filter(
        (obj) => obj.description === search[0].name && obj.service === "Addons"
      );

      if (searchAddon.length > 0) {
        searchAddon[0].subtotal = search[0].cost;
      } else {
        paymentDetailsPayload.paymentDetails.push({
          service: "Addons",
          description: search[0].name,
          subtotal: search[0].cost,
          rates: rates,
        });
      }

      setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });
    }

    activeRow.addonAmount = addonCost;
    setState({ ...state, activeRow });

    let boquet_amount = 0;
    if (activeRow.boquetAmount) {
      boquet_amount = activeRow.boquetAmount;
    }

    const total_price = addonCost
      .map((item) => item.cost)
      .reduce((prev, curr) => prev + curr, 0);

    let amount;

    amount = boquet_amount + total_price;

    setDstvAmount(amount);

    setConvertedAmount((getUsdConvertedRate(dstvCurrency) * amount).toFixed(2));

    activeRow.dstv_amount = amount;

    setState({ ...state, activeRow });
  };

  const handleBillerSearch = (search) => {
    let arraySearch = [];
    const arrayLength = billerServices.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        name: billerServices[i].name,
        code: billerServices[i].code,
      });
    }

    var searchResults = filterItems(search, arraySearch, "code");

    activeRow.biller = searchResults;

    setState({ ...state, activeRow });
  };

  const onServiceProviderChange = (event) => {
    const search = event.target.value;
    let arraySearch = [];
    const arrayLength = airtimeServices.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        name: airtimeServices[i].name.replace("Airtime", ""),
        code: airtimeServices[i].code,
        additional_data: airtimeServices[i].additional_data,
      });
    }

    var searchResults = filterItems(
      search.substring(0, search.length - 1),
      arraySearch,
      "code"
    );

    paymentDetailsPayload.productName = searchResults[0].name;

    setPaymentDetails({ ...paymentDetails, paymentDetailsPayload });

    let dataArraySearch = [];
    const dataArrayLength = searchResults.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dataArrayLength; i++) {
      dataArraySearch = searchResults[i].additional_data.packages;
    }

    activeRow.data = dataArraySearch;

    setState({ ...state, activeRow });
  };

  let schoolsArray = [];
  if (activeRow.schools) {
    schoolsArray = activeRow.schools;
  } else {
    schoolsArray = schoolServices;
  }

  let billerArray = [];
  let billerKeys = [];
  if (activeRow.biller) {
    billerArray = activeRow.biller;
  } else {
    billerArray = billerServices;
  }

  if (billerArray) {
    let dataResult = billerArray.reduce(function (r, a) {
      r[a.biller_type] = r[a.biller_type] || [];
      r[a.biller_type].push(a);
      return r;
    }, Object.create(null));
    billerArray = dataResult;

    billerKeys = Object.keys(billerArray);
  }

  let dataArray = [];
  let keys = [];
  if (activeRow.data) {
    dataArray = activeRow.data;

    let dataResult = dataArray.reduce(function (r, a) {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, Object.create(null));
    dataArray = dataResult;

    keys = Object.keys(dataArray);
  }

  const submitButton = (
    <>
      <input
        className="blue-gradient-bg-1 btn btn-lg text-white letter-spacing-3 text-uppercase fw-700 border-0 rounded-0 w-100"
        type="submit"
        value="continue"
      />
    </>
  );

  let rates_component = (
    <select
      required
      className="form-select fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    >
      <option selected disabled value="">
        Currency To ZWL
      </option>
    </select>
  );

  let counrtyAirtimeServices = airtimeServices;
  let countrySchoolsArray = [];
  let countryDstvBoquetServices = [];
  let countryDstvAddonsServices = [];
  let purposeServicesArray = [];
  let voucherBiller = [];
  let countryVoucherServices = [];
  if (isCountry === "zimbabwe") {
    // counrtyAirtimeServices = airtimeServices;
    countrySchoolsArray = schoolsArray;
    purposeServicesArray = purposeServices;
    countryDstvBoquetServices = dstvBoquetServices;
    countryDstvAddonsServices = dstvAddonsServices;
    voucherBiller = voucherServices;
  }

  let ratesOptions;
  let usdOptions;
  let usd_rates_component;

  if (Object.keys(rates).length > 0) {
    ratesOptions = (
      <>
        {/* {!!rates &&
          rates.map((items) => {
            return (
              <option key={items.from_currency} value={items.from_currency}>
                {items.from_currency.toUpperCase()}
              </option>
            );
          })} */}
        <option value="">Select currency</option>
        <option value="usd">USD</option>
        <option value="zwl">ZWL</option>
      </>
    );

    usdOptions = (
      <>
        {/* {!!rates &&
          rates.map((items) => {
            return (
              <option key={items.from_currency} value={items.from_currency}>
                {items.from_currency.toUpperCase()}
              </option>
            );
          })} */}
        <option value="">Select currency</option>
        <option value="usd">USD</option>
      </>
    );

    rates_component = (
      <Form.Item
        name="currency"
        rules={[{ required: true, message: "Please select currency!" }]}
      >
        <select
          name="currency"
          // onChange={(e) => onZwlCurrencyHandlerChange(e)}
          className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
        >
          {ratesOptions}
        </select>
      </Form.Item>
    );
  }

  const amount_component = (
    <Form.Item
      className=" mt-sm-0"
      name="amount"
      rules={[{ required: true, message: "Please input your amount!" }]}
    >
      <input
        onChange={(e) => onAmountChange(e)}
        className="form-control shadow-none border-2-green bg-transparent"
        type="text"
        step=".01"
        // value="5"
        // min="5"
        placeholder="Amount"
      />
    </Form.Item>
  );

  const zwl_amount_component = (
    <input
      disabled
      className="form-control shadow-none border-2-green bg-transparent"
      type="number"
      value={converted_amount}
      min="0"
      step=".01"
      placeholder="Total Amount (ZWL)"
    />
  );

  const email_address_component = (
    <Form.Item name="sender_email" className="mt-sm-0">
      <input
        className="form-control shadow-none border-2-green bg-transparent"
        type="email"
        placeholder="Email Address"
      />
    </Form.Item>
  );

  const country_component = (
    <Country
      setCountry={setCountry}
      class_name="form-select fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    />
  );

  const billerArrayLength = billerKeys.length;

  let show_amount;
  if (converted_amount) {
    show_amount = "$USD" + converted_amount;
  }
  let billersList = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < billerArrayLength; i++) {
    if (billerKeys[i].toUpperCase() === "INTERNET SERVICE PROVIDER") {
      billersList.push({
        name: billerKeys[i].toUpperCase(),
        data: [
          {
            item: <div className="col-12">{country_component}</div>,
          },
          {
            item: (
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    name="billerId"
                    rules={[
                      {
                        required: true,
                        message: "Please select biller!",
                      },
                    ]}
                  >
                    <Select
                      className="border-2-green bg-transparent text-green-2"
                      defaultValue=""
                      showSearch
                      filterOption={false}
                      onSearch={handleBillerSearch}
                      onChange={onBillerChange}
                    >
                      <Option value="" disabled selected>
                        Select Biller
                      </Option>

                      {billerArray[billerKeys[i]].map((items) => {
                        return (
                          <Option key={items.code} value={items.code}>
                            {items.name.toUpperCase()}{" "}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            ),
          },
          {
            item: (
              <div className="row ">
                <div className="col-12">
                  <Form.Item
                    name="accountNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter account number!",
                      },
                    ]}
                  >
                    <input
                      className="form-control shadow-none border-2-green bg-transparent"
                      type="text"
                      placeholder="Account Number"
                    />
                  </Form.Item>
                </div>
              </div>
            ),
          },
          {
            item: (
              <div className="row">
                <div className="col-12">
                  <PhoneNumber />
                </div>
              </div>
            ),
          },
          {
            item: (
              <div className="row">
                <div className="col-12">{email_address_component}</div>
              </div>
            ),
          },
        ],
      });
    } else {
      billersList.push({
        name: billerKeys[i].toUpperCase(),
        data: [
          {
            item: <div className="col-12">{country_component}</div>,
          },
          {
            item: (
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    name="billerId"
                    rules={[
                      {
                        required: true,
                        message: "Please select biller!",
                      },
                    ]}
                  >
                    <Select
                      className="border-2-green bg-transparent text-green-2"
                      defaultValue=""
                      showSearch
                      filterOption={false}
                      onSearch={handleBillerSearch}
                      onChange={onBillerChange}
                    >
                      <Option value="" disabled selected>
                        Select Biller
                      </Option>

                      {billerArray[billerKeys[i]].map((items) => {
                        return (
                          <Option key={items.code} value={items.code}>
                            {items.name.toUpperCase()}{" "}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            ),
          },
          {
            item: (
              <div className="row ">
                <div className="col-12">
                  <Form.Item
                    name="accountNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter account number!",
                      },
                    ]}
                  >
                    <input
                      className="form-control shadow-none border-2-green bg-transparent"
                      type="text"
                      placeholder="Account Number"
                    />
                  </Form.Item>
                </div>
              </div>
            ),
          },
          {
            item: (
              <div className="row">
                <div className="col-12">
                  <PhoneNumber />
                </div>
              </div>
            ),
          },
          {
            item: (
              <div className="row">
                <div className="col-12">{email_address_component}</div>
              </div>
            ),
          },
          {
            item:
              selectedBiller !== "014" ? (
                <div className="row mb-4 mb-sm-0">
                  <div className="col-sm-4">
                    <Form.Item
                      name="currency"
                      rules={[
                        {
                          required: true,
                          message: "Please select currency!",
                        },
                      ]}
                    >
                      <select
                        name="currency"
                        // onChange={(e) => onZwlCurrencyHandlerChange(e)}
                        className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
                      >
                        <option value="">Select currency</option>
                        <option value="zwl">ZWL</option>
                      </select>
                    </Form.Item>
                  </div>

                  <div className="col-sm-4">{amount_component}</div>

                  <div className="col-sm-4">{zwl_amount_component}</div>
                </div>
              ) : null,
          },
        ],
      });
    }
  }

  const serviceitems = [
    /**
     * ==================================================================================================
     * Buy Voucher
     * ==================================================================================================
     */
    // {
    //   image: "./assets/kiosks/icons/grocery.svg",
    //   modalTitle: "Buy Voucher",
    //   modalButton: submitButton,
    //   fields: [
    //     {
    //       item: <div className="col-12">{country_component}</div>,
    //     },
    //     {
    //       item: (
    //         <div className="col-12">
    //           <Form.Item
    //             name="biller"
    //             rules={[
    //               {
    //                 required: true,
    //                 message: "Please select biller!",
    //               },
    //             ]}
    //           >
    //             <select
    //               name="biller"
    //               className="form-select fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //             >
    //               <option selected value="">
    //                 Select Service Provider
    //               </option>
    //               {voucherBiller.map((items) => {
    //                 return (
    //                   <option key={items.code} value={items.code}>
    //                     {items.name.toUpperCase()}{" "}
    //                   </option>
    //                 );
    //               })}
    //             </select>
    //           </Form.Item>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-sm-6">
    //             <Form.Item
    //               name="recepient_first_name"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please enter Receiver Name!",
    //                 },
    //               ]}
    //             >
    //               <input
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="text"
    //                 placeholder="Receiver Name"
    //               />
    //             </Form.Item>
    //           </div>

    //           <div className="col-sm-6">
    //             <Form.Item
    //               name="recepient_last_name"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please enter Receiver Surname!",
    //                 },
    //               ]}
    //             >
    //               <input
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="text"
    //                 placeholder="Receiver Surname"
    //               />
    //             </Form.Item>
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-12">
    //             <Form.Item
    //               name="recepient_national_id"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please enter Receiver  National ID!",
    //                 },
    //               ]}
    //             >
    //               <input
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="text"
    //                 placeholder="Receiver National ID"
    //               />
    //             </Form.Item>
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-12">
    //             <Form.Item
    //               name="address"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please enter Receiver Address!",
    //                 },
    //               ]}
    //             >
    //               <textarea
    //                 class="form-control shadow-none border-2-green bg-transparent"
    //                 id="exampleFormControlTextarea1"
    //                 rows="1"
    //                 placeholder="Receiver Address"
    //               ></textarea>
    //             </Form.Item>
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-sm-6">
    //             <PhoneNumber />
    //           </div>

    //           <div className="col-sm-6">{email_address_component}</div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row mb-4 mb-sm-0">
    //           <div className="col-sm-4">
    //             {" "}
    //             <Form.Item
    //               name="currency"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please select currency!",
    //                 },
    //               ]}
    //             >
    //               <select
    //                 name="currency"
    //                 onChange={(e) => onUsdCurrencyHandlerChange(e)}
    //                 className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //               >
    //                 {usdOptions}
    //               </select>
    //             </Form.Item>
    //           </div>

    //           <div className="col-sm-4">
    //             {" "}
    //             <Form.Item
    //               className=" mt-sm-0"
    //               name="amount"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please input your amount!",
    //                 },
    //               ]}
    //             >
    //               <input
    //                 onChange={(e) => onAmountChange(e)}
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="text"
    //                 step=".01"
    //                 // value="5"
    //                 // min="5"
    //                 placeholder="Amount"
    //               />
    //             </Form.Item>
    //           </div>

    //           <div className="col-sm-4">
    //             <input
    //               disabled
    //               className="form-control shadow-none border-2-green bg-transparent"
    //               type="text"
    //               value={show_amount}
    //               placeholder="Total Amount (USD)"
    //             />
    //           </div>
    //         </div>
    //       ),
    //     },
    //   ],
    // },

    // /**
    //  * ==================================================================================================
    //  * AIRTIME
    //  * ==================================================================================================
    //  */
    // {
    //   image: "./assets/kiosks/icons/airtime.svg",
    //   modalTitle: "Airtime",
    //   modalButton: submitButton,
    //   hasExtraField: true,
    //   fields: [],
    //   extraFields: [
    //     {
    //       name: "Airtime",
    //       data: [
    //         {
    //           item: <div className="col-12">{country_component}</div>,
    //         },
    //         {
    //           item: (
    //             <div className="col-12">
    //               <Form.Item
    //                 name="transactionType"
    //                 rules={[
    //                   {
    //                     required: true,
    //                     message: "Please select provider!",
    //                   },
    //                 ]}
    //               >
    //                 <select
    //                   onChange={onchangeTransactionType}
    //                   name="transactionType"
    //                   className="form-select fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //                 >
    //                   <option selected value="">
    //                     Select Service Provider
    //                   </option>
    //                   {!!counrtyAirtimeServices &&
    //                     counrtyAirtimeServices.map((items) => {
    //                       return (
    //                         <option key={items.code} value={items.code}>
    //                           {items.name}
    //                         </option>
    //                       );
    //                     })}
    //                 </select>
    //               </Form.Item>
    //             </div>
    //           ),
    //         },
    //         {
    //           item: (
    //             <div className="col-12">
    //               <PhoneNumber />
    //             </div>
    //           ),
    //         },
    //         {
    //           item: (
    //             <div className="row mb-4 mb-sm-0">
    //               <div className="col-sm-4 mb-4 mb-sm-0">{rates_component}</div>

    //               <div className="col-sm-4">{amount_component}</div>

    //               <div className="col-sm-4">{zwl_amount_component}</div>
    //             </div>
    //           ),
    //         },
    //         {
    //           item: <div className="col-12">{email_address_component}</div>,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Data",
    //       data: [
    //         {
    //           item: <div className="col-12">{country_component}</div>,
    //         },
    //         {
    //           item: (
    //             <div className="col-12">
    //               <Form.Item
    //                 name="transactionType"
    //                 rules={[
    //                   {
    //                     required: true,
    //                     message: "Please select provider!",
    //                   },
    //                 ]}
    //               >
    //                 <select
    //                   onChange={onServiceProviderChange}
    //                   name="transactionType"
    //                   className="form-select fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //                 >
    //                   <option selected value="">
    //                     Select Service Provider
    //                   </option>
    //                   {!!counrtyAirtimeServices &&
    //                     counrtyAirtimeServices.map((items) => {
    //                       const serviceCode = `${items.code}0`;
    //                       return (
    //                         <option key={serviceCode} value={serviceCode}>
    //                           {items.name.replace("Airtime", "")}
    //                         </option>
    //                       );
    //                     })}
    //                 </select>
    //               </Form.Item>
    //             </div>
    //           ),
    //         },
    //         {
    //           item: (
    //             <div className="col-12">
    //               <Form.Item
    //                 name="billerProduct"
    //                 rules={[
    //                   {
    //                     required: true,
    //                     message: "Please select data package!",
    //                   },
    //                 ]}
    //               >
    //                 <select
    //                   onChange={(e) => getDataChange(e)}
    //                   name="billerProduct"
    //                   className="form-select fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //                 >
    //                   <option selected value="">
    //                     Select Data Package
    //                   </option>{" "}
    //                   {keys.map((keyItem) => {
    //                     return (
    //                       <optgroup
    //                         key={keyItem}
    //                         label={keyItem.replace("_", " ")}
    //                       >
    //                         {dataArray[keyItem].map((items) => {
    //                           return (
    //                             <option key={items.code} value={items.code}>
    //                               {items.name.split("$")[0]} - $RTGS
    //                               {items.price}
    //                             </option>
    //                           );
    //                         })}
    //                       </optgroup>
    //                     );
    //                   })}
    //                 </select>
    //               </Form.Item>
    //             </div>
    //           ),
    //         },
    //         {
    //           item: (
    //             <div className="col-12">
    //               <PhoneNumber />
    //             </div>
    //           ),
    //         },
    //         {
    //           item: (
    //             <div className="row mb-4 mb-sm-0">
    //               <div className="col-sm-4">
    //                 {" "}
    //                 <Form.Item
    //                   name="currency"
    //                   rules={[
    //                     {
    //                       required: true,
    //                       message: "Please select currency!",
    //                     },
    //                   ]}
    //                 >
    //                   <select
    //                     name="currency"
    //                     onChange={(e) => onUsdCurrencyHandlerChange(e)}
    //                     className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //                   >
    //                     {ratesOptions}
    //                   </select>
    //                 </Form.Item>
    //               </div>

    //               <div className="col-sm-4 mb-4 mb-sm-0">
    //                 {" "}
    //                 {/* <Input
    //                 defaultValue={dstvAmount}
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //               /> */}
    //                 <input
    //                   disabled
    //                   className="form-control shadow-none border-2-green bg-transparent"
    //                   type="text"
    //                   name="amount"
    //                   value={dstvAmount}
    //                   // min="5"
    //                   placeholder="Amount"
    //                 />
    //               </div>

    //               <div className="col-sm-4">{zwl_amount_component}</div>
    //             </div>
    //           ),
    //         },
    //         {
    //           item: <div className="col-12">{email_address_component}</div>,
    //         },
    //       ],
    //     },
    //   ],
    // },
    /**==================================================================================================
     * END OF AIRTIME
     * ==================================================================================================
     * ELECTRICITY
     * ==================================================================================================
     */
    // {
    //   image: "./assets/kiosks/icons/zesa.svg",
    //   modalTitle: "Electricity",
    //   modalButton: submitButton,
    //   fields: [
    //     {
    //       item: <div className="col-12">{country_component}</div>,
    //     },
    //     {
    //       item: (
    //         <div className="col-12">
    //           <Form.Item
    //             name="accountNumber"
    //             rules={[
    //               {
    //                 required: true,
    //                 message: "Please input your meter number!",
    //               },
    //             ]}
    //           >
    //             <input
    //               className="form-control shadow-none border-2-green bg-transparent"
    //               type="number"
    //               placeholder="Meter Number"
    //             />
    //           </Form.Item>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="col-12">
    //           <PhoneNumber />
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row mb-4 mb-sm-0">
    //           <div className="col-sm-4 mb-4 mb-sm-0">{rates_component}</div>

    //           <div className="col-sm-4">{amount_component}</div>

    //           <div className="col-sm-4">{zwl_amount_component}</div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: <div className="col-12">{email_address_component}</div>,
    //     },
    //   ],
    // },
    /**==================================================================================================
     * END OF ELECTRICITY
     * ==================================================================================================
     * SCHOOL FEES
     * ==================================================================================================
     */
    // {
    //   image: "./assets/kiosks/icons/fees.svg",
    //   modalTitle: "School Fees",
    //   modalButton: submitButton,
    //   fields: [
    //     {
    //       item: <div className="col-12">{country_component}</div>,
    //     },
    //     {
    //       item: (
    //         <div className="col-12">
    //           <Form.Item name="billerId">
    //             <Select
    //               className="border-2-green bg-transparent text-green-2"
    //               defaultValue=""
    //               showSearch
    //               filterOption={false}
    //               onSearch={handleSearch}
    //               onChange={onSchoolChange}
    //             >
    //               <Option value="" disabled selected>
    //                 Select School
    //               </Option>
    //               {!!countrySchoolsArray &&
    //                 countrySchoolsArray.map((items) => {
    //                   return (
    //                     <Option key={items.code} value={items.code}>
    //                       {items.name.toUpperCase()}
    //                     </Option>
    //                   );
    //                 })}
    //             </Select>
    //           </Form.Item>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-12">
    //             <Form.Item
    //               name="student_name"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please enter student full name!",
    //                 },
    //               ]}
    //             >
    //               <input
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="text"
    //                 placeholder="Student Full Name"
    //               />
    //             </Form.Item>
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-sm-6">
    //             <Form.Item
    //               name="accountNumber"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please enter student number!",
    //                 },
    //               ]}
    //             >
    //               <input
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="text"
    //                 placeholder="Student Number"
    //               />
    //             </Form.Item>
    //           </div>
    //           <div className="col-sm-6">
    //             <Form.Item name="academicSemester">
    //               <input
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="number"
    //                 placeholder="Academic Semester"
    //               />
    //             </Form.Item>
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-sm-6">
    //             <Form.Item
    //               name="purpose"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please select purpose!",
    //                 },
    //               ]}
    //             >
    //               <select className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2">
    //                 <option value="">Select purpose</option>
    //                 {!!purposeServicesArray &&
    //                   purposeServicesArray.map((items) => {
    //                     return (
    //                       <option
    //                         key={items.short_code}
    //                         value={items.short_code}
    //                       >
    //                         {items.description.toUpperCase()}
    //                       </option>
    //                     );
    //                   })}
    //               </select>
    //             </Form.Item>
    //           </div>

    //           <div className="col-sm-6">
    //             <PhoneNumber />
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-12">{email_address_component}</div>
    //         </div>
    //       ),
    //     },

    //     {
    //       item: (
    //         <div className="row mb-4 mb-sm-0">
    //           <div className="col-sm-4 mb-4 mb-sm-0">{rates_component}</div>

    //           <div className="col-sm-4">{amount_component}</div>

    //           <div className="col-sm-4">{zwl_amount_component}</div>
    //         </div>
    //       ),
    //     },
    //   ],
    // },
    /**==================================================================================================
     * END OF SCHOOL FEES
     * ==================================================================================================
     * DSTV
     * ==================================================================================================
     */
    // {
    //   image: "./assets/kiosks/icons/dstv.svg",
    //   modalTitle: "DSTV",
    //   modalButton: submitButton,
    //   fields: [
    //     {
    //       item: <div className="col-12">{country_component}</div>,
    //     },
    //     {
    //       item: (
    //         <div className="row ">
    //           <div className="col-12">
    //             <Form.Item
    //               name="accountNumber"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please enter smart card number!",
    //                 },
    //               ]}
    //             >
    //               <input
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //                 type="text"
    //                 placeholder="SmartCard Number"
    //               />
    //             </Form.Item>
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-12">
    //             <PhoneNumber />
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-sm-6">
    //             <Form.Item
    //               name="bouquet"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please select Bouquet!",
    //                 },
    //               ]}
    //             >
    //               <select
    //                 onChange={(e) => getBouquet(e)}
    //                 className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //               >
    //                 <option selected disabled value="">
    //                   Select Bouquet
    //                 </option>
    //                 {!!countryDstvBoquetServices &&
    //                   countryDstvBoquetServices.map((items) => {
    //                     return (
    //                       <option key={items.productId} value={items.productId}>
    //                         {items.productName} $USD{items.cost}
    //                       </option>
    //                     );
    //                   })}
    //               </select>
    //             </Form.Item>
    //           </div>

    //           <div className="col-sm-6">
    //             <Form.Item
    //               name="addons"
    //               // rules={[
    //               //   {
    //               //     required: true,
    //               //     message: "Please select Addons!",
    //               //   },
    //               // ]}
    //             >
    //               <Select
    //                 mode="multiple"
    //                 className="border-2-green bg-transparent text-green-2"
    //                 filterOption={false}
    //                 onChange={(e) => getAddons(e)}
    //                 placeholder="Select Addons"
    //               >
    //                 {!!countryDstvAddonsServices &&
    //                   countryDstvAddonsServices.map((items) => {
    //                     return (
    //                       <Option key={items.ADDONID} value={items.ADDONID}>
    //                         {items.ADDONNAME} $USD{items.COST}
    //                       </Option>
    //                     );
    //                   })}
    //               </Select>
    //               {/* <select
    //                 onChange={(e) => onChangeHandler(e)}
    //                 className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //               >
    //                 <option selected disabled value="">
    //                   Select Addons
    //                 </option>
    //                 {!!dstvServices.ADDONS &&
    //                   dstvServices.ADDONS.map((items) => {
    //                     return (
    //                       <option key={items.ADDONID} value={items.ADDONID}>
    //                         {items.ADDONNAME}
    //                       </option>
    //                     );
    //                   })}
    //               </select> */}
    //             </Form.Item>
    //           </div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row mb-4 mb-sm-0">
    //           <div className="col-sm-4">
    //             {" "}
    //             <Form.Item
    //               name="currency"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Please select currency!",
    //                 },
    //               ]}
    //             >
    //               <select
    //                 name="currency"
    //                 onChange={(e) => onUsdCurrencyHandlerChange(e)}
    //                 className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
    //               >
    //                 {usdOptions}
    //               </select>
    //             </Form.Item>
    //           </div>

    //           <div className="col-sm-4 mb-4 mb-sm-0">
    //             {" "}
    //             {/* <Input
    //                 defaultValue={dstvAmount}
    //                 className="form-control shadow-none border-2-green bg-transparent"
    //               /> */}
    //             <input
    //               disabled
    //               className="form-control shadow-none border-2-green bg-transparent"
    //               type="number"
    //               name="amount"
    //               value={dstvAmount}
    //               // min="5"
    //               placeholder="Amount"
    //             />
    //           </div>

    //           <div className="col-sm-4">{zwl_amount_component}</div>
    //         </div>
    //       ),
    //     },
    //     {
    //       item: (
    //         <div className="row">
    //           <div className="col-12">{email_address_component}</div>
    //         </div>
    //       ),
    //     },
    //   ],
    // },

    // /**==================================================================================================
    //  * END DSTV
    //  * ==================================================================================================
    //  * BILL PAYMENTS
    //  * ==================================================================================================
    //  */

    // {
    //   image: "./assets/kiosks/icons/bills.svg",
    //   modalTitle: "Bill Payment",
    //   modalButton: submitButton,
    //   hasExtraField: true,
    //   fields: [],
    //   extraFields: billersList,
    // },

    /**==================================================================================================
     * END OF BILL PAYMENTS
     * ==================================================================================================
     * SHOP
     * ==================================================================================================
     */
    // {
    //   image: "./assets/img/icons/send-value-icon.svg",
    //   modalTitle: "Coming Soon",
    //   modalButton: "",
    //   fields: [
    //     {
    //       item: "",
    //     },
    //   ],
    // },
    /**==================================================================================================
     * END OF SHOP
     * ==================================================================================================
     *                                          ***  Nigel Reign ***
     * ==================================================================================================
     */
  ];

  return (
    <>
      <section className="kiosks-bg-4" id={id}>
        <div className="kiosk-container py-5">
          <div className="row">
            <div className="col-lg-7 kiosk-end ">
              <div style={{ height: 80 }} className="pb-5">
                <h1 className="text-white fw-800 kiosk-bills-text text-center">
                  <span className="kiosk-bills-gradient-text">
                    <Typical
                      className="d-inline text-orange-3"
                      steps={["Send USD Cash"]}
                      // loop={Infinity}
                    />
                  </span>
                  <em className="fw-600">via</em> Tumai
                </h1>
              </div>
              {/* <StyleRoot> */}
              <div className="d-flex mt-5 flex-wrap justify-content-center gap-4" >
                {/* <CreateOrder /> */}
                {serviceitems?.map((items) => {
                  return (
                    <ServiceItems
                      key={items.modalTitle}
                      image={items.image}
                      fields={items.fields}
                      extraFields={items.extraFields}
                      hasExtraField={items.hasExtraField}
                      modalTitle={items.modalTitle}
                      modalButton={items.modalButton}
                      postTransaction={postTransaction}
                      setPaymentPayload={setPaymentPayload}
                      dstvAmount={dstvAmount}
                      paymentDetails={paymentDetails}
                      converted_amount={converted_amount}
                      ratesOptions={ratesOptions}
                      setConvertedAmount={clearRatesCalculator}
                      onUsdCurrencyHandlerChange={onUsdCurrencyHandlerChange}
                      onAmountChange={onAmountChange}
                      setDstvAmount={setDstvAmount}
                    />
                  );
                })}
                {/* 
                                <Popover content='Coming soon' title=''>
                                    <a href={shopUrl}>
                                        <img
                                            src='./assets/kiosks/icons/value.svg'
                                            alt=''
                                            width={150}
                                            className='kiosk-bills-icon'
                                        />
                                    </a>
                                </Popover> */}
              </div>
              {/* </StyleRoot> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Services.defaultProps = {
  services: [],
  rates: [],
};

Services.propTypes = {
  services: PropTypes.array,
  rates: PropTypes.array,
  getRates: PropTypes.func,
  postTransaction: PropTypes.func,
  setPaymentPayload: PropTypes.func,
};

export default Services;