import Logo from "components/logo/svg";
import React, { useState } from "react";
import { connect } from "react-redux";
import ResetPasswordContainer from "components/login/resetpassword";

import { Form, Button, Modal, notification, Spin } from "antd";
import SuccessMsgIcon from "assets/img/icons/success-message-icon.svg";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import getConfig from "config";

function ResetPassword(props) {
  const [state, setState] = useState({
    activeRow: {},
  });
  const config = getConfig();
  const activeRow = state;
  const [isButtonLoading, setButtonLoad] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const resetPasswordUrl = `${config.api.base_url}public/user/change-password/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  const { user_id } = useParams();

  const onFinish = (values) => {
    const payload = JSON.stringify({
      user_id: user_id,
      password: values.password,
      password2: values.password2,
    });
    setState({ ...state, activeRow });
    setButtonLoad(true);

    if (values.password === values.password2) {
      axios
        .post(resetPasswordUrl, payload, headers)
        .then((res) => {
          setButtonLoad(false);
          if (res.status === 200) {
            setIsModalVisible(true);
          } else {
            notification.error({
              message: "Error",
              description: "Failed to reset password",
              className: "custom-className",
              style: {
                top: 70,
              },
            });
          }
        })
        .catch((error) => {
          setButtonLoad(false);
          setTimeout(() => {}, 1000);
          let errors = [];
          if (error.response.data.info.message["password"]) {
            errors.push(error.response.data.info.message.password);
          }
          if (error.response.data.info.message["user_id"]) {
            errors.push("user does not exist");
          }
          notification.error({
            message: "Error",
            description: errors,
            className: "custom-className",
            style: {
              top: 70,
            },
          });
        });
    } else {
      setButtonLoad(false);
      notification.error({
        message: "Error",
        description: "Your passwords do not match",
        className: "custom-className",
        style: {
          top: 70,
        },
      });
    }
  };

  const handleCancel = () => {
    window.location.href = "/login";
  };

  const history = useHistory();

  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  if (getUserDetails()) {
    history.push({
      pathname: "/",
    });
    // window.location = "/";
  }

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div className="card-body lp-buttons-bg p-0 text-white">
            <div className="text-center py-5">
              <div className="mb-4">
                <img src={SuccessMsgIcon} alt="" width={100} />
              </div>
              <div className="mb-5">
                <h3 className="text-white fw-800">
                  Your password has been changed succesfully
                </h3>
              </div>
              <Button
                onClick={handleCancel}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div
        className="register-photo"
        style={{
          marginTop: -70,
        }}
      >
        <div className="form-container">
          <div className="image-holder d-none d-md-table-cell"></div>

          <div className="login-container">
            <br />
            <br />
            <br />
            <br />

            <div className="text-center">
              <Logo cssclassName="mb-4" imagewidth="100" />
            </div>

            <ul
              className="nav nav-tabs mb-3 justify-content-between border-0"
              id="ex1"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active text-green-2 fs-25px fw-800 text-uppercase letter-spacing-3"
                  id="ex1-tab-1"
                  data-bs-toggle="tab"
                  href="#ex1-tabs-1"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected="true"
                >
                  Reset Password
                </a>
              </li>
            </ul>
            <div className="tab-content" id="ex1-content">
              <div
                className="tab-pane fade show active"
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <Spin spinning={isButtonLoading} size="large">
                  <Form
                    style={{ padding: "0px 0px 0px 0px" }}
                    onFinish={onFinish}
                  >
                    <ResetPasswordContainer />
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
          {/* </Form> */}
        </div>
      </div>
    </>
  );
}

ResetPassword.defaultProps = {
  errorMessage: {},
};

ResetPassword.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
