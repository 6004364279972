import React from "react";
import { connect } from "react-redux";
import Footer from "components/footer/main";
import Navigation from "components/main/navigation";
import HeaderTitles from "components/main/headerTitles/HeaderTitles";
import { Helmet } from "react-helmet";

function Offerings(props) {
  const header_titles = (
    <>
      {" "}
      <HeaderTitles />
    </>
  );
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <title>What we offer | Tumai</title>
        <meta
          name="description"
          content="Bill Payments and School Fees payments. Tumai is the best way to pay for your school fees and bills online. We offer a wide range of payment options including debit and credit cards and mobile money."
        />

        <meta
          name="keywords"
          content=", Tumai, remitance, diaspora, payments, bill payments, zimbabwe airtime, econet data, netone data, ZESA token, DSTV payments, Zimbabwe bill payments, N Richards voucher"
        />

        <link rel="canonical" href="https://tumai.to/" />

        <meta property="og:url" content="https://tumai.to/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Tumai" />
        <meta
          property="og:description"
          content="Bill Payments and School Fees payments. Tumai is the best way to pay for your school fees and bills online. We offer a wide range of payment options including debit and credit cards and mobile money."
        />
        <meta
          property="og:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="twitter:card"
          content="https://tumai.to/assets/img/logo.svg"
        />
        <meta property="twitter:domain" content="tumai.to" />
        <meta property="twitter:url" content="https://tumai.to/" />
        <meta name="twitter:title" content="Tumai" />
        <meta
          name="twitter:description"
          content="Bill Payments and School Fees payments. Tumai is the best way to pay for your school fees and bills online. We offer a wide range of payment options including debit and credit cards and mobile money."
        />
        <meta
          name="twitter:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="”robots”" content="index, follow" />
      </Helmet>
      <header>
        <Navigation header_titles={header_titles} />

        <div className="offerongs-bg">
          <div className="container">
            <div className="d-lg-flex align-items-center justify-content-between py-5 text-center text-md-end">
              <h1 className="text-green fw-800 mb-sm-4">
                What does Tumai <br />
                have to offer?
              </h1>

              <img
                src="./assets/img/logo.svg"
                alt=""
                className="img-fluid lp-header-logo"
                width="200"
              />
            </div>
          </div>
        </div>
      </header>

      <section id="payments">
        <div className="pb-4 pt-4 light-grey-bg ">
          <div className="container">
            <div className="d-lg-flex align-items-center">
              <div>
                <h2 className="text-green-2 fw-800 text-center text-lg-start">
                  Bill Payments
                </h2>

                <p className="lp-about-message-1 text-green-2 mt-4 fw-600 text-align-justify">
                  Paying your loved ones’ utility bills has never been
                  easier.Through the VISA and MasterCard platforms or our agent
                  partners’ POS device, customers will be able to select the
                  utility bill they wish to pay for and make instant
                  payments.The receiver will be issued with a token or
                  confirmation of the payment on their mobile phone allowing
                  them immediately enjoy a host of services offered in Africa.
                </p>
              </div>

              <div className="text-center text-lg-start">
                <img
                  src="./assets/img/offerings/bills.png"
                  alt=""
                  width="1000"
                  className="ms-lg-5 mt-5 mt-lg-0 img-fluid"
                />
              </div>
            </div>
            <hr className="bg-green-2 my-5" />

            <div className="d-lg-flex align-items-center">
              <div className="text-center text-lg-start">
                <img
                  src="./assets/img/offerings/school-fees.png"
                  alt=""
                  width="1000"
                  className="img-fluid"
                />
              </div>
              <div className="ms-lg-5 mt-5 mt-lg-0">
                <h2 className="text-green-2 fw-800 text-center text-lg-start">
                  School Fees payments (COMING SOON)
                </h2>

                <p className="lp-about-message-1 text-green-2 mt-4 fw-600 text-align-justify">
                  Instead of sending school fees home and your family going to
                  the bank or a merchant and queueing unnecessarily for cash,
                  Tumai presents our School Fees Payment system. A payment for
                  school fees can be made through the sending partner or via
                  VISA and MasterCard the web portal to a registered educational
                  institution.
                </p>

                <p className="lp-about-message-1 text-green-2 mt-4 fw-800">
                  The required information from the sender is: <br />
                  <span className="fw-600">The pupil’s Student ID.</span>
                </p>

                <p className="lp-about-message-1 text-green-2 mt-4 fw-600 text-align-justify">
                  The institution will receive the funds directly with the with
                  the student ID included in the reference allowing for the
                  students account to be credited immediately.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="how-it-works">
        <div className="wallet-cards-bg box-shadow-1">
          <div className="container py-lg-4 py-4 px-lg-5">
            <div className="d-md-flex align-items-center justify-content-between my-4">
              <div className="mw-cs-title-line w-25 d-none d-md-inline-block bg-green-2"></div>

              <div className="d-flex align-items-center justify-content-center">
                <h1 className="text-green fw-800">How it works</h1>

                <img
                  src="./assets/img/icons/question-green.svg"
                  alt=""
                  width="60"
                  className="ms-2"
                />
              </div>

              <div className="mw-cs-title-line w-25 d-none d-md-inline-block bg-green-2"></div>
            </div>
            <div className="d-lg-flex align-items-start justify-content-lg-between text-center text-lg-start">
              <img
                src="./assets/img/offerings/method-1.svg"
                alt=""
                className="img-fluid"
                width="450"
              />

              <img
                src="./assets/img/offerings/method-2.svg"
                alt=""
                className="img-fluid mt-5 mt-lg-0"
                width="450"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="access">
        <div className="lp-access-tumai pb-4 pt-4">
          <div className="container">
            <div className="d-lg-flex">
              <div className="text-center text-lg-start">
                <img
                  src="./assets/img/offerings/vouchers-pic.png"
                  alt=""
                  width="1000"
                  className="img-fluid"
                />
              </div>
              <div>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <h4 className="mw-cs-title-name text-white">Vouchers</h4>

                  <div className="mw-cs-title-line w-50 d-none d-md-inline-block bg-white"></div>
                </div>

                <p className="lp-about-message-1 text-white mt-4 fw-700 text-align-justify">
                  Instead of recipients queueing for endless hours for money to
                  purchase goods or services, our unique system provides a
                  seamless shopping experience in two ways:
                </p>

                <p className="lp-about-message-1 text-white mt-4 text-align-justify">
                  1. The sender can select and purchase goods or services
                  directly for their loved ones from a registered Tumai
                  merchant. After the goods have been purchased, the receiver
                  will be sent a unique voucher which they use to collect the
                  purchased goods at the merchant’s outlet or;
                </p>

                <p className="lp-about-message-1 text-white mt-4 text-align-justify">
                  2. The sender can purchase a voucher for a certain value which
                  will be sent to the receiver. The receiver will then be able
                  to purchase goods of their choosing for the value of the
                  voucher at a registered merchant’s outlet.
                </p>

                {/* <div className="py-4">
                  <a
                    href="/shop"
                    className="btn btn-lg blue-gradient-bg-1 rounded-pill py-3 px-4 border-0 text-uppercase text-white letter-spacing-3 fw-800 box-shadow-btn"
                  >
                    purchase voucher
                    <img
                      src="./assets/img/icons/white-chevron-right.svg"
                      alt=""
                      width="12"
                      className="ms-2"
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-tumai">
        <div className="light-grey-bg">
          <div className="container py-lg-4 py-4 px-lg-5">
            <div className="d-md-flex align-items-center justify-content-between">
              <div className="mw-cs-title-line w-25 d-none d-md-inline-block bg-green-2"></div>

              <div className="d-flex align-items-center justify-content-center">
                <h1 className="text-green fw-800">Why Tumai</h1>

                <img
                  src="./assets/img/icons/question-green.svg"
                  alt=""
                  width="60"
                  className="ms-2"
                />
              </div>

              <div className="mw-cs-title-line w-25 d-none d-md-inline-block bg-green-2"></div>
            </div>

            <div className="d-lg-flex align-items-center">
              <div>
                <p className="lp-about-message-1 text-green-2 mt-4 fw-600 text-align-justify">
                  Picture a typical month-end in the heart of Harare, winding
                  queues at remittance agencies, shops full to the brim with
                  customers, utility bill payments stalled because the network
                  has dropped. Chaos, congestion and inconvenience all around.
                  Frustrations and tensions continuously rise. This is why Tumai
                  offers a unique experience for your loved ones by ensuring
                  that they receive:
                </p>

                <div className="d-flex align-items-center">
                  <img src="./assets/img/icons/li-icon.svg" alt="" width="25" />
                  <h5 className="text-green-2 fw-800 d-inline ms-3">
                    Goods and services from registered merchants,
                  </h5>
                </div>

                <div className="d-flex align-items-center">
                  <img src="./assets/img/icons/li-icon.svg" alt="" width="25" />
                  <h5 className="text-green-2 fw-800 d-inline ms-3">
                    Payment of utility bills and
                  </h5>
                </div>

                <div className="d-flex align-items-center">
                  <img src="./assets/img/icons/li-icon.svg" alt="" width="25" />
                  <h5 className="text-green-2 fw-800 d-inline ms-3">
                    School fees payments.
                  </h5>
                </div>

                <p className="lp-about-message-1 text-green-2 mt-4 fw-600 text-align-justify">
                  It’s hard enough to be apart from your loved ones especially
                  in such trying times, Tumai aims to bridge the gap and give
                  you and them convenience at the touch of a button.
                </p>
              </div>

              <div className="text-center text-lg-start">
                <img
                  src="./assets/img/offerings/mobile-app.png"
                  alt=""
                  height="380"
                  className="ms-lg-5 mt-5 mt-lg-0"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

Offerings.defaultProps = {};

Offerings.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Offerings);
