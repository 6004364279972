import React from "react";

import FreshProduce from "./fresh-produce.jpg";
import Liquor from "./liquor.jpg";
import HouseHold from "./household.jpg";
import Meat from "./meat.jpg";
import Appliances from "./appliances.jpg";

export default function Courasel() {
  return (
    <div
      id="category-banners"
      className="carousel slide d-none d-md-block"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#category-banners"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#category-banners"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#category-banners"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#category-banners"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#category-banners"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={FreshProduce} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={Liquor} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={HouseHold} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={Meat} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={Appliances} className="d-block w-100" alt="..." />
        </div>
      </div>
    </div>
  );
}
