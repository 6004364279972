import React, { useState } from "react";
import { connect } from "react-redux";

import createWalletRoutine, { verifyOtpRoutine } from "./routines";
import { getUserDetailsRoutine } from "containers/authentication/routines";
import Navigation from "components/main/navigation";
import CreateWalletForm from "components/createwallet";
import OtpForm from "components/otp";

function CreateWallet(props) {
  const {
    createUserWallet,
    errorMessage,
    verifyOtp,
    isVerified,
  } = props;
  const [state, setState] = useState({ activeRow: {} });
  const [openOtp, setOpenOtp] = useState(false);
  const [isWalletcreated, setIsWalletcreated] = useState(false);
  const { activeRow } = state;

  const openVerify = (phone_number) => {
    setIsWalletcreated(true);
    setOpenOtp(true);
    activeRow.phone_number = phone_number;

    setState({ ...state, activeRow });
  };

  const openOtpPage = () => {
    setIsWalletcreated(true);
    setOpenOtp(true);
    activeRow.phone_number = "";

    setState({ ...state, activeRow });
  };


  let form;

  if (isVerified) {
    window.location = "/wallet";
  }

  if (isWalletcreated) {
    form = (
      <OtpForm
        verifyOtp={verifyOtp}
        phone_number={activeRow.phone_number}
        errorMessage={errorMessage}
      />
    );
  } else {
    form = (
      <CreateWalletForm
        openVerify={openVerify}
        openOtp={openOtpPage}
        createUserWallet={createUserWallet}
        errorMessage={errorMessage}
      />
    );
  }
//open otp form
  if (openOtp) {
    form = (
      <OtpForm
        verifyOtp={verifyOtp}
        phone_number={activeRow.phone_number}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <>
      <header>
        <Navigation />

        {form}
      </header>
    </>
  );
}

CreateWallet.defaultProps = {
  services: [],
  rates: {},
};

CreateWallet.propTypes = {
  errorMessage: null,
};

function mapStateToProps(state) {
  return {
    isWalletcreated: state.getIn(["createWallet", "isWalletcreated"]),
    errorMessage: state.getIn(["createWallet", "errorMessage"]),
    isVerified: state.getIn(["createWallet", "isVerified"]),
    reloadRequested: state.getIn(["main", "reloadRequested"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createUserWallet: (values, id_number) =>
      dispatch(createWalletRoutine.trigger({ values, id_number })),
    getUserDetails: () => dispatch(getUserDetailsRoutine.trigger({})),
    verifyOtp: (otp, phone_number) =>
      dispatch(verifyOtpRoutine.trigger({ otp, phone_number })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWallet);
