import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
import getConfig from "config";
import getProductsRoutine, {
  addToCartRoutine,
  getCategoriesRoutine,
  checkoutRoutine,
  cartCounterRoutine,
  removeFromCartRoutine,
  updateCartRoutine,
} from "./routines";

const axios = require("axios");

function* fetchProducts(action) {
  const { merchant_id, category_id } = action.payload;
  const config = getConfig();

  let storeUrl;
  if (category_id === "all") {
    storeUrl = `${config.api.base_url}store/merchants/products/${merchant_id}/`;
  } else {
    storeUrl = `${config.api.base_url}store/productsbycategory/${merchant_id}/${category_id}/`;
  }

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };
  try {
    // trigger request action
    yield put(getProductsRoutine.request());

    const response = yield call(axios.get, storeUrl, headers);
    yield put(getProductsRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(getProductsRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(getProductsRoutine.fulfill());
  }
}

function* productsSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getProductsRoutine.TRIGGER, fetchProducts);
}

function* fetchCategories(action) {
  const { merchant_id } = action.payload;
  const config = getConfig();

  const storeUrl = `${config.api.base_url}store/merchants/categories/${merchant_id}/`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };
  try {
    // trigger request action
    yield put(getCategoriesRoutine.request());

    const response = yield call(axios.get, storeUrl, headers);
    yield put(getCategoriesRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(getCategoriesRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(getCategoriesRoutine.fulfill());
  }
}

export function* categoriesSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getCategoriesRoutine.TRIGGER, fetchCategories);
}

function* addToCart(action) {
  const { productID, cart, quantity, price, picture, productName } =
    action.payload;

  const getCart = () => {
    const cartInfo = localStorage.getItem("cartInfo");

    if (cartInfo !== null) {
      const infocart = JSON.parse(cartInfo).cart;
      return infocart;
    }
    return [];
  };

  let cartItem;

  if (getCart()) {
    cartItem = cart;
    var search = cart.filter((obj) => obj.product_id === productID);
    if (search.length > 0) {
      search[0].quantity = search[0].quantity + quantity;
      search[0].price = search[0].quantity * price;
    } else {
      cartItem.push({
        product_id: productID,
        quantity: quantity,
        picture: picture,
        productName: productName,
        actual_price: price,
        price: quantity * price,
      });
    }
  } else {
    cartItem = [];
    cartItem.push({
      product_id: productID,
      quantity: quantity,
      picture: picture,
      productName: productName,
      actual_price: price,
      price: quantity * price,
    });
  }

  // if (cart) {
  //   cartItem = cart;
  //   var search = cart.filter((obj) => obj.product_id === productID);
  //   if (search.length > 0) {
  //     search[0].quantity = search[0].quantity + quantity;
  //     search[0].price = search[0].quantity * price;
  //   } else {
  //     cartItem.push({
  //       product_id: productID,
  //       quantity: quantity,
  //       picture: picture,
  //       productName: productName,
  //       actual_price: price,
  //       price: quantity * price,
  //     });
  //   }
  // } else {
  //   cartItem = [];
  //   cartItem.push({
  //     product_id: productID,
  //     quantity: quantity,
  //     picture: picture,
  //     productName: productName,
  //     actual_price: price,
  //     price: quantity * price,
  //   });
  // }

  localStorage.setItem(
    "cartInfo",
    JSON.stringify({
      cart: cartItem,
    })
  );
  try {
    // trigger request action
    yield put(addToCartRoutine.request());

    yield put(addToCartRoutine.success(getCart()));
  } catch (error) {
    // if request failed
    yield put(addToCartRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(addToCartRoutine.fulfill());
  }
}

export function* addCartSaga() {
  yield takeLatest([addToCartRoutine.TRIGGER], addToCart);
}

function* removeFromCart(action) {
  const { productID, cart, quantity, price } = action.payload;

  if (cart) {
    var search = cart.filter((obj) => obj.product_id === productID);
    if (search.length > 0) {
      search[0].quantity = search[0].quantity - quantity;
      search[0].price = search[0].price - price;
    }

    for (var i = 0; i < cart.length; i++) {
      if (cart[i].quantity === 0) {
        cart.splice(i, 1);
      }
    }
  }

  localStorage.setItem(
    "cartInfo",
    JSON.stringify({
      cart: cart,
    })
  );

  try {
    // trigger request action
    yield put(removeFromCartRoutine.request());

    yield put(removeFromCartRoutine.success(cart));
  } catch (error) {
    // if request failed
    yield put(removeFromCartRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(removeFromCartRoutine.fulfill());
  }
}

export function* removeFromCartSaga() {
  yield takeLatest([removeFromCartRoutine.TRIGGER], removeFromCart);
}

function* checkout(action) {
  const { cart } = action.payload;

  const config = getConfig();

  const checkoutUrl = `${config.api.base_url}transaction/post/`;
  try {
    // trigger request action
    yield put(checkoutRoutine.request());

    const response = yield call(axios.post, checkoutUrl, cart);
    yield put(checkoutRoutine.success(response.data));
  } catch (error) {
    // if request failed
    yield put(checkoutRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(checkoutRoutine.fulfill());
  }
}

export function* checkoutSaga() {
  yield takeLatest([checkoutRoutine.TRIGGER], checkout);
}

function* cartCounter(action) {
  const { counter } = action.payload;

  localStorage.setItem(
    "counterInfo",
    JSON.stringify({
      counter: counter,
    })
  );

  try {
    // trigger request action
    yield put(cartCounterRoutine.request());

    yield put(cartCounterRoutine.success(counter));
  } catch (error) {
    // if request failed
    yield put(cartCounterRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(cartCounterRoutine.fulfill());
  }
}

export function* cartCounterSaga() {
  yield takeLatest([cartCounterRoutine.TRIGGER], cartCounter);
}

function* updateCart(action) {
  const getCart = () => {
    const cartInfo = localStorage.getItem("cartInfo");

    if (cartInfo !== null) {
      const infocart = JSON.parse(cartInfo).cart;
      return infocart;
    }
    return [];
  };

  try {
    // trigger request action
    yield put(updateCartRoutine.request());

    yield put(updateCartRoutine.success(getCart()));
  } catch (error) {
    // if request failed
    yield put(updateCartRoutine.failure(error.message));
  } finally {
    // trigger fulfill action
    yield put(updateCartRoutine.fulfill());
  }
}

export function* updateCartSaga() {
  yield takeLatest([updateCartRoutine.TRIGGER], updateCart);
}

export default productsSaga;
