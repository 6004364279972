import React, { useState } from "react";

import PropTypes from "prop-types";
import { Modal, Spin, Form, notification } from "antd";
import axios from "axios";
import getConfig from "config";
import { pulse, shake, zoomInDown } from "react-animations";
import Radium, { StyleRoot } from "radium";
import SuccessMessage from "components/messages/successMsg";

const PaymentMethod = (props) => {
  const { paymentPayload, payment_types } = props;
  const config = getConfig();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIisSuccessModalVisible] = useState(false);
  const [isPinSubmitted, setIsPinSubmitted] = useState(false);
  const [state, setState] = useState({
    activeRow: {},
  });
  const [isWalletModalVisible, setIsWalletModalVisible] = useState(false);
  const [isPaynowModalVisible, setIsPaynowModalVisible] = useState(false);
  const [isIframeLoading, setIframeLoad] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [isResetPinModalVisible, setIsResetPinModalVisible] = useState(false);
  const [isPinResetLoading, setIsPinResetLoading] = useState(false);
  const [currentMethod, setMethod] = useState("");

  const styles = {
    pulse: {
      animation: "x 1s",
      animationName: Radium.keyframes(pulse, "pulse"),
    },
    shake: {
      animation: "x 1s",
      animationName: Radium.keyframes(shake, "shake"),
    },
    zoomInDown: {
      animation: "x 0.5s",
      animationName: Radium.keyframes(zoomInDown, "zoomInDown"),
    },
  };
  const { activeRow } = state;

  const serviceUrl = `${config.api.base_url}transaction/post/`;

  const onFinish = (values) => {
    setIsPinSubmitted(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.api.token}`,
      },
    };

    const payload = JSON.stringify({
      payment_id: activeRow.payment_id,
      reference: activeRow.reference,
      amount: paymentPayload.amount,
      pin: values.pin,
    });

    axios
      .post(activeRow.paymentUrl, payload, headers)
      .then((res) => {
        if (res.status === 200) {
          setIisSuccessModalVisible(true);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setIsPinSubmitted(false);
        }, 1000);
        if (error.response.status === 400) {
          if (error.response.data.info.errors.message === "Blocked account") {
            setIsResetPinModalVisible(true);
            setIsPinSubmitted(false);
          } else {
            notification.error({
              message: "Error",
              description: error.response.data.info.errors.message,
              className: "custom-class",
              style: {
                top: 70,
              },
            });
          }
        } else {
          notification.error({
            message: "Error",
            description: "Failed to make payment",
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        }
      });
  };

  let field = "payment_type";
  payment_types.sort((a, b) =>
    (b[field] || "").toString().localeCompare((a[field] || "").toString())
  );

  let renderPaymentTypes;
  if (payment_types.length > 0) {
    let filteredPaymentTypes = payment_types.filter(
      (el) => el.payment_type !== "Paynow"
    );

    // payment_types.remove("")
    renderPaymentTypes = (
      <>
        {!!filteredPaymentTypes &&
          filteredPaymentTypes.map((items) => {
            return (
              <div
                key={items.name}
                className="col-6"
                style={{ marginTop: "20px" }}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={() =>
                    submitPayment(
                      items.payment_type,
                      items.min_amount,
                      items.max_amount
                    )
                  }
                >
                  <div className="light-grey-bg p-4 d-flex align-items-center justify-content-center h-100">
                    <img src={items.image} alt="" width="80" />

                    <img
                      src="./assets/img/icons/double-chevron-right-green.svg"
                      alt=""
                      width="20"
                      className="ms-3"
                    />
                  </div>
                </a>
              </div>
            );
          })}
      </>
    );
  } else {
    renderPaymentTypes = <Spin size="large" />;
  }

  const handleCancel = () => {
    // setIsModalVisible(false);
    // setIsWalletModalVisible(false);
    window.location.href = "/payments";
  };

  const isLoggedIn = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails !== null) {
      const isLoggedIn = JSON.parse(userDetails).isLoggedIn;
      return isLoggedIn;
    }
    return false;
  };

  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  const resendOTP = () => {
    setIsPinResetLoading(true);
    const sendOTPUrl = `${config.api.base_url}wallet/reset/pin/otp/`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.api.token}`,
      },
      mode: "cors",
    };

    axios
      .get(sendOTPUrl, headers)
      .then((res) => {
        if (res.status === 200) {
          setIsPinResetLoading(false);
          notification.success({
            message: "Success",
            description: "An OTP was sent to your phone",
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        }
      })
      .catch((error) => {
        setIsPinResetLoading(false);
        notification.error({
          message: "Error",
          description: "Failed to send OTP",
          className: "custom-class",
          style: {
            top: 70,
          },
        });
      });
  };

  const onPaynowFinish = (values) => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Client: "Web",
        // Authorization: `Bearer ${config.api.token}`,
      },
      mode: "cors",
    };

    paymentPayload.paymentType = "Paynow";
    paymentPayload.extras.phoneNumber = paymentPayload.phone_number;
    paymentPayload.extras.paying_phone_number = values.paying_phone_number;
    paymentPayload.extras.payment_method = currentMethod;

    setIsPinSubmitted(true);
    axios
      .post(serviceUrl, paymentPayload, headers)
      .then((res) => {
        if (res.status === 200) {
          setIsPinSubmitted(false);
          localStorage.setItem(
            "paymentInfo",
            JSON.stringify({
              reference: res.data.data.payment_data.reference,
            })
          );
          if (res.data.data.status) {
            window.location.href = "/transaction/return";
          } else {
            window.location.href = "/transaction/initiated";
          }
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Failed to process payment",
          className: "custom-class",
          style: {
            top: 70,
          },
        });
        setIsPinSubmitted(false);
      });
  };

  const submitPayment = (method, min_amount, max_amount) => {
    paymentPayload.paymentType = method;
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Client: "Web",
        // Authorization: `Bearer ${config.api.token}`,
      },
      mode: "cors",
    };

    if (paymentPayload.amount < max_amount) {
      if (paymentPayload.amount >= 1) {
        if (method === "Wallet") {
          if (isLoggedIn()) {
            if (getUserDetails().has_wallet) {
              setIsWalletModalVisible(true);
            } else {
              window.location = "/create-wallet";
            }
          } else {
            window.location = "/login";
          }
        }
        if (
          method.toLowerCase() === "ecocash" ||
          method.toLowerCase() === "onemoney" ||
          method.toLowerCase() === "telecash"
        ) {
          setMethod(method);
          setIsPaynowModalVisible(true);
        } else {
          setIsModalVisible(true);
          axios
            .post(serviceUrl, paymentPayload, headers)
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem(
                  "paymentInfo",
                  JSON.stringify({
                    reference: res.data.data.payment_data.reference,
                  })
                );
                if (method === "Wallet") {
                  activeRow.payment_id = res.data.data.payment_data.payment_id;
                  activeRow.reference = res.data.data.payment_data.reference;
                  activeRow.paymentUrl = res.data.data.paymentUrl;
                  setState({ ...state, activeRow });
                  setIframeLoad(false);
                } else if (method === "Paypal" || method === "Vpayments") {
                  window.location = res.data.data.paymentUrl;
                } else {
                  setIframeLoad(false);
                  setPaymentUrl(res.data.data.paymentUrl);
                }
              }
            })
            .catch((error) => {
              notification.error({
                message: "Error",
                description: "Failed to load payment method",
                className: "custom-class",
                style: {
                  top: 70,
                },
              });
              setIframeLoad(false);
              setIsModalVisible(false);
              setIsWalletModalVisible(false);
            });
        }
      } else {
        notification.error({
          message: "Error",
          description: `This payment method has a transaction limit of $${paymentPayload.currency}${min_amount}`,
          className: "custom-class",
          style: {
            top: 70,
          },
        });
      }
    } else {
      notification.error({
        message: "Error",
        description: `This payment method has a maximum limit of $${paymentPayload.currency}${max_amount}`,
        className: "custom-class",
        style: {
          top: 70,
        },
      });
    }
  };

  const handleResetPinCancel = () => {
    setIsResetPinModalVisible(false);
  };

  const resetPin = (values) => {
    setIsPinResetLoading(true);

    if (values.pin === values.pin2) {
      const resetPinUrl = `${config.api.base_url}wallet/reset/pin/`;

      const resetHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.api.token}`,
        },
        mode: "cors",
      };

      const payload = JSON.stringify({
        passphrase: values.passphrase,
        new_pin: values.pin,
        otp: values.otp,
      });

      axios
        .post(resetPinUrl, payload, resetHeaders)
        .then((res) => {
          if (res.status === 200) {
            setIsPinResetLoading(false);
            setIsModalVisible(true);
            setIsResetPinModalVisible(false);
            notification.success({
              message: "Success",
              description: "Pin reset was successful",
              className: "custom-class",
              style: {
                top: 70,
              },
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error.response.data.info.errors.message,
            className: "custom-class",
            style: {
              top: 70,
            },
          });
          setIsPinResetLoading(false);
          setTimeout(() => {}, 1000);
        });

      activeRow.values = values;

      setState({ ...state, activeRow });
    } else {
      setTimeout(() => {}, 1000);

      setIsPinResetLoading(false);
      notification.error({
        message: "Error",
        description: "Make sure your pin match",
        className: "custom-class",
        style: {
          top: 70,
        },
      });
    }
  };

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleResetPinCancel}
        visible={isResetPinModalVisible}
        width={750}
      >
        <div>
          <div class="card-body mw-card-services-card-bg">
            <div class="d-flex align-items-center mw-card-services-title">
              <img
                src="./assets/wallet/images/mw-buttons/confirm-transfer-danger-icon.svg"
                alt=""
                class="img-fluid"
                width="70"
              />
              <h4 class="ms-3 mw-cs-title-name">Your Pin Was Blocked</h4>

              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <div class="card mt-3 border-0">
              <div class="card-body px-5 pt-4 light-green-gray-bg">
                <p class="mw-confirm-transfer-caption">
                  Your wallet account was blocked. Enter the details below to
                  unblock it
                </p>
              </div>
              <div class="card-footer  pt-1 pb-4 light-grey-bg border-0">
                <Spin spinning={isPinResetLoading} size="large">
                  <Form name="basic" onFinish={resetPin} autoComplete="off">
                    <br />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={resendOTP}
                      className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                      style={styles.shake}
                    >
                      Send me an OTP
                    </a>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        OTP**
                      </label>
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: "Please input the OTP!",
                          },
                        ]}
                      >
                        <input
                          type="number"
                          maxLength="6"
                          placeholder="Enter OTP"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        Pass Phrase*
                      </label>
                      <Form.Item
                        name="passphrase"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pass phrase!",
                          },
                        ]}
                      >
                        <input
                          type="text"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        New Pin*
                      </label>
                      <Form.Item
                        name="pin"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pin!",
                          },
                        ]}
                      >
                        <input
                          type="password"
                          maxLength="6"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        ReEnter Pin*
                      </label>
                      <Form.Item
                        name="pin2"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pin!",
                          },
                        ]}
                      >
                        <input
                          type="password"
                          maxLength="6"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <StyleRoot>
                      <button
                        htmlType="submit"
                        className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                        style={styles.shake}
                      >
                        Reset Pin
                      </button>
                    </StyleRoot>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        footer={false}
        closable={false}
        visible={isSuccessModalVisible}
        width={750}
      >
        <div className="card p-0 border-0">
          <div className="card-body messages-bg p-0 text-white">
            <SuccessMessage
              payment_id={activeRow.payment_id}
              reference={activeRow.reference}
              amount={paymentPayload.amount}
            />
          </div>
        </div>
      </Modal>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isWalletModalVisible}
        width={750}
      >
        <div>
          <div class="card-body mw-card-services-card-bg">
            <div class="d-flex align-items-center mw-card-services-title">
              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <Spin spinning={isPinSubmitted} delay={300} size="large">
              <div class="card mt-3 border-0">
                <Form name="basic" onFinish={onFinish} autoComplete="off">
                  <div className="card-footer px-5 pt-1 pb-4 light-grey-bg border-0">
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        Enter your pin to continue*
                      </label>
                      <Form.Item
                        name="pin"
                        rules={[
                          {
                            required: true,
                            message: `Please input pin!`,
                          },
                        ]}
                      >
                        <input
                          type="password"
                          maxLength="6"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                          placeholder="****"
                        />
                      </Form.Item>
                    </div>

                    <button
                      className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                      type="primary"
                      htmlType="submit"
                    >
                      Make payment
                    </button>
                  </div>
                </Form>

                <div class="card-footer  pt-1 pb-4 light-grey-bg border-0"></div>
              </div>
            </Spin>
          </div>
        </div>
      </Modal>

      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isPaynowModalVisible}
        width={750}
      >
        <div>
          <div class="card-body mw-card-services-card-bg">
            <div class="d-flex align-items-center mw-card-services-title">
              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <Spin spinning={isPinSubmitted} delay={300} size="large">
              <div class="card mt-3 border-0">
                <Form name="basic" onFinish={onPaynowFinish} autoComplete="off">
                  <div className="card-footer px-5 pt-1 pb-4 light-grey-bg border-0">
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        Enter the phone number to use for payments*
                      </label>
                      <Form.Item
                        name="paying_phone_number"
                        rules={[
                          {
                            required: true,
                            message: `Please input phone number!`,
                          },
                        ]}
                      >
                        <input
                          type="paying_phone_number"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                          placeholder="Phone number"
                        />
                      </Form.Item>
                    </div>

                    <button
                      className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                      type="primary"
                      htmlType="submit"
                    >
                      Make payment
                    </button>
                  </div>
                </Form>

                <div class="card-footer  pt-1 pb-4 light-grey-bg border-0"></div>
              </div>
            </Spin>
          </div>
        </div>
      </Modal>

      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div class="card-body mw-card-services-card-bg">
            <div class="d-flex align-items-center mw-card-services-title">
              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <div class="card mt-3 border-0">
              <Spin spinning={isIframeLoading} tip="Loading..." size="large">
                <iframe
                  height="500"
                  title="paymenturl"
                  style={{ width: "100%" }}
                  src={paymentUrl}
                />
              </Spin>
              <div class="card-footer  pt-1 pb-4 light-grey-bg border-0"></div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="col-12 col-lg-6 mt-4 mt-lg-0">
        <div className="card border-0">
          <div className="card-header p-3 nav-bg">
            <h5 className="text-white fw-800">Select payment method</h5>
          </div>

          <div className="card-body text-white pb-4">
            <div className="row">{renderPaymentTypes}</div>
          </div>
        </div>
      </div>
    </>
  );
};

PaymentMethod.defaultProps = {
  paymentPayload: [],
  payment_types: [],
};

PaymentMethod.propTypes = {
  paymentPayload: PropTypes.func,
  payment_types: PropTypes.array,
};

export default PaymentMethod;
