import { createRoutine } from 'redux-saga-routines';

const transactionsRoutine = createRoutine('GET_TRANSACTIONS');

export default transactionsRoutine;

export const paymentRoutine = createRoutine('PAYMENT');

export const sendMoneyRoutine = createRoutine('PEER_SEND_MONEY');
export const removeMessagesRoutine = createRoutine('REMOVE_MESSAGES');

export const paymentLookupRoutine = createRoutine('PAYMENT_LOOK_UP');
