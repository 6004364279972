import React, { useState } from "react";
import PropTypes from "prop-types";
import { notification, Spin, Modal, Button } from "antd";
import axios from "axios";
import getConfig from "config";
import SuccessMsgIcon from "assets/img/icons/success-message-icon.svg";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Link } from "react-router-dom";


const Signin = (props) => {
  const config = getConfig();
  const [state, setState] = useState({
    activeRow: {},
  });

  const [isButtonLoading, setButtonLoad] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const activeRow = state;
  const loginUrl = `${config.api.base_url}login/`;

  const onChangeHandler = (event) => {
    activeRow[event.target.name] = event.target.value;
    setState({ ...state, activeRow });
  };

  const onSubmit = () => {
    setButtonLoad(true);

    const payload = JSON.stringify({
      email: activeRow.email_address,
      password: activeRow.password,
    });

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    };

    if (
      activeRow.email_address === "" ||
      activeRow.password === "" ||
      activeRow.email_address === undefined ||
      activeRow.password === undefined
    ) {
      setButtonLoad(false);
      notification.error({
        message: "Error",
        description: "Fields cannot be empty",
      });
    } else {
      axios
        .post(loginUrl, payload, headers)
        .then((res) => {
          setButtonLoad(false);
          if (res.status === 200) {
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                loginToken: res.data.access,
                isLoggedIn: true,
              })
            );

            const token = res.data.access;
            const getUserUrl = `${config.api.base_url}request/user/`;

            const getUserheaders = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              mode: "cors",
            };
            axios
              .get(getUserUrl, getUserheaders)
              .then((res) => {
                if (res.status === 200) {
                  setIsModalVisible(true);
                  localStorage.setItem(
                    "userDetails",
                    JSON.stringify({
                      user: res.data.payload,
                      isLoggedIn: true,
                    })
                  );
                }
              })
              .catch((error) => {
                setButtonLoad(false);
                setTimeout(() => { }, 1000);
                notification.error({
                  message: "Error",
                  description: error.response.data.detail,
                  className: "custom-class",
                  style: {
                    top: 70,
                  },
                });
              });
          }
        })
        .catch((error) => {
          setButtonLoad(false);
          setTimeout(() => { }, 1000);
          notification.error({
            message: "Error",
            description: error.response.data.detail,
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        });
    }
  };

  const onEnterKey = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  const failureResponseGoogle = () => {
    setButtonLoad(false);
  };

  const responseFacebook = (response) => {
    setButtonLoad(true);

    const facebookUrl = `${config.api.base_url}rest-auth/facebook/`;



    const payload = JSON.stringify({
      access_token: response.accessToken,
      first_name: response.name,
      last_name: response.name,
      username: response.name,
      phone_number: "",
      password: response.userID,
      email: response.userID + "@intelliafrice.solutions",
    });


    activeRow.email_address = response.userID + "@intelliafrice.solutions";
    activeRow.password = response.userID;
    setState({ ...state, activeRow });

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    };

    axios
      .post(facebookUrl, payload, headers)
      .then((res) => {
        setButtonLoad(false);

        if (res.status === 200) {
          onSubmit();
        }
      })
      .catch((error) => {
        setButtonLoad(false);
        setTimeout(() => { }, 1000);
        notification.error({
          message: "Error",
          description: error.response.data.detail,
          className: "custom-class",
          style: {
            top: 70,
          },
        });
      });

  };

  const successResponseGoogle = (response) => {
    setButtonLoad(true);


    const googleUrl = `${config.api.base_url}rest-auth/google/`;

    const payload = JSON.stringify({
      access_token: response.accessToken,
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName,
      username: response.profileObj.name,
      email: response.profileObj.email,
      phone_number: "",
      password: response.profileObj.googleId,
    });

    activeRow.email_address = response.profileObj.email;
    activeRow.password = response.profileObj.googleId;
    setState({ ...state, activeRow });

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    };
    axios
      .post(googleUrl, payload, headers)
      .then((res) => {
        setButtonLoad(false);

        if (res.status === 200) {
          onSubmit();
        }
      })
      .catch((error) => {
        setButtonLoad(false);
        setTimeout(() => { }, 1000);
        notification.error({
          message: "Error",
          description: error.response.data.detail,
          className: "custom-class",
          style: {
            top: 70,
          },
        });
      });
  };

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={false}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div class="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              <div className="mb-4">
                <img src={SuccessMsgIcon} alt="" width={100} />
              </div>
              <div className="mb-5">
                <h2 className="text-green-2 fw-800">Login Successful!</h2>
              </div>
              <Button
                onClick={handleCancel}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Spin spinning={isButtonLoading} size="large">
        {/* <button
          class="btn bg-white w-100 box-shadow-1
  text-green-2 fw-600 text-start mb-3"
        >
          <img
            class="ms-3 me-3"
            src="./assets/img/icons/google-logo.svg"
            alt=""
            width="20"
          />
          Log in with Google
        </button> */}
        <GoogleLogin
          clientId={config.api.googleID}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              class="btn bg-white w-100 box-shadow-1 text-green-2 fw-600 text-start mb-3"
            >
              <img
                class="ms-3 me-3"
                src="./assets/img/icons/google-logo.svg"
                alt=""
                width="20"
              />
              Log in with Google
            </button>
          )}
          buttonText="Login"
          isSignedIn={false}
          onSuccess={successResponseGoogle}
          onFailure={failureResponseGoogle}
          cookiePolicy="single_host_origin"
        />

        <FacebookLogin
          appId={config.api.facebookID}
          autoLoad={false}
          callback={responseFacebook}
          render={renderProps => (
            <button
              onClick={renderProps.onClick}
              class="btn bg-white w-100 box-shadow-1 bg-facebook-blue text-white fw-600 text-start mb-3"
            >
              <img
                class="ms-4 me-3"
                src="./assets/img/icons/facebook-logo.svg"
                alt=""
                width="10"
              />
              Log in with Facebook
            </button>
          )}
        />

        {/* <button
          class="btn bg-white w-100 box-shadow-1 bg-facebook-blue
  text-white fw-600 text-start mb-3"
        >
          <img
            class="ms-4 me-3"
            src="./assets/img/icons/facebook-logo.svg"
            alt=""
            width="10"
          />
          Log in with Facebook
        </button> */}

        <div class="mb-3">
          <label for="email" class="form-label text-green-2 fw-600">
            Email address
          </label>
          <div class="input-group">
            <span
              class="input-group-text bg-transparent border-1-green"
              id="basic-addon1"
            >
              <img src="./assets/img/icons/login-user.svg" alt="" width="20" />
            </span>
            <input
              onChange={(e) => onChangeHandler(e)}
              id="email"
              onKeyPress={onEnterKey}
              name="email_address"
              required
              type="email"
              class="form-control shadow-none border-1-green bg-transparent"
              placeholder="Enter email address"
            />
          </div>
        </div>

        <div class="mb-3">
          <label for="password" class="form-label text-green-2 fw-600">
            Password
          </label>
          <div class="input-group">
            <span class="input-group-text bg-transparent border-1-green">
              <img src="./assets/img/icons/login-lock.svg" alt="" width="16" />
            </span>
            <input
              onKeyPress={onEnterKey}
              name="password"
              onChange={(e) => onChangeHandler(e)}
              id="password"
              type="password"
              class="form-control shadow-none border-1-green bg-transparent"
              placeholder="Enter Password"
            />
          </div>
        </div>

        <div class="form-check form-switch mt-3 mb-4">
          <label
            class="form-check-label text-green-2 fw-600 d-flex align-items-center justify-content-between"
            for="flexSwitchCheckChecked"
          >
            <small class="fs-12px"></small>

            <small class="ms-5 fs-12px">
              <Link
                to="/forgotpassword"
                class="text-decoration-none text-green-2 fw-700"
              >
                Forgot password?
              </Link>
            </small>
          </label>
        </div>
        <button
          type="submit"
          onClick={onSubmit}
          class="btn btn-warning w-100 text-uppercase text-white fw-800 letter-spacing-3 orange-gradient-bg-1"
        >
          Login
        </button>
      </Spin>
    </>
  );
};

Signin.defaultProps = {};

Signin.propTypes = {
  errorMessage: PropTypes.string,
  loginUser: PropTypes.func,
  removeMessages: PropTypes.func,
};

export default Signin;
