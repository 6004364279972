import React from 'react'
// import routes from 'routes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Main from 'containers/main'
import Login from 'containers/authentication'
import Contact from 'containers/contact'
import Wallet from 'containers/wallet'
import Statement from 'containers/statement'
import MakePayment from 'containers/makePayment'
import Shop from 'containers/shop'
import Store from 'containers/store'
import Cart from 'containers/cart/'
import Merchants from 'containers/merchants'
import Offerings from 'containers/offerings'
import Checkout from 'containers/checkout'
import LoadBalance from 'containers/loadBalance'
import SendMoney from 'containers/sendMoney'
import CardServices from 'containers/cardServices'
import Payments from 'containers/payments'
import CreateWallet from 'containers/createWallet'
import ProtectedRoute from './protected'
import ProtectedWalletRoute from './walletprotected'
import ForgotPassword from 'containers/forgotpassword'
import ResetPassword from 'containers/resetpassword'
import MyAccount from 'containers/myAccount'
import CookiesPolicy from 'containers/cookiesPolicy'
import TermsAndConditions from 'containers/termsAndConditions'
import PrivacyPolicy from 'containers/privacyPolicy'
import Industry from 'containers/industry'
import ScrollToTop from './scrollToTop'
import TumaiPay from 'containers/tumaiPay'
import TumaiKioks from 'containers/kiosks'
import Kiosks from 'containers/kiosks'

const routes = () => (
    <>
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path='/' component={TumaiKioks} />
                {/* <Route path='/login'>
                    <Login />
                </Route>
                <Route path='/signup'>
                    <Login />
                </Route>
                <Route path='/forgotpassword'>
                    <ForgotPassword />
                </Route>
                <Route path='/contact'>
                    <Contact />
                </Route>
                <Route path='/tumai-pay'>
                    <TumaiPay />
                </Route>
                <Route path='/tumai-kiosks'>
                    <TumaiKioks />
                </Route>
                <Route path='/our-services'>
                    <Main />
                </Route>

                <Route path='/statement'>
                    <Statement />
                </Route> */}
                <Route path='/cookies-policy' component={CookiesPolicy} />
                <Route
                    path='/terms-and-conditions'
                    component={TermsAndConditions}
                />
                {/* <Route path='/privacy-policy' component={PrivacyPolicy} />
                <Route path='/offerings' component={Offerings} />
                <Route path='/my-account' component={MyAccount} />
                <Route path='/payments' component={Payments} />
                <Route path='/shop' component={Shop} /> */}
                {/* <Route path="/merchants" component={Merchants} /> */}
                {/* <Route path='/industries' component={Industry} /> */}
                {/* <Route path='/cart' component={Cart} />
                <Route path='/verify/email/:user_id' component={Main} />
                <Route path='/transaction/:status' component={Main} />
                <Route
                    path='store/:merchant_id/:merchant_name'
                    component={Store}
                />
                <Route
                    path='/store/:merchant_id/:merchant_name/:category_id'
                    render={(merchant_id, merchant_name, category_id) => (
                        <Store
                            merchant_id={merchant_id}
                            merchant_name={merchant_name}
                            category_id={category_id}
                        />
                    )}
                />
                <Route
                    path='/merchants/:industryName/'
                    render={(industryName) => (
                        <Merchants industryName={industryName} />
                    )}
                />
                <Route
                    path='/reset-password/:user_id/'
                    render={(user_id) => {
                        return <ResetPassword user_id={user_id} />
                    }}
                /> */}

                {/*
        ========================================================================== 
          Protected URLs 
        ==========================================================================
        */}
                {/* <ProtectedRoute path="/shop" component={Shop} /> */}
                {/* <ProtectedRoute path='/checkout' component={Checkout} /> */}
                {/* <ProtectedRoute path="/merchants" component={Merchants} />
                <ProtectedRoute
                    path='/create-wallet'
                    component={CreateWallet}
                /> */}
                {/* <ProtectedRoute path="/cart" component={Cart} /> */}
                {/* <ProtectedRoute
          path="store/:merchant_id/:merchant_name"
        
          component={Store}
        /> */}

                {/* 
        ===========================================================================
        Protected wallet URLS
        ===========================================================================
        */}
                {/* <ProtectedRoute path='/wallet' component={Wallet} /> */}
                {/* <ProtectedWalletRoute
                    path='/load-balance'
                    component={LoadBalance}
                /> */}
                {/* <ProtectedWalletRoute
                    path='/send-money'
                    component={SendMoney}
                />
                <ProtectedWalletRoute
                    path='/card-services'
                    component={CardServices}
                /> */}
                {/* <ProtectedWalletRoute
                    path='/make-payment'
                    component={MakePayment}
                /> */}

                <Route path='*'>
                   <Kiosks/>
                </Route>
            </Switch>
        </Router>
    </>
)

export default routes
