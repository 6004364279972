import React from "react";
import { Form } from "antd";
import PropTypes from "prop-types";

const Country = (props) => {
  const { class_name, option_color, setCountry } = props;

  const onChangeHandler = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
      <Form.Item
        rules={[{ required: true, message: "Please select country!" }]}
        name="country"
      >
        <select onChange={onChangeHandler} className={class_name}>
          <option className={option_color} value="">
            Select Country
          </option>
          <option className={option_color} value="zimbabwe">
            Zimbabwe
          </option>
          <optgroup className={option_color} label="Coming soon">
            <option disabled>Botswana</option>
            <option disabled>Kenya</option>
            <option disabled>Lesotho</option>
            <option disabled>Malawi</option>
            <option disabled>Mozambique</option>
            <option disabled>Namibia</option>
            <option disabled>South Africa</option>
            <option disabled>Uganda</option>
          <option value="ZM" disabled>Zambia</option>
          </optgroup>
        </select>
      </Form.Item>
    </>
  );
};

Country.defaultProps = {};

Country.propTypes = {
  class_name: PropTypes.string,
  option_color: PropTypes.string,
  setCountry: PropTypes.func,
};

export default Country;
