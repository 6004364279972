import React, { useState } from "react";
import CheckoutCardBg from "assets/img/checkout-card-bg.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";

const VoucherCard = (props) => {
  const { logo, amount, id, setPaymentPayload, voucherNo, rates } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currency, setCurrency] = useState("");

  const getPhoneNumber = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const phone_number = JSON.parse(userDetails).user.phone_number;
      return phone_number;
    }
    return false;
  };

  let paymentDetails = [];
  paymentDetails.push({
    service: voucherNo,
    description: "voucher",
    subtotal: amount,
  });
  const payload = {
    amount: amount,
    billpaymentName: "Voucher",
    hasRates: false,
    transactionType: "009",
    currency: currency,
    extras: { voucher_id: id, phoneNumber: getPhoneNumber() },
    paymentDetails: {
      paymentDetails: paymentDetails,
    },
  };

  const history = useHistory();
  const paymentCheckout = () => {
    setPaymentPayload(payload);
    history.push({
      pathname: "/payments",
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCurrency = () => {
    setIsModalVisible(true);
  };

  const currencyChange = (event) => {
    setCurrency(event.target.value);
  };

  let ratesOptions;
  if (Object.keys(rates).length > 0) {
    ratesOptions = (
      <>
        {!!rates &&
          rates.map((items) => {
            return (
              <option key={items.from_currency} value={items.from_currency}>
                {items.from_currency.toUpperCase()}
              </option>
            );
          })}
      </>
    );
  }


  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div className="card-body mw-card-services-card-bg">
            <div className="text-center py-5">
              <div className="mb-4"></div>
              <div className="mb-5">
                <h6 className="text-green-2">Currency</h6>
                <br />
                <select
                  onChange={(e) => currencyChange(e)}
                  name="currency"
                  className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
                >
                  <option value="">Select currency</option>
                  {ratesOptions}
                </select>
              </div>
              <Button
                onClick={paymentCheckout}
                type="primary"
                key="console"
                className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div
        className="checkout-card-1 box-shadow-1 bg-white d-md-flex align-items-center p-3 ps-md-5 mt-4"
        style={{
          backgroundImage: "url(" + CheckoutCardBg + ")",
          backgroundPosition: "top left",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
          height: "auto",
        }}
      >
        <div
          className="checkout-circle mx-auto mx-md-0"
          style={{
            backgroundImage: "url(" + logo + ")",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: 150,
          }}
        ></div>

        <div className="ms-0 ms-md-5 mt-4 mt-md-0 px-3 px-md-0 text-green-2 text-center">
          <div className="d-flex justify-content-between">
            <div className="text-start">
              <h6 className="fw-800 text-green-2">Voucher ID</h6>
              <p className="fw-500 text-green-2">{voucherNo}</p>
            </div>

            <h3 className="fw-800 text-green-2">${amount}</h3>
          </div>

          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={getCurrency}
              to="#"
              className="btn blue-gradient-bg-1 border-0 rounded-pill w-100 text-uppercase text-white letter-spacing-3 fw-800 fs-14px py-3 px-4 box-shadow-btn"
            >
              pay for voucher
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

VoucherCard.defaultProps = {};

VoucherCard.propTypes = {
  amount: PropTypes.number,
  logo: PropTypes.string,
  voucherNo: PropTypes.string,
  id: PropTypes.number,
  rates: PropTypes.array,
  setPaymentPayload: PropTypes.func,
};

export default VoucherCard;
