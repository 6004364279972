import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import walletRoutine, {
  sendMoneyRoutine,
  removeMessagesRoutine,
} from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
  reloadRequested: false,
  showSuccessMessage: false,
  showErrorMessage: false,
});

let walletErrorMessage;

export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    // Wallet Routine
    case walletRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case walletRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case walletRoutine.SUCCESS:
      return state.merge({
        errorMessage: null,
        transactions: action.payload.data.transactions,
        balance: action.payload.data.balance,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case walletRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: "Failed to retrieve transactions",
        showErrorMessage: Math.random(),
      });

    /*
    ***********************************************************************************************************************
      Send Money reducer
    ***********************************************************************************************************************
    */
    case sendMoneyRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case sendMoneyRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case sendMoneyRoutine.SUCCESS:
      let paymentUrl;
      if (action.payload.data["paymentUrl"]) {
        paymentUrl = action.payload.data["paymentUrl"];
      }
      return state.merge({
        errorMessage: null,
        successMessage: action.payload.data,
        containerState: STATE_OK,
        showSuccessMessage: true,
        paymentUrl: paymentUrl,
        reloadRequested: true,
      });
    case sendMoneyRoutine.FAILURE:
      if (action.payload.non_field_errors) {
        walletErrorMessage = action.payload.non_field_errors[0];
      } else if (action.payload.transaction) {
        walletErrorMessage = action.payload.transaction[0];
      }

      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: walletErrorMessage,
        successMessage: null,
        showErrorMessage: Math.random(),
        showSuccessMessage: false,
      });

    /*
    ***********************************************************************************************************************
      Remove Messages
    ***********************************************************************************************************************
    */
    case removeMessagesRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case removeMessagesRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case removeMessagesRoutine.SUCCESS:
      return state.merge({
        errorMessage: null,
        successMessage: null,
        containerState: STATE_OK,
        showSuccessMessage: false,
        reloadRequested: false,
      });
    case removeMessagesRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: null,
        successMessage: null,
        showSuccessMessage: false,
        reloadRequested: false,
        showErrorMessage: false,
      });

    /*
      ***********************************************************************************************************************
      END OF SEND MONEY REDUCER
      
      */

    default:
      return state;
  }
}
