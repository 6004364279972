import React from "react";
import { connect } from "react-redux";

import Header from "components/contact";
import HeaderTitles from "components/main/headerTitles/HeaderTitles";

import { Helmet } from "react-helmet";

function Contact(props) {
  const header_titles = (
    <>
      {" "}
      <HeaderTitles />
    </>
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <title>Contact Us | Tumai</title>
        <meta
          name="description"
          content="Contact us for any enquiries or feedback. We are always happy to hear from you."
        />

        <meta
          name="keywords"
          content=", Tumai, remitance, diaspora, payments, bill payments, zimbabwe airtime, econet data, netone data, ZESA token, DSTV payments, Zimbabwe bill payments, N Richards voucher"
        />

        <link rel="canonical" href="https://tumai.to/" />

        <meta property="og:url" content="https://tumai.to/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Tumai" />
        <meta
          property="og:description"
          content="Contact us for any enquiries or feedback. We are always happy to hear from you."
        />
        <meta
          property="og:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="twitter:card"
          content="https://tumai.to/assets/img/logo.svg"
        />
        <meta property="twitter:domain" content="tumai.to" />
        <meta property="twitter:url" content="https://tumai.to/" />
        <meta name="twitter:title" content="Tumai" />
        <meta
          name="twitter:description"
          content="Contact us for any enquiries or feedback. We are always happy to hear from you."
        />
        <meta
          name="twitter:image"
          content="https://tumai.to/assets/img/logo.svg"
        />

        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="”robots”" content="index, follow" />
      </Helmet>
      <Header header_titles={header_titles} />
    </>
  );
}

Contact.defaultProps = {};

Contact.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
