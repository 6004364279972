import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import getCategoriesRoutine from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
});

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case getCategoriesRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case getCategoriesRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case getCategoriesRoutine.SUCCESS:
      return state.merge({
        categories: action.payload.results,
        containerState: STATE_OK,
      });
    case getCategoriesRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
      });

    default:
      return state;
  }
}
