import React from "react";
import Header from "./header";
import PropTypes from "prop-types";

const Logo = (props) => {
  const { header_titles } = props;
  return (
    <>
      <Header header_titles={header_titles} />
    </>
  );
};

Logo.defaultProps = {};

Logo.propTypes = {
  header_titles: PropTypes.string,
};

export default Logo;
