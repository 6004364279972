import React, { useState } from "react";
import PropTypes from "prop-types";

const CartItems = (props) => {
  const {
    productName,
    picture,
    price,
    actual_price,
    productID,
    handleInputChange,
    handleRemoveChange,
    quantity,
  } = props;

  const [count, setCount] = useState(quantity);
  const [productPrice, setPrice] = useState(price);

  const increaseCounter = () => {
    setCount(count + 1);
    setPrice(productPrice + actual_price);
    handleInputChange(productID, actual_price, 1, picture, productName);
  };

  const dereaseCounter = () => {
    if (count > 0) {
      setCount(count - 1);
      setPrice(productPrice - actual_price);
      handleRemoveChange(productID, actual_price, 1, picture, productName);
    } else {
      setCount(0);
    }
  };
  return (
    <>
      {" "}
      <tr>
        <td>
          <div
            className="card box-shadow-1 border-0 text-center"
            style={{ width: 200 }}
          >
            <div className="card-body p-0">
              <img src={picture} alt="" width={100} className="my-4" />
              <div className="orange-gradient-bg-2 py-2 height-50">
                <h6 className="m-0 fw-700 text-uppercase letter-spacing-3 text-white">
                  {productName}
                </h6>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="add-buttons d-flex px-5 mx-lg-3 mt-2 justify-content-between align-items-center">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => increaseCounter()}
              href
              className="btn border-0 bg-green-2 p-2 rounded-0"
            >
              <img src="assets/img/icons/plus.svg" alt="" width={20} />
            </a>
            <span className="text-green-2 fw-800 fs-20px mx-lg-3 mx-3">
              {count}
            </span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => dereaseCounter()}
              href
              className="btn border-0 bg-green-2 p-2 rounded-0"
            >
              <img src="assets/img/icons/minus.svg" alt="" width={20} />
            </a>
          </div>
        </td>
        <td>
          <h3 className="text-green-2 fw-800 letter-spacing-3">
            ${productPrice.toFixed(2)}
          </h3>
        </td>
      </tr>
    </>
  );
};

CartItems.defaultProps = {};

CartItems.propTypes = {
  productName: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  productID: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  actual_price: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleRemoveChange: PropTypes.func.isRequired,
};

export default CartItems;
