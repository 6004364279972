import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import CartBanner from "components/shop/banner/cartbanner/";
import CartItems from "components/shop/cartitems/";

import { message, Tooltip } from "antd";
import {
  addToCartRoutine,
  checkoutRoutine,
  cartCounterRoutine,
  removeFromCartRoutine,
} from "containers/store/routines";
import Navigation from "../../components/main/navigation";
import { setPaymentPayloadRoutine } from "containers/main/routines";
import { Link } from "react-router-dom";

import HomeIcon from "assets/img/icons/home.svg";
import WalletIcon from "assets/img/icons/wallet.svg";
import CartIcon from "assets/img/icons/cart.svg";

function Cart(props) {
  const {
    checkout,
    counter,
    addToCart,
    cartCount,
    cart,
    removeToCart,
    setPaymentPayload,
    isVoucherCreated,
  } = props;

  const sum = cart
    .map((item) => item.quantity)
    .reduce((prev, curr) => prev + curr, 0);

  const total_price = cart
    .map((item) => item.price)
    .reduce((prev, curr) => prev + curr, 0);

  const header_titles = (
    <>
      <li className="nav-item ps-3">
        <a className="nav-link text-uppercase" href="/">
          <img src={HomeIcon} height="25" alt="" />
        </a>
      </li>

      <li className="nav-item ps-3">
        <Link className="nav-link text-uppercase" to="#">
          <img src={WalletIcon} height="25" alt="" />
        </Link>
      </li>

      <li className="nav-item ps-3 position-relative">
        <Link className="nav-link text-uppercase" to="/cart">
          <img src={CartIcon} height="25" alt="" />
        </Link>
        <span className="position-absolute top-30 start-100 translate-middle badge rounded-pill bg-white text-dark">
          {sum}
          <span className="visually-hidden">unread messages</span>
        </span>
      </li>
    </>
  );

  const handleInputChange = (
    productID,
    price,
    quantity,
    picture,
    productName
  ) => {
    message.success({
      content: "Product added to cart",
      className: "custom-class",
      style: {
        marginTop: 70,
      },
    });
    addToCart(productID, cart, quantity, price, picture, productName);
    cartCount(counter + quantity);
  };

  const history = useHistory();
  const cartcheckout = () => {
    const sum = cart
      .map((item) => item.price)
      .reduce((prev, curr) => prev + curr, 0);

    const payload = {
      amount: sum,
      transactionType: "008",
      currency: "USD",
      extras: cart.map((cartItem) => ({
        product_id: cartItem.product_id,
        quantity: cartItem.quantity,
      })),
    };
    setPaymentPayload(payload);
    checkout(payload);
  };

  if (isVoucherCreated) {
    history.push({
      pathname: "/checkout",
    });
  }

  const handleRemoveChange = (
    productID,
    price,
    quantity,
    picture,
    productName
  ) => {
    message.warning("Product removed from cart");
    removeToCart(productID, cart, quantity, price, picture, productName);
    cartCount(counter - quantity);
  };

  return (
    <>
      <Navigation header_titles={header_titles} />
      <CartBanner />
      <section id="cards">
        <div className="wallet-cards-bg py-5">
          <div className="container">
            <div className="card border-0 box-shadow-1">
              <div className="card-header nav-bg border-0 py-4 px-md-5 d-flex align-items-center justify-content-between">
                <h4 className="text-white fw-800">Shopping Cart({sum})</h4>
                <a
                  href="/industries"
                  className="btn btn-sm bg-white fw-700 letter-spacing-3 text-green-2"
                >
                  Continue Shopping
                </a>
              </div>
              <div className="card-body px-lg-5 pb-5 light-grey-bg">
                <div className="row">
                  <div className="col-12 col-md-8">
                    <div className="table-responsive m-0 p-0">
                      <table className="table table-borderless my-4 text-white">
                        <thead className="cart-payments-table-border">
                          <tr>
                            <th style={{ width: 200 }}>
                              <h6 className="letter-spacing-3 fs-16px text-orange-1 fw-800 text-uppercase text-center m-0 py-2">
                                product
                              </h6>
                            </th>
                            <th>
                              <h6 className="letter-spacing-3 fs-16px text-orange-1 fw-800 text-uppercase text-center m-0 py-2">
                                quantity
                              </h6>
                            </th>
                            <th>
                              <h6 className="letter-spacing-3 fs-16px text-orange-1 fw-800 text-uppercase text-center m-0 py-2">
                                subtotal
                              </h6>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {cart.map((item) => (
                            <CartItems
                              key={item.product_id}
                              productID={item.product_id}
                              productName={item.productName}
                              picture={item.picture}
                              price={item.price}
                              quantity={item.quantity}
                              actual_price={item.actual_price}
                              handleInputChange={handleInputChange}
                              handleRemoveChange={handleRemoveChange}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-4">
                    <div className="card border-0">
                      <div className="card-body cart-card-bg p-0">
                        <div className="py-4 px-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="text-white fw-800 letter-spacing-3">
                              Your Order
                            </h5>
                            <div className="horizontal-line bg-white w-25" />
                          </div>
                          <table className="table table-borderless my-2 text-white letter-spacing-3 fw-600 fs-16px">
                            <tbody>
                              <tr>
                                <td>{sum} Items</td>
                                <td className="text-center">
                                  ${total_price.toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot className="fw-800 fs-20px">
                              <tr>
                                <td>Total</td>
                                <td className="text-center">
                                  ${total_price.toFixed(2)}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                          <div className="text-center mt-4">
                            <Tooltip
                              placement="topLeft"
                              title="Your cart will be cleared if you proceed to checkout"
                            >
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                href
                                onClick={cartcheckout}
                                className="btn px-2 py-2 blue-gradient-bg-1 border-0 rounded-0 letter-spacing-3 text-uppercase fw-800 text-white"
                              >
                                Proceed to checkout
                              </a>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Cart.defaultProps = {
  counter: 0,
  cart: [],
};

Cart.propTypes = {};

function mapStateToProps(state) {
  return {
    counter: state.getIn(["store", "counter"]),
    cart: state.getIn(["store", "cart"]),
    isVoucherCreated: state.getIn(["store", "isVoucherCreated"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (productID, cart, quantity, price, picture, productName) =>
      dispatch(
        addToCartRoutine.trigger({
          productID,
          cart,
          quantity,
          price,
          picture,
          productName,
        })
      ),
    removeToCart: (productID, cart, quantity, price, picture, productName) =>
      dispatch(
        removeFromCartRoutine.trigger({
          productID,
          cart,
          quantity,
          price,
          picture,
          productName,
        })
      ),
    checkout: (cart) => dispatch(checkoutRoutine.trigger({ cart })),
    cartCount: (counter) => dispatch(cartCounterRoutine.trigger({ counter })),
    setPaymentPayload: (values) =>
      dispatch(setPaymentPayloadRoutine.trigger({ values })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
