import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Popover } from "antd";

import UserIcon from "assets/img/icons/user-icon.svg";
import { getUserDetailsRoutine } from "containers/authentication/routines";
import { updateCartRoutine } from "containers/store/routines";
import { Link } from "react-router-dom";
function Nav(props) {
  const { header_titles, userDetails } = props;
  let userData;
  useEffect(() => {
    const { getUserDetails, updateCart } = props;
    getUserDetails();
    updateCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userDetails");
    window.location = "/";
  };

  let walletUrl;
  if (userDetails.has_wallet) {
    // walletUrl = "/wallet";
  } else {
    // walletUrl = "/create-wallet";
  }

  // function isEmpty(obj) {
  //   return Object.keys(obj).length === 0;
  // }

  const getUserDetails = () => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails !== null) {
      const user = JSON.parse(userDetails).user;
      return user;
    }
    return false;
  };

  if (getUserDetails()) {
    userData = (
      <>
        {/* <li className="nav-item ms-lg-5 dropdown"> */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {/* <Link
            className="nav-link nav-li-a"
            to="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
          >
            <div className="d-flex align-items-center">
              <img src={UserIcon} height="25" alt="" width="16" />
              <span className="text-uppercase letter-spacing-3 ms-3">
                {getUserDetails().first_name}
              </span>
            </div>
          </Link> */}
          {/* <ul
            className="dropdown-menu bg-light-green-gray"
            aria-labelledby="navbarDropdown"
          >
            <Popover content="Coming soon" title="">
              <li>
                <Link className="dropdown-item" to={walletUrl}>
                  Wallet
                </Link>
              </li>
            </Popover>
            <li>
              <Link className="dropdown-item" to="/my-account">
                My Account
              </Link>
            </li>
          </ul>
        </li> */}

        {/* <li className="nav-item ms-lg-3"> */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {/* <Link onClick={logout} className="nav-link nav-li-a" to="#">
            Logout
          </Link>
        </li>*/}
      </> 
    );
  } else {
    userData = (
      <>
        {/* <li className="nav-item ms-lg-3">
          <Link className="nav-link nav-li-a" to="/login">
            <span className="nav-li-separator d-none d-lg-inline-block ms-lg-3 me-3">
              |
            </span>
            Login
          </Link>
        </li>

        <li className="nav-item ms-lg-3">
          <Link className="nav-link nav-li-a" to="/signup">
            Sign Up
          </Link>
        </li> */}
      </>
    );
  }

  return (
    <>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
          {header_titles}
          {userData}
        </ul>
      </div>
    </>
  );
}

Nav.defaultProps = {
  userDetails: {},
};

Nav.propTypes = {
  header_titles: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userDetails: state.getIn(["authentication", "userDetails"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserDetails: () => dispatch(getUserDetailsRoutine.trigger({})),
    updateCart: () => dispatch(updateCartRoutine.trigger({})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
