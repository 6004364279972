import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import sendMoneyRoutine, { peerLookUpRoutine } from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
  reloadRequested: false,
  lookupSuccess: false,
});

let walletErrorMessage;

export default function lookupReducer(state = initialState, action) {
  switch (action.type) {
    // Send Money Routine
    case sendMoneyRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case sendMoneyRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case sendMoneyRoutine.SUCCESS:
      const payload = action.payload.data;
      const messageSuccess = `Amount $USD${payload.amount} with reference ${payload.reference} was sent successfully`;
      return state.merge({
        errorMessage: null,
        showSendMoneySuccessMessage: true,
        successMessage: messageSuccess,
        containerState: STATE_OK,
        reloadRequested: true,
      });
    case sendMoneyRoutine.FAILURE:
      if (action.payload.non_field_errors) {
        walletErrorMessage = action.payload.non_field_errors[0];
      } else if (action.payload.transaction) {
        walletErrorMessage = action.payload.transaction[0];
      }
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: walletErrorMessage,
        successMessage: null,
        showSendMoneyErrorMessage: Math.random(),
      });

    // Lookup Routine
    case peerLookUpRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case peerLookUpRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case peerLookUpRoutine.SUCCESS:
      let username = "";
      if (action.payload.data) {
        username = action.payload.data.name;
      } else {
        username = "Requested user does not exist!!";
      }
      return state.merge({
        errorMessage: null,
        lookupSuccess: true,
        foundUser: username,
        containerState: STATE_OK,
        reloadRequested: false,
      });
    case peerLookUpRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        lookupSuccess: false,
      });
    default:
      return state;
  }
}
