import React from "react";

import LogoLg from "assets/img/logo.svg"

/**
 * @summary Render Products Handle component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const Logo = (props) => {
  return (
    <>
      <a className="navbar-brand" href="/">
        <img src={LogoLg} alt="" width={120} />
      </a>
    </>
  );
};

Logo.defaultProps = {};

Logo.propTypes = {};

export default Logo;
