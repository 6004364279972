import React from "react";

export default function Links() {
  return (
    <div class="col-sm-12 col-lg-6">
      <div class="mt-3 d-none d-lg-inline-block">
        <div>
          <a href class="text-decoration-none">
            <h4 class="fw-800 text-green-2 mb-0 ms-2">
              Link a credit or debit card
              <img
                src="./assets/img/icons/double-chevron-right-green.svg"
                alt=""
                class="img-fluid ms-2"
                width="16"
              />
            </h4>
            <small class="text-muted ms-2">
              Keep your card info secure when shopping
            </small>
            <hr class="bg-green-2 mt-2" />
          </a>
        </div>

        <div>
          <a href class="text-decoration-none">
            <h4 class="fw-800 text-green-2 mb-0 ms-2">
              Link a bank account
              <img
                src="./assets/img/icons/double-chevron-right-green.svg"
                alt=""
                class="img-fluid ms-2"
                width="16"
              />
            </h4>
            <small class="text-muted ms-2">
              We’ll transfer your payments to your bank account
            </small>
            <hr class="bg-green-2 mt-2" />
          </a>
        </div>

        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" class="text-decoration-none">
            <h4 class="fw-800 text-green-2 mb-0 ms-2">
              View Transactions
              <img
                src="./assets/img/icons/double-chevron-right-green.svg"
                alt=""
                class="img-fluid ms-2"
                width="16"
              />
            </h4>
            <small class="text-muted ms-2">View your recent transactions</small>
            <hr class="bg-green-2 mt-2" />
          </a>
        </div>

        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" class="text-decoration-none">
            <h4 class="fw-800 text-green-2 mb-0 ms-2">
              Contact Support
              <img
                src="./assets/img/icons/double-chevron-right-green.svg"
                alt=""
                class="img-fluid ms-2"
                width="16"
              />
            </h4>
            <small class="text-muted ms-2">
              Get assistance for any account issues
            </small>
            <hr class="bg-green-2 mt-2" />
          </a>
        </div>
      </div>
    </div>
  );
}
