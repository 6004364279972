import { fromJS } from "immutable";
import {
  STATE_NEW,
  STATE_LOADING,
  STATE_ERROR,
  STATE_OK,
} from "../../constants";
import loginRoutine, {
  removeMessagesRoutine,
  registerRoutine,
  getUserDetailsRoutine,
  saveEmailAndPasswordRoutine,
} from "./routines";

const initialState = fromJS({
  containerState: STATE_NEW,
  loggedIn: false,
  errorMessage: null,
  reloadRequested: false,
  isLoggedIn: false,
  isRegistered: false,
});

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * =============================================================
     * LOGIN
     * =============================================================
     */
    case loginRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case loginRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case loginRoutine.SUCCESS:
      return state.merge({
        services: action.payload,
        containerState: STATE_OK,
        reloadRequested: false,
        isRegistered: false,
        isLoggedIn: true,
      });
    case loginRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: action.payload,
        showErrorMessage: Math.random(),
      });

    /**
     * =============================================================
     * END OF SERVICES
     * =============================================================
     */
    /*
    ***********************************************************************************************************************
      Remove Messages
    ***********************************************************************************************************************
    */
    case removeMessagesRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case removeMessagesRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case removeMessagesRoutine.SUCCESS:
      return state.merge({
        errorMessage: null,
        successMessage: null,
        containerState: STATE_OK,
        showSuccessMessage: false,
        reloadRequested: false,
        isLoggedIn: false,
        isRegistered: false,
      });
    case removeMessagesRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: null,
        successMessage: null,
        showSuccessMessage: false,
        reloadRequested: false,
        showErrorMessage: false,
        isLoggedIn: false,
        isRegistered: false,
      });
    /**
     * =============================================================
     * REGISTER
     * =============================================================
     */
    case registerRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case registerRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case registerRoutine.SUCCESS:
      return state.merge({
        services: action.payload,
        containerState: STATE_OK,
        isLoggedIn: false,
        isRegistered: true,
      });
    case registerRoutine.FAILURE:
      let errors = [];
      if (action.payload.data.message.errors["password"]) {
        errors.push(action.payload.data.message.errors.password);
      }
      if (action.payload.data.message.errors["email"]) {
        errors.push(action.payload.data.message.errors.email);
      }
      if (action.payload.data.message.errors["phone_number"]) {
        errors.push(action.payload.data.message.errors.phone_number);
      }
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: errors,
        showErrorMessage: Math.random(),
      });

    /**
     * =============================================================
     * GET USER DETAILS
     * =============================================================
     */
    case getUserDetailsRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case getUserDetailsRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case getUserDetailsRoutine.SUCCESS:
      return state.merge({
        userDetails: action.payload.payload,
        containerState: STATE_OK,
        isLoggedIn: false,
        hasWallet: action.payload.payload.has_wallet,
      });
    case getUserDetailsRoutine.FAILURE:
      // localStorage.removeItem("userInfo");
      // localStorage.removeItem("userDetails");
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: errors,
      });

    /**
     * =============================================================
     * GET USER DETAILS
     * =============================================================
     */
    case saveEmailAndPasswordRoutine.TRIGGER:
      return state.merge({
        loading: true,
        containerState: STATE_LOADING,
      });
    case saveEmailAndPasswordRoutine.REQUEST:
      return state.merge({
        loading: false,
        containerState: STATE_LOADING,
      });
    case saveEmailAndPasswordRoutine.SUCCESS:
      return state.merge({
        registeringUser: action.payload,
        containerState: STATE_OK,
        isLoggedIn: false,
      });
    case saveEmailAndPasswordRoutine.FAILURE:
      return state.merge({
        containerState: STATE_ERROR,
        errorMessage: errors,
      });

    default:
      return state;
  }
}
