import React from "react";
import { Form } from "antd";
import PhoneInput from "react-phone-input-2";

const PhoneNumber = (props) => {

  const countries = ["zw", "zm"];

  return (
    <>
      <Form.Item
        name="phone_number"
        rules={[{ required: true, message: "Please input phone number!" }]}
      >
        <PhoneInput
          className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
          country={"zw"}
          onlyCountries={countries}
          placeholder="263779525756"
        />
      </Form.Item>
    </>
  );
};

PhoneNumber.defaultProps = {};

PhoneNumber.propTypes = {
};

export default PhoneNumber;
