import React, { useState } from "react";
import PropTypes from "prop-types";
import "assets/otp.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import getConfig from "config";
import { Spin, notification, Form } from "antd";
import PhoneInput from "react-phone-input-2";

const OtpForm = (props) => {
  const [state, setState] = useState({ activeRow: {} });
  const { phone_number } = props;
  const [createWalletSpinner, setCreateWalletSpinner] = useState(false);

  const { activeRow } = state;

  const onChangeHandler = (event) => {

    let inputId = "";
    if (parseInt(event.target.id) < 6) {
      inputId = parseInt(event.target.id) + 1;
      document.getElementById(inputId).focus();
    }
    activeRow[event.target.name] = event.target.value;
    setState({ ...state, activeRow });
  };

  const handleOnChange = (value) => {
    activeRow.phone_number = value;
    setState({ ...state, activeRow });
  };

  const history = useHistory();
  const config = getConfig();

  const serviceUrl = `${config.api.base_url}wallet/verify_otp/`;
  const resendOtpUrl = `${config.api.base_url}wallet/resend/otp/`;

  const countries = ["zw"];

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${config.api.token}`,
    },
    mode: "cors",
  };

  //resend OTP

  const resentOtp = () => {
    if (
      activeRow.phone_number === undefined ||
      activeRow.phone_number.length <= 3
    ) {
      notification.error({
        message: "Please Enter Phone Number",
        className: "custom-class",
        style: {
          top: 70,
        },
      });
    } else {
      setCreateWalletSpinner(true);
      const payload = JSON.stringify({
        phone_number: activeRow.phone_number,
      });

      axios
        .post(resendOtpUrl, payload, headers)
        .then((res) => {
          setCreateWalletSpinner(false);
          if (res.status === 200) {
            notification.success({
              message: "success",
              description: "OTP has been sent check your phone number",
              className: "custom-class",
              style: {
                top: 70,
              },
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error.response.data.info.errors.message,
            className: "custom-class",
            style: {
              top: 70,
            },
          });
          setTimeout(() => {
            setCreateWalletSpinner(false);
          }, 3000);
        });
    }
  };

  //submit OTP
  const submitOtp = () => {
    setCreateWalletSpinner(true);
    const otp =
      activeRow["1st"] +
      activeRow["sec"] +
      activeRow["third"] +
      activeRow["fourth"] +
      activeRow["fifth"] +
      activeRow["sixth"];

    let submit_phone_number;
    if (!phone_number) {
      submit_phone_number = activeRow.phone_number;
    } else {
      submit_phone_number = phone_number;
    }

    const payload = JSON.stringify({
      otp: otp,
      phone_number: submit_phone_number,
    });
    axios
      .post(serviceUrl, payload, headers)
      .then((res) => {
        setCreateWalletSpinner(false);
        if (res.status === 200) {
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${config.api.token}`,
            },
            mode: "cors",
          };

          const getUserUrl = `${config.api.base_url}request/user/`;
          axios
            .get(getUserUrl, headers)
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify({
                    user: res.data.payload,
                    isLoggedIn: true,
                  })
                );
                history.push({
                  pathname: "/wallet",
                });
              }
            })
            .catch((error) => {});
        } else {
          notification.error({
            message: "Error",
            description: res.data.info.errors.message,
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Failed to verify",
          className: "custom-class",
          style: {
            top: 70,
          },
        });
        setTimeout(() => {
          setCreateWalletSpinner(false);
        }, 3000);
      });
  };

  let user_phone_number = "";
  let get_otp_text = "";

  if (!phone_number) {
    user_phone_number = (
      <div className="mb-2 mx-5">
        <Form.Item>
          <PhoneInput
            className="form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2"
            country={"zw"}
            onChange={handleOnChange}
            inputProps={{
              name: "phone_number",
            }}
            onlyCountries={countries}
            placeholder="263779525756"
          />
        </Form.Item>
      </div>
    );

    get_otp_text = (
      <>
        <h6 className="text-green fw-500 mb-1">
          Didn’t receive OTP? <br />
        </h6>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          onClick={resentOtp}
          className="text-light-green text-decoration-none fw-700"
        >
          Resend OTP
        </a>
      </>
    );
  }

  return (
    <>
      <header>
        <div className="create-wallet-header-bg pt-4 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6"></div>

              <div className="col-12 col-lg-6">
                <div className="card border-0 box-shadow-1 p-2 wallet-cards-bg bg-white">
                  <Spin
                    spinning={createWalletSpinner}
                    delay={300}
                    size="large"
                    style={{ marginTop: "70px" }}
                  >
                    <div className="card-body p-4">
                      <div className="card light-gray-bg-1 border-0">
                        <div className="card-body text-center">
                          <div className="mb-3">
                            <img
                              src="assets/img/icons/otp-icon.svg"
                              alt=""
                              width="100"
                            />
                          </div>

                          <h5 className="text-green fw-800 mb-3">
                            Enter One Time Password (OTP)
                          </h5>

                          <h6 className="text-green fw-500 mb-3">
                            A One Time Password has been <br />
                            sent to your phone number.
                          </h6>

                          <div className="w-75 mx-auto my-3">
                            {user_phone_number}
                          </div>

                          <div className="my-4 d-flex justify-content-between mx-md-5">
                            <input
                              onChange={onChangeHandler}
                              type="text"
                              className="otp-input bg-light-green-gray text-center"
                              id="1"
                              maxlength="1"
                              name="1st"
                              onkeyup="clickEvent(this,'sec')"
                              required
                            />

                            <input
                              onChange={onChangeHandler}
                              type="text"
                              className="otp-input bg-light-green-gray text-center"
                              id="2"
                              maxlength="1"
                              onkeyup="clickEvent(this,'third')"
                              name="sec"
                              required
                            />

                            <input
                              onChange={onChangeHandler}
                              type="text"
                              className="otp-input bg-light-green-gray text-center"
                              id="3"
                              maxlength="1"
                              onkeyup="clickEvent(this,'fourth')"
                              name="third"
                              required
                            />

                            <input
                              onChange={onChangeHandler}
                              type="text"
                              className="otp-input bg-light-green-gray text-center"
                              id="4"
                              maxlength="1"
                              onkeyup="clickEvent(this,'fifth')"
                              name="fourth"
                              required
                            />

                            <input
                              onChange={onChangeHandler}
                              type="text"
                              className="otp-input bg-light-green-gray text-center"
                              id="5"
                              maxlength="1"
                              onkeyup="clickEvent(this,'sixth')"
                              name="fifth"
                              required
                            />

                            <input
                              onChange={onChangeHandler}
                              type="text"
                              className="otp-input bg-light-green-gray text-center"
                              id="6"
                              maxlength="1"
                              name="sixth"
                              required
                            />
                          </div>

                          {get_otp_text}

                          <div className="w-75 mx-auto my-3">
                            <button
                              onClick={submitOtp}
                              className="btn btn-lg w-100 text-uppercase text-white fw-800 border-radius-1 letter-spacing-3 blue-gradient-bg-1 border-0 rounded-pill fs-16px w-50"
                            >
                              Verify
                            </button>
                          </div>

                          <p className="text-green fs-14px">
                            *NOTE: One Time Password will expire after 10
                            minutes
                          </p>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

OtpForm.defaultProps = {
  phone_number: "",
};

OtpForm.propTypes = {
  verifyOtp: PropTypes.func,
  phone_number: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default OtpForm;
