import React, {useState} from "react";
import {Button, Form, Modal, notification} from 'antd';
import axios from "axios";
import getConfig from "config";
import SuccessMsgIcon from "../../../assets/img/icons/success-message-icon.svg";

export default function ContactForm() {
    const onFinish = (values) => {

        const payload = JSON.stringify({
            name: values.name,
            surname: values.surname,
            email: values.email,
            message: values.message,
        });
        const headers = {
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
        };

        if (values.name === "" || values.surname === "" || values.email === "" || values.message === "") {
            // setButtonLoad(false);
            notification.error({
                message: "Error",
                description: "Fiels cannot be empty",
            });
        } else {
            axios
                .post(contactUrl, payload, headers)
                .then((res) => {
                    // setButtonLoad(false);
                    if (res.status === 200) {
                        setIsModalVisible(true);

                        localStorage.setItem(
                            "userInfo",
                            JSON.stringify({
                                loginToken: res.data.access,
                                isLoggedIn: true,
                            })
                        );
                    }
                })
                .catch((error) => {
                    // setButtonLoad(false);
                    setTimeout(() => {
                    }, 1000);
                    notification.error({
                        message: "Error",
                        description: error.response.data.detail,
                        className: "custom-class",
                        style: {
                            top: 70,
                        },
                    });
                });
        }
    };

    // const [isButtonLoading, setButtonLoad] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const config = getConfig();

    const contactUrl = `${config.api.base_url}contact/`;

    const handleCancel = () => {
        window.location.href = "/";
    };

    return (
        <>
            <Modal
                style={{ marginTop: "-20px" }}
                footer={false}
                closable={true}
                onCancel={handleCancel}
                visible={isModalVisible}
                width={750}
            >
                <div>
                    <div className="card-body lp-buttons-bg p-0 text-white">
                        <div className="text-center py-5">
                            <div className="mb-4">
                                <img src={SuccessMsgIcon} alt="" width={100}/>
                            </div>
                            <div className="mb-5">
                                <h2 className="text-white fw-800">Your Message was successfully sent</h2>
                            </div>
                            <Button onClick={handleCancel} type="primary" key="console" className="blue-gradient-bg-1 border-0 text-uppercase rounded-pill px-5 text-white fw-800 letter-spacing-3 box-shadow-btn">
                                Home
                            </Button>,
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="contact-us-header-bg py-5">
                <div className="container">
                    <Form
                        onFinish={onFinish}
                    >
                        <div className="row">
                            <div className="col-lg-6"/>

                            <div className="col-12 col-lg-6">
                                <div className="text-center">
                                    <h1 className="text-green-2 fw-800">
                                        Getting in touch is easy!
                                    </h1>
                                </div>
                                <div className="card border-0 box-shadow-1 border-radius-1 p-2">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="mb-3">
                                                    <label
                                                        className="form-label text-green-2 fw-700"
                                                    >
                                                        First Name
                                                    </label>

                                                    <Form.Item
                                                        name="name"
                                                        rules={[{required: true, message: 'Please input your Firstname!'}]}
                                                    >
                                                        <input
                                                            id="password"
                                                            type="text"
                                                            className="
                                                          form-control
                                                          border-2-green border-radius-1
                                                          bg-transparent
                                                        "
                                                            placeholder="Enter Firstname"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="mb-3">
                                                    <label
                                                        className="form-label text-green-2 fw-700"
                                                    >
                                                        Lastname
                                                    </label>

                                                    <Form.Item
                                                        name="surname"
                                                        rules={[{required: true, message: 'Please input your Lastname!'}]}
                                                    >
                                                        <input
                                                            id="password"
                                                            type="text"
                                                            className="
                                                          form-control
                                                          border-2-green border-radius-1
                                                          bg-transparent
                                                        "
                                                            placeholder="Enter Lastname"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label text-green-2 fw-700">
                                                        Email Address
                                                    </label>

                                                    <Form.Item
                                                        name="email"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your Email Address!'
                                                        }]}
                                                    >
                                                        <input
                                                            type="email"
                                                            className="
                                                          form-control
                                                          border-2-green border-radius-1
                                                          bg-transparent
                                                        "
                                                            placeholder="Enter Email Address"
                                                        />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label text-green-2 fw-700">
                                                Write to us
                                            </label>
                                            <Form.Item
                                                name="message"
                                                rules={[{required: true, message: 'Please input your Message!'}]}
                                            >
                                                <div className="mb-3">
                                                  <textarea
                                                      className="
                                                        form-control
                                                        border-2-green border-radius-1
                                                        bg-transparent
                                                        text-green
                                                      "
                                                      rows="3"
                                                  />
                                                </div>
                                            </Form.Item>

                                        </div>

                                        <button
                                            type="submit"
                                            className="
                                            btn btn-lg
                                            w-100
                                            text-uppercase
                                            alert-dismissible
                                            fw-800
                                            rounded-pill
                                            letter-spacing-3
                                            blue-gradient-bg-1
                                            border-0
                                            text-white
                                          "
                                        >
                                            Send Message
                                            <img
                                                src="assets/img/icons/send-icon.svg"
                                                alt=""
                                                width="22"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}
