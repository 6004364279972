import { createRoutine } from 'redux-saga-routines';

const getProductsRoutine = createRoutine('GET_PRODUCTS');

export default getProductsRoutine;

export const addToCartRoutine = createRoutine('ADD_TO_CART');
export const getCategoriesRoutine = createRoutine('GET_CATEGORIES');
export const removeFromCartRoutine = createRoutine('REMOVE_FROM_CART');
export const checkoutRoutine = createRoutine('CHECK_OUT');
export const cartCounterRoutine = createRoutine('CART_COUNT');
export const updateCartRoutine = createRoutine('GET_CART_FROM_LOCALSTORAGE');
