import React from 'react'
import RegionsBillersSlider from 'components/regions/slider'
import { Link } from 'react-router-dom'

const Regions = props => {
    return (
        <div className="regions-bg-color py-5 text-center text-md-start">
            <div className="container">
                <div className="d-md-flex align-items-center justify-content-between">
                    <h4 className="text-green">Regional Footprint</h4>

                    <div className="horizontal-line-1-5 bg-green-2 w-50 d-none d-md-inline-block" />
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <div>
                            <h4 className="mw-cs-title-name mb-4">
                                Join our African <br />
                                network of billers
                            </h4>

                            <div>
                                <Link to="/contact" className="btn btn-lg fw-800 blue-gradient-bg-1 text-white border-0 rounded-pill box-shadow-btn px-4">
                                    Contact Us
                                    <img src="assets/img/icons/double-chevron-right-white.svg" alt="" className="img-fluid ms-2"
                                        width={14}
                                    />
                                </Link>
                            </div>

                            <div>
                                <div className="mt-5 position-relative">
                                    <div className="vertical-line-3 orange-gradient-bg-2 position-absolute top-0 h-100" />

                                    <p className="text-green ms-3">
                                        <span className="text-green-3 fw-800">Pay bills</span> in 5 countries <br />
                                        across Africa.
                                    </p>
                                </div>

                                <RegionsBillersSlider />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <img src="assets/img/regions/africa.svg" alt="" className="img-fluid mt-5 d-none d-md-inline-block" width={400} />
                    </div>
                </div>

            </div>
        </div>
    )
}

Regions.propTypes = {

}

export default Regions
