import React, { useEffect } from "react";
import { connect } from "react-redux";

import Navigation from "components/main/navigation";
import PaymentDetails from "components/payments/paymentDetails";
import PaymentMethod from "components/payments/paymentMethod";

import getPaymentTypeRoutine from "./routines";

function Payments(props) {
  const { payment_types, reloadRequested } = props;


  const getPaymentPayload = () => {
    const paymentInfo = localStorage.getItem("paymentPayload");

    if (paymentInfo !== null) {
      const infoPayment = JSON.parse(paymentInfo).payload;
      return infoPayment;
    }
    return [];
  };

  let paymentPayload = getPaymentPayload();


  useEffect(() => {
    const { getPaymentTypes } = props;

    if (paymentPayload) {
      if (Object.keys(paymentPayload).length > 0) {
        getPaymentTypes(paymentPayload.currency);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadRequested]);

  return (
    <>
      <Navigation />

      <section id="cards">
        <div className="wallet-cards-bg py-5">
          <div className="container">
            <div className="card border-0 box-shadow-1">
              <div className="card-header orange-gradient-bg-1 border-0 py-4 px-md-5 d-flex align-items-center justify-content-between">
                <h3 className="text-white fw-800">
                  <img
                    src="./assets/img/icons/payments-icon.svg"
                    alt=""
                    width="50"
                    className="me-3"
                  />
                  Payments
                </h3>
              </div>
              <div className="card-body px-lg-5 pb-5 light-grey-bg">
                <div className="row">
                  <PaymentDetails paymentPayload={paymentPayload} />

                  <PaymentMethod
                    paymentPayload={paymentPayload}
                    payment_types={payment_types}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Payments.defaultProps = {
  paymentPayload: {},
};

Payments.propTypes = {};

function mapStateToProps(state) {
  return {
    reloadRequested: state.getIn(["main", "paymentPayload"]),
    payment_types: state.getIn(["payments", "payment_types"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPaymentTypes: (currency) =>
      dispatch(getPaymentTypeRoutine.trigger({ currency })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
