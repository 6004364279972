import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { Form, Popover } from "antd";
// import { Link } from "react-router-dom";

const Signup = (props) => {

  const content = (
    <div>
      <p>Password should contain more than 8 characters</p>
      <p>Password should have atleast one numeral</p>
      <p>Password should contain atleast one uppercase</p>
      <p>Password should contain one special character</p>
    </div>
  );

  // const countries = ["zw"];
  return (
    <>
      {/* {emptyMessage} */}
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="">
            <label for="first_name" class="form-label text-green-2 fw-700">
              First Name
            </label>
            <Form.Item
              name="first_name"
              rules={[{ required: true, message: "Enter firstname!" }]}
            >
              <input
                id="first_name"
                type="first_name"
                name="first_name"
                class="form-control shadow-none border-2-green bg-transparent"
                placeholder="Enter Firstname"
              />
            </Form.Item>
          </div>
        </div>

        <div class="col-12 col-sm-6">
          <div class="">
            <label for="last_name" class="form-label text-green-2 fw-700">
              Last Name
            </label>
            <Form.Item
              name="last_name"
              rules={[{ required: true, message: "Enter last name!" }]}
            >
              <input
                id="last_name"
                type="last_name"
                name="last_name"
                class="form-control shadow-none border-2-green bg-transparent"
                placeholder="Enter Lastname"
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label text-green-2 fw-700">Email Address</label>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Enter email address!" }]}
            >
              <input
                type="email"
                class="form-control shadow-none border-2-green bg-transparent"
                placeholder="Enter Email Address"
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label text-green-2 fw-700">Phone Number</label>
            <Form.Item
              name="phone_number"
              rules={[{ required: true, message: "Enter phone number!" }]}
            >
              <PhoneInput
                name="phone_number"
                className="form-control shadow-none border-2-green"
                country={"zw"}
                // onlyCountries={countries}
                placeholder="263779525756"
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="mb-3">
            <Popover
              content={content}
              title="Password Requirements"
              trigger="hover"
            >
              <label class="form-label text-green-2 fw-700">Password</label>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Enter password" }]}
              >
                <input
                  name="password"
                  type="password"
                  class="form-control shadow-none border-2-green bg-transparent"
                  placeholder="Enter Password"
                />
              </Form.Item>
            </Popover>
          </div>
        </div>

        <div class="col-12 col-sm-6">
          <div class="mb-3">
            <Popover
              content={content}
              title="Password Requirements"
              trigger="hover"
            >
              <label class="form-label text-green-2 fw-700">
                Confirm Password
              </label>
              <Form.Item
                name="password2"
                rules={[{ required: true, message: "Enter password!" }]}
              >
                <input
                  name="password2"
                  type="password"
                  class="form-control shadow-none border-2-green bg-transparent"
                  placeholder="Repeat Password"
                />
              </Form.Item>
            </Popover>
          </div>
        </div>
      </div>

      <div class="text-center mb-3">
        <small class="fs-12px">
          Already have an account?{" "}
          <a href="/login" class="fw-800 text-green-2 text-decoration-none">
            Login
          </a>
        </small>
      </div>
      <button
        type="submit"
        // onClick={onSubmit}
        class="btn btn-warning w-100 text-uppercase
                    text-white fw-800 letter-spacing-3 orange-gradient-bg-1"
      >
        Sign Up
      </button>
    </>
  );
};

Signup.defaultProps = {};

Signup.propTypes = {
  errorMessage: PropTypes.string,
  registerUser: PropTypes.func,
  removeMessages: PropTypes.func,
};

export default Signup;
