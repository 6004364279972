import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'

const HeaderTitles = () => {
    return (
        <>
            <li className='nav-item ms-lg-3'>
                <ScrollLink
                    className='nav-link nav-li-a'
                    to='home'
                    spy={true}
                    smooth={true}
                    offset={-72}
                    duration={200}
                >
                    Home
                </ScrollLink>
            </li>

            <li className='nav-item ms-lg-3'>
                <ScrollLink
                    className='nav-link nav-li-a'
                    to='howItWorks'
                    spy={true}
                    smooth={true}
                    offset={-72}
                    duration={200}
                >
                    How it works
                </ScrollLink>
            </li>

            <li className='nav-item ms-lg-3'>
                <ScrollLink
                    className='nav-link nav-li-a'
                    to='locations'
                    spy={true}
                    smooth={true}
                    offset={-72}
                    duration={200}
                >
                    Locations
                </ScrollLink>
            </li>

            <li className='nav-item ms-lg-3'>
                <ScrollLink
                    className='nav-link nav-li-a'
                    to='charges'
                    spy={true}
                    smooth={true}
                    offset={-72}
                    duration={200}
                >
                    Charges
                </ScrollLink>
            </li>

            <li className='nav-item ms-lg-3'>
                <ScrollLink
                    className='nav-link nav-li-a'
                    to='contactUs'
                    spy={true}
                    smooth={true}
                    offset={-72}
                    duration={200}
                >
                    Contact Us
                </ScrollLink>
            </li>

            {/* <li className='nav-item ms-lg-3'>
                <a className='nav-link nav-li-a' href='https://api.tumai.to/'>
                    Api Documentation
                </a>
            </li> */}
        </>
    )
}

export default HeaderTitles
