import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IndustryItems from "components/shop/categories/industry-items";
import { Empty, Spin } from "antd";
import getConfig from "config";
import axios from "axios";

const Main = (props) => {
  const config = getConfig();

  const [industries, setProducts] = useState([]);
  const [isIndustryLoading, setIsIndustryLoading] = useState(true);

  const getIndustries = () => {
    const industryUrl = `${config.api.base_url}merchant/industries/`;
    axios
      .get(industryUrl)
      .then((res) => {
        if (res.status === 200) {
          setIsIndustryLoading(false);
          setProducts(res.data.data);
        }
      })
      .catch((error) => {
        setIsIndustryLoading(false);
        setProducts([]);
      });
  };

  useEffect(() => {
    getIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toRender;
  if (industries.length > 0 && isIndustryLoading === false) {
    toRender = (
      <>
        {industries.map((item) => (
          <IndustryItems
            key={item.id}
            categroryID={item.id}
            categoryName={item.name}
            picture_1={item.image}
            picture_2={item.picture_2}
          />
        ))}
      </>
    );
  } else if (isIndustryLoading === false) {
    toRender = (
      <>
        <Empty
          imageStyle={{
            height: 120,
          }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          description={
            <h6>Sorry there are no industries found at the moment</h6>
          }
        />
      </>
    );
  }

  return (
    <>
      <section id="categories">
        <div className="wallet-cards-bg">
          <div className="container py-5">
            <div className="d-flex align-items-center justify-content-between text-center">
              <div className="horizontal-line-1 bg-green-2 w-25 d-none d-md-inline"></div>

              <h2 className="fw-800 text-green-2">Browse Industries</h2>

              <div className="horizontal-line-1 bg-green-2 w-25 d-none d-md-inline"></div>
            </div>

            <div className="row text-center">
              {toRender}
              <Spin style={{ marginTop: "50px" }} spinning={isIndustryLoading} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Main.defaultProps = {
  industries: [],
};

Main.propTypes = {
  industries: PropTypes.array.isRequired,
};

export default Main;
