import React from "react";
import PropTypes from "prop-types";

const Transaction = (props) => {
  const { date, amount, transaction_type, reference } = props;
  return (
    <>
      <tr>
        <td>
          <h6 class="text-green fw-700">{date}</h6>
        </td>
        <td>
          <h6 class="text-green-2 fw-800 mb-0">{reference}</h6>
          <small class="text-muted">{transaction_type}</small>
        </td>
        <td>
          <h6 class="text-green-2 fw-800 mb-0 float-end">{amount}</h6>
        </td>
      </tr>
    </>
  );
};

Transaction.defaultProps = {};

Transaction.propTypes = {
  date: PropTypes.string,
  amount: PropTypes.string,
  reference: PropTypes.string,
  transaction_type: PropTypes.string,
};

export default Transaction;
