import React from "react";
import PropTypes from "prop-types";
import Navigation from "components/main/navigation";
import Typical from 'react-typical';
import { Link } from "react-router-dom";

const Header = (props) => {
  const { header_titles } = props;
  return (
    <>
      <header>
        <Navigation header_titles={header_titles} />

        <div className="lp-header-bg">
          <div className="container">
            <div className="lp-hero-content d-lg-flex align-items-center py-5">
              <img
                src="./assets/img/logo.svg"
                alt=""
                className="img-fluid lp-header-logo d-none d-lg-inline-block"
                width="200"
              />

              <div className="lp-hero-right-content ms-lg-5 text-center text-sm-start">
                <h1 className="lp-header-text mb-4">
                  Send value to {" "}
                  <Typical
                    className="d-inline text-orange-3"
                    steps={[
                      'Zimbabwe',
                      5000,
                      'Botswana',
                      5000,
                      'Kenya',
                      5000,
                      'Malawi',
                      5000,
                      'South Africa',
                      5000,
                      'Zambia',
                      5000,
                      'Mozambique',
                      5000,
                      'Lesotho',
                      5000,
                      'Namibia',
                      5000,
                    ]}
                    loop={Infinity}
                  />

                  <br />

                  from anywhere in the World
                </h1>

                <Link
                  to="/offerings"
                  className="btn blue-gradient-bg-1 fw-800 rounded-pill border-0 text-white px-5 py-2 box-shadow-btn text-uppercase letter-spacing-3"
                >
                  Learn More
                  <img
                    src="./assets/img/icons/white-chevron-right.svg"
                    alt=""
                    width="10"
                    className="ms-2"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.defaultProps = {};

Header.propTypes = {
  header_titles: PropTypes.object,
};

export default Header;
