import React from "react";
import PropTypes from "prop-types";
import Form from "./form";
import Links from "./links";

const SendMoney = (props) => {
  const {
    sendMoney,
    showErrorMessage,
    lookup,
    successMessage,
    errorMessage,
    balance,
    foundUser,
    showSuccessMessage,
    paymentUrl,
  } = props;
  return (
    <>
      <section id="cards">
        <div className="wallet-cards-bg py-5">
          <div className="container">
            <div className="card border-0 box-shadow-1">
              <div className="card-body p-5">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/img/icons/wallet/load-balance-icon.svg"
                    alt=""
                    className="img-fluid"
                    width="70"
                  />
                  <h3 className="fw-800 text-green-2 ms-3">Load Balance</h3>
                  <div className="mw-cs-title-line w-60 d-none d-lg-inline-block bg-green-2 ms-auto"></div>
                </div>
                <div className="row">
                  <Form
                    successMessage={successMessage}
                    showErrorMessage={showErrorMessage}
                    errorMessage={errorMessage}
                    foundUser={foundUser}
                    lookup={lookup}
                    balance={balance}
                    sendMoney={sendMoney}
                    showSuccessMessage={showSuccessMessage}
                    paymentUrl={paymentUrl}
                  />
                  <Links />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

SendMoney.defaultProps = {};

SendMoney.propTypes = {
  sendMoney: PropTypes.func.isRequired,
  makePayment: PropTypes.func.isRequired,
  removeMessages: PropTypes.func.isRequired,
  lookup: PropTypes.func.isRequired,
  balance: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.array,
  foundUser: PropTypes.string,
  foundMerchant: PropTypes.string,
  showSuccessMessage: PropTypes.bool,
  showErrorMessage: PropTypes.string,
  paymentUrl: PropTypes.string,
};

export default SendMoney;
