import React from "react";

export default function Buttons() {
  return (
    <section id="buttons">
      <div className="lp-buttons-bg">
        <div className="container">
          <div className="d-lg-flex justify-content-between align-items-center pt-3 pb-5 text-center">
            <div className="pt-5">
              <a href="/wallet" className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="assets/img/icons/wallet/ma-home.svg"
                  alt=""
                  width="80"
                />
                <h6 className="text-white fw-800 mt-3">
                  Home
                  <img
                    src="assets/img/icons/wallet/ma-chevron-right-orange.svg"
                    alt=""
                    width="9"
                  />
                </h6>
              </a>
            </div>

            <div className="pt-5">
              <a href="/send-money" className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="assets/img/icons/wallet/ma-send-money.svg"
                  alt=""
                  width="80"
                />
                <h6 className="text-white fw-800 mt-3">
                  Send Money
                  <img
                    src="assets/img/icons/wallet/ma-chevron-right-orange.svg"
                    alt=""
                    width="9"
                  />
                </h6>
              </a>
            </div>

            <div className="pt-5">
              <a href="load-balance" className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="assets/img/icons/wallet/ma-load-balance.svg"
                  alt=""
                  width="80"
                />
                <h6 className="text-white fw-800 mt-3">
                  Load Balance
                  <img
                    src="assets/img/icons/wallet/ma-chevron-right-orange.svg"
                    alt=""
                    width="9"
                  />
                </h6>
              </a>
            </div>

            <div className="pt-5">
              <a href="make-payment" className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="assets/img/icons/wallet/ma-make-payment.svg"
                  alt=""
                  width="80"
                />
                <h6 className="text-white fw-800 mt-3">
                  Make Payment
                  <img
                    src="assets/img/icons/wallet/ma-chevron-right-orange.svg"
                    alt=""
                    width="9"
                  />
                </h6>
              </a>
            </div>

            <div className="pt-5">
              <a href className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="assets/img/icons/wallet/ma-beneficiaries.svg"
                  alt=""
                  width="80"
                />
                <h6 className="text-white fw-800 mt-3">
                  Beneficiaries
                  <img
                    src="assets/img/icons/wallet/ma-chevron-right-orange.svg"
                    alt=""
                    width="9"
                  />
                </h6>
              </a>
            </div>

            <div className="pt-5">
              <a href className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="assets/img/icons/wallet/ma-new-statement.svg"
                  alt=""
                  width="80"
                />
                <h6 className="text-white fw-800 mt-3">
                  New Statement
                  <img
                    src="assets/img/icons/wallet/ma-chevron-right-orange.svg"
                    alt=""
                    width="9"
                  />
                </h6>
              </a>
            </div>

            <div className="pt-5">
              <a href className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="assets/img/icons/wallet/ma-card-services.svg"
                  alt=""
                  width="80"
                />
                <h6 className="text-white fw-800 mt-3">
                  Card Services
                  <img
                    src="assets/img/icons/wallet/ma-chevron-right-orange.svg"
                    alt=""
                    width="9"
                  />
                </h6>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
