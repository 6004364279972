import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import getConfig from "config";

const MerchantItem = (props) => {
  // const config = getConfig();
  const { logo, merchant_id, merchant_name } = props;

  // var logoUrl = logo.replace("http://0.0.0.0", `${config.api.ip_address}`);

  const storeUrl = `/store/${merchant_id}/${merchant_name}/all`;

  return (
    <>
      <Link to={storeUrl}>
        <div className="card border-0 box-shadow-4 mx-lg-3 mt-5 height-150">
          <div className="card-body justify-content-center light-grey-bg d-flex align-items-center px-5">
            <Link to={storeUrl} className="mx-5 text-decoration-none">
              <img src={logo} alt="" width={200} className="text-decoration-none img-fluid" />
              <img src="assets/img/icons/double-chevron-right-green.svg" alt="" width={30} className="ms-3 img-fluid d-none d-sm-inline" />
            </Link>
          </div>
        </div>
      </Link>
    </>
  );
};

MerchantItem.defaultProps = {};

MerchantItem.propTypes = {
  logo: PropTypes.string,
  merchant_id: PropTypes.string,
  merchant_name: PropTypes.string,
};

export default MerchantItem;
