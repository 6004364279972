import React from "react";
import Banner from "components/banner/main-banner";
import { Popover } from "antd";
import { Link } from "react-router-dom";

const About = (props) => {
  return (
    <>
      <section id="about">
        <div className="position-relative">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mt-5">
              <h4 className="mw-cs-title-name">What is Tumai?</h4>

              <div className="mw-cs-title-line w-50 d-none d-md-inline-block"></div>
            </div>

            <p className="lp-about-message-1 text-align-justify">
              Tumai is a cutting-edge service which assists people living abroad
              with making direct payments for goods and services on behalf of
              their loved ones in Africa. Tumai is designed to offer convenience
              to both the diaspora community and the families they support.
            </p>

            <Banner />

            <div className="d-flex align-items-center justify-content-between mt-5">
              <h4 className="mw-cs-title-name">What does Tumai do?</h4>

              <div className="mw-cs-title-line w-50 d-none d-md-inline-block"></div>
            </div>

            <p className="lp-about-message-1 mb-5 text-align-justify">
              Tumai allows customers living in the diaspora to make payments on
              behalf of their families for goods, services and utilities in
              Africa. Instead of a customer sending money for a good or service
              to their relative, Tumai facilitates direct payment of the service
              from the customer’s location.
            </p>

            <div className="what-does-tumai-do-btns d-md-flex justify-content-between w-75 mx-auto text-center">
              <div className="wdtdb-item">
                <Link to="/login">
                  <img
                    src="./assets/img/icons/lp-login.svg"
                    alt=""
                    className="img-fluid p-2 p-lg-0"
                    width="180"
                  />
                </Link>
              </div>

              <div className="wdtdb-item">
                <Popover content="Coming soon" title="">
                  <a href>
                    <img
                      src="./assets/img/icons/lp-send.svg"
                      alt=""
                      className="img-fluid p-2 p-lg-0"
                      width="180"
                    />
                  </a>
                </Popover>
              </div>

              <div className="wdtdb-item">
                <Popover content="Coming soon" title="">
                  <a href>
                    <img
                      src="./assets/img/icons/lp-receive.svg"
                      alt=""
                      className="img-fluid p-2 p-lg-0"
                      width="180"
                    />
                  </a>
                </Popover>
              </div>
            </div>
          </div>

          <div className="lp-wdtd-line d-none d-md-block"></div>
        </div>
      </section>
    </>
  );
};

About.defaultProps = {};

About.propTypes = {};

export default About;
