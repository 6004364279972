import React from "react";
import appStore from "assets/img/footer/app-store.svg";
import playStore from "assets/img/footer/play-store.svg";
import facebook from "assets/img/icons/social/facebook-icon.svg";
import twitter from "assets/img/icons/social/twitter-icon.svg";
import linkedin from "assets/img/icons/social/linkedin-icon.svg";
import instagram from "assets/img/icons/social/instagram-icon.svg";

export default function Footer() {
  return (
    <>
      <div className="text-center text-lg-start d-none d-lg-inline">
        <div>
          <h5 className="footer-follow-us">Quick Links</h5>
          <ul className="footer-follow-us-ul">
            <li className="footer-ql-li">
              <a href="/contact">Contact us</a>
            </li>
            <li className="footer-ql-li">
              <a href="/offerings">About us</a>
            </li>
            {/* <li className="footer-ql-li">
              <a href>
                FAQs
              </a>
            </li> */}
          </ul>
        </div>
        <div className="pt-3">
          {/* <a
            href
            className="btn border-0 blue-gradient-bg-1 text-white box-shadow-btn fw-700 rounded-pill px-4 py-2"
          >
            Show on our app
          </a> */}
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="footer-app-store-link">
            <img src={appStore} alt="" className="img-fluid" width={150} />
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="footer-play-store-link mt-2">
            <img src={playStore} alt="" className="img-fluid" width={150} />
          </a>
        </div>
        <div className="pt-4">
          <h5 className="footer-follow-us">Follow us</h5>
          <div className="mb-3">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="footer-social-icon">
              <img src={facebook} alt="" className="img-fluid" width={35} />
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="footer-social-icon ms-2">
              <img src={instagram} alt="" className="img-fluid" width={35} />
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="footer-social-icon ms-2">
              <img src={linkedin} alt="" className="img-fluid" width={35} />
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="footer-social-icon ms-2">
              <img src={twitter} alt="" className="img-fluid" width={35} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
