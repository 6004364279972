import React, { useState } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

import { Form, Modal } from "antd";
const MakePaymentForm = (props) => {
  const { sendMoney, paymentUrl } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isIframeLoading, setIframeLoad] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = (values) => {
    showModal();
    setIframeLoad(true);
    sendMoney(values.amount, "load", values.pin);
  };

  const closeLoader = () => {
    if (paymentUrl) {
      setIframeLoad(false);
    }
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div class="card-body mw-card-services-card-bg">
            <div class="d-flex align-items-center mw-card-services-title">
              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <div class="card mt-3 border-0">
              <Spin
                spinning={isIframeLoading}
                tip="Loading..."
                size="large"
                style={{ marginTop: "30%" }}
              />{" "}
              <iframe
                onLoad={closeLoader}
                height="500"
                title="paymenturl"
                style={{ width: "100%" }}
                src={paymentUrl}
              />
              <div class="card-footer  pt-1 pb-4 light-grey-bg border-0"></div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="col-sm-12 col-lg-6">
        <div className="card mt-3 border-0">
          <div class="card-body px-5 pt-4 light-green-gray-bg">
            <h5 class="fw-700 text-green-2">
              {" "}
              Load Money into your Tumai wallet.
            </h5>
          </div>
          <Form name="basic" onFinish={onFinish} autoComplete="off">
            <div className="card-footer px-5 pt-1 pb-4 light-grey-bg border-0">
              <div className="my-3">
                <label className="fs-16px mb-2 text-green-2 fw-600">
                  Amount*
                </label>
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input amount!",
                    },
                  ]}
                >
                  <input
                    type="number"
                    step=".01"
                    className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                    placeholder="10.00"
                  />
                </Form.Item>
              </div>

              <button
                className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                type="primary"
                htmlType="submit"
              >
                Proceed
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

MakePaymentForm.defaultProps = {
  balance: 0,
};

MakePaymentForm.propTypes = {
  sendMoney: PropTypes.func.isRequired,
  closePages: PropTypes.func.isRequired,
  lookup: PropTypes.func.isRequired,
  removeMessageWallet: PropTypes.func,
  balance: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  pageName: PropTypes.string,
  usage: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  showErrorMessage: PropTypes.string,
  paymentUrl: PropTypes.string,
  foundUser: PropTypes.string,
  showSuccessMessage: PropTypes.bool,
};

export default MakePaymentForm;
