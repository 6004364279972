import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Merchant from "components/shop/merchant/merchantitem";
import { Empty } from "antd";
import getConfig from "config";
import axios from "axios";
import { Spin } from "antd";
import {  useParams } from "react-router-dom";

const Container = (props) => {
  const config = getConfig();
  const [merchants, setMerchants] = useState([]);
  const [isMerchantLoading, setIsMerchantLoading] = useState(true);

  const { industryName } = useParams();

  const getMerchants = () => {
    const merchantUrl = `${config.api.base_url}merchant/industry/${industryName}/`;
    axios
      .get(merchantUrl)
      .then((res) => {
        if (res.status === 200) {
          setIsMerchantLoading(false);
          setMerchants(res.data.data);
        }
      })
      .catch((error) => {
        setIsMerchantLoading(false);
        setMerchants([]);
      });
  };

  useEffect(() => {
    getMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toRender;
  let toRenderEmpty;
  if (merchants.length > 0 && isMerchantLoading === false) {
    toRender = (
      <>
        {merchants.map((item) => {
          if (merchants.length > 0) {
            return (
              <Merchant
                key={item.ID}
                merchant_id={item.ID}
                merchant_name={item.name}
                logo={item.logo}
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  else if (isMerchantLoading === false) {
    toRenderEmpty = (
      <>
        <br /> <br />{" "}
        <Empty
          imageStyle={{
            height: 120,
          }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          description={<h6>Sorry there are no merchants at the moment</h6>}
        />
      </>
    );
  }
  return (
    <>
      <section>
        <div className="wallet-cards-bg py-5">
          <div className="container">
            <div className="d-flex justify-content-around align-items-center">
              <div className="horizontal-line bg-green-2 w-25 d-none d-lg-inline" />
              <h3 className="text-green-2 fw-700">
                Select a merchant to continue
              </h3>
              <div className="horizontal-line bg-green-2 w-25 d-none d-lg-inline" />
            </div>
            {toRenderEmpty}
            <div className="d-lg-flex mb-4 justify-content-around flex-wrap">
              {toRender}
              <Spin style={{ marginTop: "50px" }} spinning={isMerchantLoading} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Container.defaultProps = {
  merchants: [],
};

Container.propTypes = {
  merchants: PropTypes.array,
};

export default Container;
