import getConfig from 'config'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Spin, Tabs, notification } from 'antd'
import axios from 'axios'
import { useHistory } from 'react-router'

const { TabPane } = Tabs

const ServiceItems = (props) => {
    const history = useHistory()

    const {
        image,
        fields,
        extraFields,
        hasExtraField,
        modalTitle,
        modalButton,
        setPaymentPayload,
        setConvertedAmount,
        dstvAmount,
        paymentDetails,
        converted_amount,
        onUsdCurrencyHandlerChange,
        ratesOptions,
        setDstvAmount
    } = props

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [verificationDetails, setVerificationDetails] = useState({})
    const [telcoData, setTelcoData] = useState([])
    const [verifying, setVerifying] = useState(false)
    const [isTelcoModalVisible, setTelcoModal] = useState(false)
    const [nyaradzoModal, setNyaradzoModal] = useState(false)
    const [verificationLoader, setVerificationLoader] = useState(false)
    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [info, setInfo] = useState({})
    const [amount, setAmount] = useState(0)
    const [count, setCount] = useState(1)

    const [nyaradzoMonthlyPremiumAmount, setNyaradzoMonthlyPremiumAmount] =
        useState(0)
    const [nyaradzoMonthlyAmount, setNyaradzoMonthlyAmount] = useState(0)
    const [state, setState] = useState({
        activeRow: {}
    })
    const showModal = () => {
        setIsModalVisible(true)
    }

    const activeRow = state

    const [form] = Form.useForm()

    const handleCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
        setConvertedAmount()
        retryVerify()
        setTelcoModal(false)
    }

    const telcoVerficationData = telcoData[0]
    let telcoCustomerName
    let telcoExtras
    if (telcoVerficationData) {
        telcoCustomerName = telcoVerficationData.customerName
        telcoExtras = telcoVerficationData.customerExtraDetails
    }

    let telcoCustomerid
    let telcoSubscribedPackages = []
    if (telcoExtras) {
        telcoSubscribedPackages = telcoExtras.subscribed_packages
        telcoCustomerid = telcoExtras.customerid
    }

    const config = getConfig()
    const verifyUrl = `${config.api.base_url}verify/account/`

    const transactionTypes = [
        '001',
        '0010',
        '002',
        '003',
        '0020',
        '0030',
        '006',
        '005',
        '007',
        '015',
        '013'
    ]

    const headers = {
        headers: {
            accept: 'application/json'
        }
    }

    const serviceMonitor = (transactionType) => {
        setVerificationLoader(true)
        const services = [
            '001',
            '002',
            '003',
            '005',
            '006',
            '0010',
            '0020',
            '0030'
        ]

        if (services.includes(transactionType)) {
            const serviceMonitorUrl = `${config.api.base_url}service-monitor/`

            axios
                .get(serviceMonitorUrl, headers)
                .then((res) => {
                    if (transactionType === '0010') {
                        transactionType = '001'
                    }
                    if (transactionType === '0020') {
                        transactionType = '002'
                    }
                    if (transactionType === '0030') {
                        transactionType = '003'
                    }

                    if (res.status === 200) {
                        if (res.data.data.services[transactionType]) {
                            if (
                                res.data.data.services[transactionType].status
                            ) {
                                history.push({
                                    pathname: '/payments'
                                })
                            } else {
                                setVerifying(false)
                                setVerificationLoader(false)
                                notification.error({
                                    message: 'Error',
                                    description:
                                        'This service is currently not available',
                                    className: 'custom-class',
                                    style: {
                                        top: 70
                                    }
                                })
                            }
                        } else {
                            setVerifying(false)
                            setVerificationLoader(false)
                            notification.error({
                                message: 'Error',
                                description:
                                    'This service is currently not available',
                                className: 'custom-class',
                                style: {
                                    top: 70
                                }
                            })
                        }
                    }
                })
                .catch((error) => {
                    setVerifying(false)
                    setVerificationLoader(false)
                    notification.error({
                        message: 'Error',
                        description: 'This service is currently not available',
                        className: 'custom-class',
                        style: {
                            top: 70
                        }
                    })
                })
        } else {
            history.push({
                pathname: '/payments'
            })
        }
    }

    const onFinish = (values) => {
        setVerificationLoader(true)
        if (dstvAmount) {
            values.amount = dstvAmount
        }

        setAmount(values.amount)

        values.paymentDetails = paymentDetails

        const getUsdConvertedRate = () => {
            let rates = values.paymentDetails['rates']
            let currency = values.currency
            var search = rates.filter((obj) => obj.to_currency === currency)
            if (currency === 'usd') {
                return 1
            } else {
                if (currency) {
                    return search[0].converted_rate
                } else {
                    return 0
                }
            }
        }

        if (values.amount) {
            values.original_amount = parseFloat(values.amount)
            values.amount = values.amount * getUsdConvertedRate()
        }
        values.extras = {}
        values.billpaymentName = modalTitle

        /* Setting Transaction type code */
        if (modalTitle === 'DSTV') {
            values.transactionType = '006'
            values.billerId = 'DSTV'

            values.extras = {
                phoneNumber: values.phone_number,
                bouquet: values.bouquet,
                addons: values.addons,
                accountNumber: values.accountNumber,
                fullName: values.student_name,
                billerId: values.provider,
                academicSemester: values.academicSemester,
                purpose: values.purpose
                /* Searching school fees details */
            }
        } else if (modalTitle === 'School Fees') {
            let accountNumber = values.accountNumber
            values.transactionType = '004'
            values.accountNumber = accountNumber.toUpperCase()
        } else if (modalTitle === 'Electricity') {
            values.amount = values.original_amount
            values.transactionType = '005'
            values.extras = {
                billerId: 'ZETDC'
            }
            values.billerId = 'ZETDC'
        } else if (modalTitle === 'Bill Payment') {
            values.amount = values.original_amount
            values.transactionType = '007'
            values.extras = {
                billerId: values.billerId
            }
        } else if (modalTitle === 'Airtime') {
            if (values.billerProduct) {
            } else {
                values.amount = values.original_amount
            }
            values.extras = {
                billerProduct: values.billerProduct
            }
        }
        /* Passing phone number into extras */
        if (transactionTypes.includes(values.transactionType)) {
            values.extras.phoneNumber = values.phone_number
        }

        if (values.country !== 'zimbabwe' && modalTitle === 'Airtime') {
            values.extras = {
                country: values.country,
                phoneNumber: values.phone_number,
                billerId: values.transactionType
            }
            values.transactionType = '015'
        }

        const verifiables = ['005', '004', '006', '007', '013']

        if (values.transactionType === '015') {
            const reloadlyUrl = `${config.api.base_url}verify-reloadly/`

            const payload = {
                billerId: values.extras.billerId,
                country: values.country,
                phoneNumber: values.phone_number,
                amount: values.amount,
                currency: values.currency
            }
            axios
                .post(reloadlyUrl, payload, headers)
                .then((res) => {
                    if (res.data.data.successful === true) {
                        values.hasRates = true
                        // API CALLS
                        setPaymentPayload(values)
                        setInfo(values)
                        if (verifiables.includes(values.transactionType)) {
                            setVerificationLoader(true)

                            const verifyPayload = {
                                billerId: values.billerId,
                                accountNumber: values.accountNumber
                            }
                            axios
                                .post(verifyUrl, verifyPayload, headers)
                                .then((res) => {
                                    if (res.status === 200) {
                                        /* Store verification details in state and not redux */
                                        setVerificationLoader(false)
                                        if (values.billerId === '013') {
                                            setTelcoData([res.data.data])
                                            setVerificationLoader(false)
                                            setVerificationDetails({
                                                billerId: values.billerId,
                                                customerid:
                                                    res.data.data.customerid,
                                                accountNumber:
                                                    values.accountNumber
                                            })
                                        } else {
                                            setVerificationDetails(
                                                res.data.data
                                            )
                                            setVerifying(true)
                                            setVerificationLoader(false)
                                        }
                                    }
                                })
                                .catch((error) => {
                                    setInvalidCredentials(true)
                                    setVerifying(false)
                                    setVerificationLoader(false)
                                })
                        } else {
                            setVerificationLoader(true)
                            //redirects
                            serviceMonitor(values.transactionType)
                        }
                    } else {
                        setVerificationLoader(false)
                        notification.error({
                            message: 'Error',
                            description: res.data.data.message,
                            className: 'custom-class',
                            style: {
                                top: 70
                            }
                        })
                    }
                })
                .catch((error) => {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to proceed',
                        className: 'custom-class',
                        style: {
                            top: 70
                        }
                    })
                })
        } else if (verificationDetails.billerId === '013') {
            if (
                values.package === '' ||
                values.package_type === '' ||
                values.subscribed_packages === '' ||
                values.package === undefined ||
                values.package_type === undefined ||
                values.subscribed_packages === undefined
            ) {
                setVerificationLoader(false)
                notification.error({
                    message: 'Error',
                    description: 'Fields cannot be empty',
                    className: 'custom-class',
                    style: {
                        top: 70
                    }
                })
            } else {
                values.transactionType = '007'
                values.phone_number = verificationDetails.phoneNumber
                values.extras = {
                    accountNumber: verificationDetails.accountNumber,
                    phoneNumber: verificationDetails.phoneNumber,
                    customer_id: telcoCustomerid,
                    service_id: values.subscribed_packages,
                    product_id: values.package,
                    type: values.package_type,
                    billerId: verificationDetails.billerId
                }
                values.hasRates = true
                // API CALLS
                setPaymentPayload(values)
                setInfo(values)
                setVerificationLoader(true)
                //redirects
                serviceMonitor(values.transactionType)
            }
        } else {
            values.hasRates = true

            if (modalTitle === 'Buy Voucher') {
                values.transactionType = '016'
                values.amount = values.original_amount
                values.currency = values.currency.toUpperCase()
                values.extras = {
                    billerId: values.biller,
                    recepient_phone_number: values.phone_number,
                    recepient_national_id: values.recepient_national_id,
                    recepient_first_name: values.recepient_first_name,
                    recepient_last_name: values.recepient_last_name
                }
            }
            // API CALLS

            setPaymentPayload(values)
            setInfo(values)
            if (verifiables.includes(values.transactionType)) {
                setVerificationLoader(true)

                const verifyPayload = {
                    billerId: values.billerId,
                    accountNumber: values.accountNumber
                }
                axios
                    .post(verifyUrl, verifyPayload, headers)
                    .then((res) => {
                        if (res.status === 200) {
                            setVerificationLoader(false)
                            /* Store verification details in state and not redux */
                            if (values.billerId === '013') {
                                setTelcoData([res.data.data])
                                setTelcoModal(true)
                                setVerificationDetails({
                                    billerId: values.billerId,
                                    phoneNumber: values.phone_number,
                                    accountNumber: values.accountNumber
                                })
                            }
                            if (values.billerId === '014') {
                                setNyaradzoModal(true)
                                setVerificationDetails(res.data.data)
                                setNyaradzoMonthlyPremiumAmount(
                                    res.data.data.customerExtraDetails
                                        .monthlyPremium
                                )
                                setNyaradzoMonthlyAmount(
                                    res.data.data.customerExtraDetails
                                        .monthlyPremium
                                )
                            } else {
                                setVerificationDetails(res.data.data)
                                setVerifying(true)
                            }
                        }
                    })
                    .catch((error) => {
                        setInvalidCredentials(true)
                        setVerifying(false)
                        setVerificationLoader(false)
                    })
            } else {
                setVerificationLoader(true)
                //redirects
                serviceMonitor(values.transactionType)
            }
        }
    }

    let rates
    if (paymentDetails['rates']) {
        rates = paymentDetails['rates']
    }

    const getConvertedRate = (currency) => {
        let rate
        if (currency === 'usd') {
            var search = rates.filter((obj) => obj.from_currency === currency)
            rate = search[0].converted_rate
        } else {
            rate = 1
        }
        return rate
    }

    const verifySubmit = (values) => {

        if (verificationDetails) {
            info.verificationDetails = verificationDetails
            if (modalTitle === 'DSTV') {
                // append pipe to the end of addons
                let addons

                if (info.extras.addons) {
                    info.extras.addons = info.extras.addons.concat('')
                    info.extras.addons.join('|')
                }

                info.extras = {
                    phoneNumber: info.phone_number,
                    accountNumber: info.accountNumber,
                    billerId: info.billerId,
                    bouquet: info.bouquet,
                    addons: addons,
                    fullName: verificationDetails.customerName
                }
            } else if (info.billerId === '014'){
                info.amount = nyaradzoMonthlyAmount
                info.currency = 'zwl'
                info.extras = {
                    billerId: '014',
                    numberOfMonths: count,
                    monthlyPremium:
                        verificationDetails.customerExtraDetails.monthlyPremium,
                    sourceReference:
                        verificationDetails.customerExtraDetails.sourceReference,
                    accountNumber: info.accountNumber,
                    phoneNumber: info.extras.phoneNumber,
                    fullName: verificationDetails.customerName
                }
            } else {
                info.extras = {
                    phoneNumber: info.phone_number,
                    accountNumber: info.accountNumber,
                    billerId: info.billerId,
                    academicSemester: info.academicSemester,
                    purpose: info.purpose,
                    fullName: verificationDetails.customerName
                }
            }
        }
        info.hasRates = true

        setVerificationLoader(false)
        setPaymentPayload(info)
        serviceMonitor(info.transactionType)
    }

    const getTelcoPackages = (event) => {
        let arraySearch = []
        const arrayLength = telcoPackages.length

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < arrayLength; i++) {
            arraySearch.push({
                id: telcoPackages[i].id,
                list_price: telcoPackages[i].list_price
            })
        }

        var searchResults = arraySearch.filter(
            (obj) => parseInt(obj.id) === parseInt(event.target.value)
        )

        let amount

        amount = searchResults[0].list_price

        setDstvAmount(amount)
    }

    const retryVerify = () => {
        setVerifying(false)
        setInvalidCredentials(false)
    }

    const confirmSend = (
        <button
            htmlType='button'
            className='btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100'
            onClick={verifySubmit}
        >
            Confirm Details
        </button>
    )

    const retry = (
        <button
            htmlType='button'
            className='btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100'
            onClick={retryVerify}
        >
            Retry
        </button>
    )

    let formData = (
        <>
            {' '}
            {!!fields &&
                fields.map((field) => {
                    return <div key={field.item}>{field.item}</div>
                })}
        </>
    )
    if (hasExtraField) {
        formData = (
            <Tabs defaultActiveKey='0'>
                {extraFields.map((field, index) => {
                    return (
                        <TabPane tab={field.name} key={index}>
                            <>
                                {!!field.data &&
                                    field.data.map((field) => {
                                        return (
                                            <div key={field.item}>
                                                {field.item}
                                            </div>
                                        )
                                    })}
                            </>
                        </TabPane>
                    )
                })}
            </Tabs>
        )
    }

    const detailsForm = (
        <div className='card-body mw-card-services-card-bg'>
            <h4 className='mw-cs-title-name'>{modalTitle}</h4>

            <div className='card mt-4 border-0'>
                <div className='card-body pt-3 light-grey-bg'>
                    {/* <form action="/"> */}

                    <Form
                        name='basic'
                        form={form}
                        initialValues={{ amount: dstvAmount, currency: 'usd' }}
                        onFinish={onFinish}
                    >
                        {formData}

                        {modalButton}
                    </Form>

                    {/* </form> */}
                </div>
            </div>
        </div>
    )

    const alertImage = (
        <img
            src='./assets/wallet/images/mw-buttons/confirm-transfer-icon.svg'
            alt=''
            class='img-fluid'
            width='70'
        />
    )

    let customerExtras
    if (verificationDetails.customerExtraDetails) {
        if (verificationDetails.customerExtraDetails['status']) {
            customerExtras = verificationDetails.customerExtraDetails['status']
        } else {
            customerExtras = verificationDetails.customerExtraDetails
        }
    }

    let description
    if (verificationDetails.billerId === '014') {
        description = 'Customer Status:'
    } else {
        description = 'Customer Address:'
    }

    const verification = (
        <div className='card-body mw-card-services-card-bg'>
            <div class='d-flex align-items-center mw-card-services-title'>
                {alertImage}
                <h4 className='ms-3 mw-cs-title-name'>
                    Please Verify Your details
                </h4>

                <div class='ms-auto mw-cs-title-line'></div>
            </div>

            <div class='card mt-3 border-0'>
                <div class='card-body px-5 pt-4 light-green-gray-bg'>
                    <p class='mw-confirm-transfer-caption'>
                        You are about to make a transaction with the following
                        parameters.
                    </p>
                </div>{' '}
                <div class='card-footer  pt-1 pb-4 light-grey-bg border-0'>
                    <h4 class='mw-ct-transaction-details my-3'>
                        Transaction Details
                    </h4>

                    <p class='mw-ct-transaction-details-item'>
                        <strong>Customer Name:</strong>{' '}
                        {verificationDetails.customerName}
                    </p>
                    <p class='mw-ct-transaction-details-item'>
                        <strong>{description} </strong> {customerExtras}
                    </p>
                    <p class='mw-ct-transaction-details-item'>
                        <strong>Account Number:</strong>{' '}
                        {verificationDetails.accountNumber}
                    </p>

                    {verificationDetails.billerId === '014' && (
                        <>
                            <p class='mw-ct-transaction-details-item'>
                                <strong>Monthly Premium:</strong> $RTGS
                                {
                                    verificationDetails.customerExtraDetails
                                        .monthlyPremium
                                }
                            </p>
                            <p class='mw-ct-transaction-details-item'>
                                <strong>Balance:</strong> $RTGS
                                {
                                    verificationDetails.customerExtraDetails
                                        .balance
                                }
                            </p>
                            <p class='mw-ct-transaction-details-item'>
                                <strong>Amount:</strong> $RTGS
                                {amount *
                                    getConvertedRate(info.currency).toFixed(2)}
                            </p>
                        </>
                    )}

                    {/* <form action="/"> */}
                    <br />
                    {confirmSend}
                    {/* </form> */}
                </div>
            </div>
        </div>
    )
    const alertDangerImage = (
        <img
            src='./assets/wallet/images/mw-buttons/confirm-transfer-danger-icon.svg'
            alt=''
            class='img-fluid'
            width='70'
        />
    )

    const InvalidCredentialsAlert = (
        <div className='card-body mw-card-services-card-bg'>
            <div class='d-flex align-items-center mw-card-services-title'>
                {alertDangerImage}
                <h4 className='ms-3 mw-cs-title-name'>
                    Incorrect credentials!
                </h4>

                <div class='ms-auto mw-cs-title-line'></div>
            </div>

            <div class='card mt-3 border-0'>
                <div class='card-body px-5 pt-4 light-green-gray-bg'>
                    <p class='mw-confirm-transfer-caption'>
                        Please enter the right credentials. The credentials you
                        entered were wrong.
                    </p>
                </div>{' '}
                <div class='card-footer  pt-1 pb-4 light-grey-bg border-0'>
                    {/* <form action="/"> */}
                    <br />
                    {retry}
                    {/* </form> */}
                </div>
            </div>
        </div>
    )

    // const rightCredentials = invalidCredentials ? InvalidCredentialsAlert : verification;

    // const whatToRender = verifying ? rightCredentials : detailsForm;
    let whatToRender = detailsForm

    if (invalidCredentials) {
        whatToRender = InvalidCredentialsAlert
    } else if (verifying) {
        whatToRender = verification
    }

    function filterItems(needle, heystack, nat) {
        var query = needle.toLowerCase()
        return heystack.filter(function (item) {
            return item[nat].toLowerCase().indexOf(query) >= 0
        })
    }

    const onHandlerChange = (event) => {
        if (event.target.localName === 'select') {
            activeRow[event.target.name] = event.target.value
        }
        setState({ ...state, activeRow })

        let arraySearch = []

        let packagesArray = []
        if (activeRow.package_type === 'renew') {
            packagesArray = telcoExtras.available_renew_options.options
        } else if (activeRow.package_type === 'topup') {
            packagesArray = telcoExtras.available_topup_options.options
        }
        const arrayLength = packagesArray.length

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < arrayLength; i++) {
            arraySearch.push({
                id: packagesArray[i].id,
                name: packagesArray[i].name,
                service_id: packagesArray[i].service_id,
                list_price: packagesArray[i].list_price,
                products: packagesArray[i].products
            })
        }

        var searchResults = filterItems(
            event.target.value,
            arraySearch,
            'service_id'
        )
        activeRow.telcoSelectedPackages = searchResults

        setState({ ...state, activeRow })
    }

    const onPackageTypeChange = (event) => {
        let arraySearch = []

        let packagesArray = []
        if (event.target.value === 'renew') {
            packagesArray = telcoExtras.available_renew_options.options
        } else if (event.target.value === 'topup') {
            packagesArray = telcoExtras.available_topup_options.options
        }

        activeRow[event.target.name] = event.target.value

        setState({ ...state, activeRow })

        const arrayLength = packagesArray.length

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < arrayLength; i++) {
            arraySearch.push({
                id: packagesArray[i].id,
                name: packagesArray[i].name,
                service_id: packagesArray[i].service_id,
                list_price: packagesArray[i].list_price,
                products: packagesArray[i].products
            })
        }

        let useSearch
        if (activeRow.subscribed_packages) {
            useSearch = activeRow.subscribed_packages
        }

        var searchResults = filterItems(useSearch, arraySearch, 'service_id')
        activeRow.telcoSelectedPackages = searchResults

        setState({ ...state, activeRow })
    }

    let telcoPackages = []
    if (activeRow.telcoSelectedPackages) {
        telcoPackages = activeRow.telcoSelectedPackages

        if (telcoPackages.length > 0) {
            if (telcoPackages[0].products) {
                telcoPackages = telcoPackages[0].products
            }
        }
    }

    const increaseCounter = () => {
        if (count < 10) {
            const reignCount = count + 1
            setCount(reignCount)

            setNyaradzoMonthlyAmount(
                parseFloat(nyaradzoMonthlyPremiumAmount) * reignCount
            )
        }
    }

    const dereaseCounter = () => {
        if (count > 1) {
            const reignCount = count - 1
            setCount(reignCount)
            setNyaradzoMonthlyAmount(
                parseFloat(nyaradzoMonthlyPremiumAmount) * reignCount
            )
        }
    }

    return (
        <>
            <Modal
                style={{ marginTop: '-20px' }}
                footer={false}
                closable={true}
                onCancel={handleCancel}
                visible={isTelcoModalVisible}
                width={750}
            >
                <Form
                    onFinish={onFinish}
                    initialValues={{ amount: dstvAmount }}
                >
                    <Spin
                        spinning={verificationLoader}
                        delay={300}
                        size='large'
                    >
                        <div className='card-body mw-card-services-card-bg'>
                            <div class='d-flex align-items-center mw-card-services-title'>
                                {alertImage}
                                <h4 className='ms-3 mw-cs-title-name'>
                                    Please Verify Your details
                                </h4>

                                <div class='ms-auto mw-cs-title-line'></div>
                            </div>

                            <div class='card mt-3 border-0'>
                                <div class='card-body px-5 pt-4 light-green-gray-bg'>
                                    <p class='mw-confirm-transfer-caption'>
                                        Select your package below
                                    </p>
                                </div>{' '}
                                <div class='card-footer  pt-1 pb-4 light-grey-bg border-0'>
                                    <h4 class='mw-ct-transaction-details my-3'>
                                        Transaction Details
                                    </h4>

                                    <p class='mw-ct-transaction-details-item'>
                                        {telcoCustomerName}
                                    </p>
                                    <br />
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Form.Item name='subscribed_packages'>
                                                <select
                                                    name='subscribed_packages'
                                                    onChange={onHandlerChange}
                                                    className='form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2'
                                                >
                                                    <option value=''>
                                                        Select Subscribed
                                                        Package
                                                    </option>

                                                    {!!telcoSubscribedPackages &&
                                                        telcoSubscribedPackages.map(
                                                            (items) => {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            items.service_id
                                                                        }
                                                                        value={
                                                                            items.service_id
                                                                        }
                                                                    >
                                                                        {items.service_id.toUpperCase()}
                                                                    </option>
                                                                )
                                                            }
                                                        )}
                                                </select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>
                                            <Form.Item name='package_type'>
                                                <select
                                                    name='package_type'
                                                    onChange={
                                                        onPackageTypeChange
                                                    }
                                                    className='form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2'
                                                >
                                                    <option value=''>
                                                        Select Package Type
                                                    </option>

                                                    <option value='renew'>
                                                        Renew
                                                    </option>
                                                    <option value='topup'>
                                                        Topup
                                                    </option>
                                                </select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>
                                            <Form.Item name='package'>
                                                <select
                                                    onChange={getTelcoPackages}
                                                    name='packages'
                                                    className='form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2'
                                                >
                                                    <option value=''>
                                                        Select Package
                                                    </option>

                                                    {!!telcoPackages &&
                                                        telcoPackages.map(
                                                            (items) => {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            items.id
                                                                        }
                                                                        value={
                                                                            items.id
                                                                        }
                                                                    >
                                                                        {items.name.toUpperCase()}{' '}
                                                                        $USD
                                                                        {
                                                                            items.list_price
                                                                        }
                                                                    </option>
                                                                )
                                                            }
                                                        )}
                                                </select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className='row mb-4 mb-sm-0'>
                                        <div className='col-sm-4'>
                                            {' '}
                                            <Form.Item name='currency'>
                                                <select
                                                    name='currency'
                                                    onChange={(e) =>
                                                        onUsdCurrencyHandlerChange(
                                                            e
                                                        )
                                                    }
                                                    className='form-select  fw-600 border-2-green py-2 fs-13px bg-transparent text-green-2'
                                                >
                                                    <option value=''>
                                                        Select currency
                                                    </option>
                                                    {ratesOptions}
                                                </select>
                                            </Form.Item>
                                        </div>

                                        <div className='col-sm-4'>
                                            {' '}
                                            {/* <Input
                    defaultValue={dstvAmount}
                    className="form-control shadow-none border-2-green bg-transparent"
                  /> */}
                                            <input
                                                disabled
                                                className='form-control shadow-none border-2-green bg-transparent'
                                                type='text'
                                                name='amount'
                                                value={dstvAmount}
                                                // min="5"
                                                placeholder='Amount'
                                            />
                                        </div>

                                        <div className='col-sm-4'>
                                            <input
                                                disabled
                                                className='form-control shadow-none border-2-green bg-transparent'
                                                type='number'
                                                value={converted_amount}
                                                min='0'
                                                step='.01'
                                                placeholder='Total Amount (ZWL)'
                                            />
                                        </div>
                                    </div>

                                    {/* <form action="/"> */}
                                    <br />
                                    <input
                                        className='blue-gradient-bg-1 btn btn-lg text-white letter-spacing-3 text-uppercase fw-700 border-0 rounded-0 w-100'
                                        type='submit'
                                        value='continue'
                                    />
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Form>
            </Modal>

            <Modal
                style={{ marginTop: '-20px' }}
                footer={false}
                closable={true}
                onCancel={() => {
                    setNyaradzoModal(false)
                }}
                visible={nyaradzoModal}
                width={750}
            >
                <Form onFinish={verifySubmit}>
                    <Spin
                        spinning={verificationLoader}
                        delay={300}
                        size='large'
                    >
                        <div className='card-body mw-card-services-card-bg'>
                            <div className='card-body mw-card-services-card-bg'>
                                <div class='d-flex align-items-center mw-card-services-title'>
                                    {alertImage}
                                    <h4 className='ms-3 mw-cs-title-name'>
                                        Please Verify Your details
                                    </h4>

                                    <div class='ms-auto mw-cs-title-line'></div>
                                </div>

                                <div class='card mt-3 border-0'>
                                    <div class='card-body px-5 pt-4 light-green-gray-bg'>
                                        <p class='mw-confirm-transfer-caption'>
                                            You are about to make a transaction
                                            with the following parameters.
                                        </p>
                                    </div>{' '}
                                    <div class='card-footer  pt-1 pb-4 light-grey-bg border-0'>
                                        <br />

                                        <p class='mw-ct-transaction-details-item'>
                                            <strong>Customer Name: </strong>{' '}
                                            {verificationDetails.customerName}
                                        </p>
                                        <p class='mw-ct-transaction-details-item'>
                                            <strong>Policy Number: </strong>{' '}
                                            {verificationDetails.accountNumber}
                                        </p>

                                        <p class='mw-ct-transaction-details-item'>
                                            <strong>{description} </strong>{' '}
                                            {customerExtras}
                                        </p>

                                        {verificationDetails.billerId ===
                                            '014' && (
                                            <>
                                                <p class='mw-ct-transaction-details-item'>
                                                    <strong>
                                                        Monthly Premium:{' '}
                                                    </strong>
                                                    {`$ZWL ${verificationDetails.customerExtraDetails.monthlyPremium}`}
                                                </p>

                                                <p class='mw-ct-transaction-details-item'>
                                                    <strong>Balance: </strong>{' '}
                                                    $ZWL
                                                    {
                                                        verificationDetails
                                                            .customerExtraDetails
                                                            .balance
                                                    }
                                                </p>
                                            </>
                                        )}
                                        <br />
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label class='mw-ct-transaction-details-item'>
                                                    <strong>
                                                        Amount to be paid:
                                                    </strong>
                                                </label>
                                                <input
                                                    disabled
                                                    className='form-control shadow-none border-2-green bg-transparent'
                                                    type='number'
                                                    value={
                                                        nyaradzoMonthlyAmount
                                                    }
                                                    min='0'
                                                    step='.01'
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <label class='mw-ct-transaction-details-item'>
                                            <strong>
                                                Select months to pay for
                                            </strong>
                                        </label>
                                        <div className='add-buttons d-flex px-5 mx-lg-3 mt-2 justify-content-between align-items-center'>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a
                                                onClick={() => dereaseCounter()}
                                                href
                                                className='btn border-0 bg-green-2 p-2 rounded-0'
                                            >
                                                <img
                                                    src='assets/img/icons/minus.svg'
                                                    alt=''
                                                    width={20}
                                                />
                                            </a>
                                            <span className='text-green-2 fw-800 fs-20px mx-lg-3 mx-3'>
                                                {count}
                                            </span>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a
                                                onClick={() =>
                                                    increaseCounter()
                                                }
                                                href
                                                className='btn border-0 bg-green-2 p-2 rounded-0'
                                            >
                                                <img
                                                    src='assets/img/icons/plus.svg'
                                                    alt=''
                                                    width={20}
                                                />
                                            </a>
                                        </div>
                                        <br />

                                        <input
                                            className='blue-gradient-bg-1 btn btn-lg text-white letter-spacing-3 text-uppercase fw-700 border-0 rounded-0 w-100'
                                            type='submit'
                                            value='continue'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Form>
            </Modal>
            <Modal
                style={{ marginTop: '-20px' }}
                footer={false}
                closable={true}
                onCancel={handleCancel}
                visible={isModalVisible}
                width={750}
            >
                <Spin spinning={verificationLoader} delay={300} size='large'>
                    {whatToRender}
                </Spin>
            </Modal>

            <a href onClick={showModal}>
                <img
                    src={image}
                    alt=''
                    width={150}
                    className='kiosk-bills-icon'
                />
            </a>
        </>
    )
}

ServiceItems.defaultProps = {
    modalButton: '',
    extraFields: [],
    hasExtraField: false
}

ServiceItems.propTypes = {
    image: PropTypes.string,
    modalTitle: PropTypes.string,
    dstvAmount: PropTypes.string,
    converted_amount: PropTypes.string,
    ratesOptions: PropTypes.string,
    modalButton: PropTypes.object,
    paymentDetails: PropTypes.object,
    fields: PropTypes.array,
    extraFields: PropTypes.array,
    hasExtraField: PropTypes.bool,
    postTransaction: PropTypes.func,
    setPaymentPayload: PropTypes.func,
    setConvertedAmount: PropTypes.func,
    onUsdCurrencyHandlerChange: PropTypes.func,
    setDstvAmount: PropTypes.func,
    onAmountChange: PropTypes.func
}

export default ServiceItems
