function filterCoodinates(array, key, key2) {
  let data = [];

  if (array?.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (array[i]["latitude"] && array[i]["longitude"]) {
        data.push(array[i]);
      }
    }
  }

  return data;
}

export default filterCoodinates;
