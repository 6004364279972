import React from "react";

export default function Buttons() {
  return (
    <section id="buttons">
      <div className="contact-us-bg-2 py-5">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div>
              <h1 className="text-white fw-800">Contact Us</h1>
            </div>

            <div className="mw-cs-title-line w-60 d-none d-md-inline-block bg-white"></div>
          </div>
          <div className="d-lg-flex">
            <img
              src="assets/img/ias-logo-1.svg"
              alt=""
              width="400"
              className="me-5"
            />

            <div>
              <div className="mt-5">
                <img
                  src="assets/img/icons/contact-us-message.svg"
                  alt=""
                  width="60"
                  className="d-inline"
                />

                <h6 className="d-inline text-white fw-700 ms-3">
                  info@tumai.to
                </h6>
              </div>

              <div className="mt-4">
                <img
                  src="assets/img/icons/contact-us-phone.svg"
                  alt=""
                  width="60"
                  className="d-inline"
                />

                <h6 className="d-inline text-white fw-700 ms-3">
                  +263 78 844 8616/617
                </h6>
              </div>

              {/* <div className="mt-3 d-inline-flex align-items-center">
                <img
                  src="assets/img/icons/contact-us-location.svg"
                  alt=""
                  width="60"
                  className="d-inline"
                />

                <h6 className="d-inline-block text-white fw-700 ms-3">
                  105 West Road,
                  <br />
                  Morningside, JHB,
                  <br />
                  South Africa
                </h6>

                <div className="vertical-line-2 mx-4"></div>

                <h6 className="d-inline-block text-white fw-700">
                  17 Philips Avenue,
                  <br />
                  Belgravia, Harare,
                  <br />
                  Zimbabwe
                </h6>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
