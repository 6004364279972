import React from "react";
import PropTypes from "prop-types";

import SuccessMsgIcon from "assets/img/icons/success-message-icon.svg";
import ReturnBackIcon from "assets/img/icons/return-back.svg";
import { Link } from "react-router-dom";

const SuccessMessage = (props) => {
  const { amount, reference } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-7">
          <div className="card mx-5 my-4 rounded-3 border-0">
            <div className="card-body messages-card-bg rounded-3">
              <div className="text-center px-3">
                <div className="mb-4">
                  <img src={SuccessMsgIcon} alt="" width={60} />
                </div>
                <div className="mb-3">
                  <h5 className="fw-800 text-white">
                    Transaction Processed Successfully!
                  </h5>
                </div>

                <table className="table table-borderless">
                  <tbody>
                    <tr className="text-white border-bottom text-start">
                      <th>Reference</th>
                      <td>{reference}</td>
                    </tr>

                    <tr className="text-white border-bottom text-start">
                      <th>Amount</th>
                      <td>${amount}</td>
                    </tr>
                  </tbody>
                </table>

                <Link
                  to="/wallet"
                  className="btn fs-14px px-3 blue-gradient-bg-1 border-0 text-uppercase rounded-pill text-white fw-800 letter-spacing-3 box-shadow-btn"
                >
                  Go to wallet
                </Link>

                <div className="mt-2">
                  <Link to="/" className="text-decoration-none">
                    <img
                      src={ReturnBackIcon}
                      alt=""
                      className="img-fluid d-inline"
                      width={12}
                    />
                    <h6 className="text-light-green fw-700 fs-12px d-inline ms-2">
                      Go to home
                    </h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5" />
      </div>
    </>
  );
};

SuccessMessage.propTypes = {
  payment_id: PropTypes.string,
  reference: PropTypes.string,
  amount: PropTypes.string,
};

export default SuccessMessage;
