import React, { useState } from "react";
import PropTypes from "prop-types";
import { pulse, shake, zoomInDown } from "react-animations";
import Radium, { StyleRoot } from "radium";
import axios from "axios";
import getConfig from "config";

import SuccessMessage from "components/messages/successMsg";

import { Form, Modal, Spin, notification } from "antd";
const MakePaymentForm = (props) => {
  const { balance } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFailModalVisible, setIsFailModalVisible] = useState(false);
  const [isResetPinModalVisible, setIsResetPinModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [isPinResetLoading, setIsPinResetLoading] = useState(false);
  const [isUserFound, setIsUserFound] = useState("");
  const [isButtonLoading, setButtonLoad] = useState(false);

  const config = getConfig();
  const [state, setState] = useState({
    activeRow: {},
  });

  const styles = {
    pulse: {
      animation: "x 1s",
      animationName: Radium.keyframes(pulse, "pulse"),
    },
    shake: {
      animation: "x 1s",
      animationName: Radium.keyframes(shake, "shake"),
    },
    zoomInDown: {
      animation: "x 0.5s",
      animationName: Radium.keyframes(zoomInDown, "zoomInDown"),
    },
  };

  const { activeRow } = state;

  let beneficiary_amount;
  let beneficiary_reference;
  if (activeRow.values) {
    beneficiary_amount = activeRow.values.amount;
    beneficiary_reference = activeRow.values.reference;
  }

  const showSuccessModal = () => {
    setSuccessModalVisible(true);
  };

  const sendPeerMoney = (values) => {
    // sendMoney(activeRow.values, "p2p", values.pin);
    setIsPinResetLoading(true);

    const sendMoneyUrl = `${config.api.base_url}wallet/p2m/`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.api.token}`,
      },
      mode: "cors",
    };

    const payload = JSON.stringify({
      recipient_id: activeRow.values.phoneNumber,
      amount: activeRow.values.amount,
      reference: activeRow.values.reference,
      pin: values.pin,
    });

    axios
      .post(sendMoneyUrl, payload, headers)
      .then((res) => {
        if (res.status === 201) {
          showSuccessModal(true);
          setIsPinResetLoading(false);
        }
      })
      .catch((error) => {
        setIsPinResetLoading(false);
        let walletErrorMessage;
        if (error.response.data.info.errors) {
          walletErrorMessage =
            error.response.data.info.errors.non_field_errors[0];
        }

        if (walletErrorMessage === "Blocked account") {
          setIsResetPinModalVisible(true);
        } else {
          notification.error({
            message: "Error",
            description: walletErrorMessage,
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        }
      });
  };

  const handleSuccessCancel = () => {
    window.location = "/make-payment";
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleFailCancel = () => {
    setIsFailModalVisible(false);
  };

  const handleResetPinCancel = () => {
    setIsResetPinModalVisible(false);
  };

  const resendOTP = () => {
    setIsPinResetLoading(true);
    const sendOTPUrl = `${config.api.base_url}wallet/reset/pin/otp/`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.api.token}`,
      },
      mode: "cors",
    };

    axios
      .get(sendOTPUrl, headers)
      .then((res) => {
        if (res.status === 200) {
          setIsPinResetLoading(false);
          notification.success({
            message: "Success",
            description: "An OTP was sent to your phone",
            className: "custom-class",
            style: {
              top: 70,
            },
          });
        }
      })
      .catch((error) => {
        setIsPinResetLoading(false);
        notification.error({
          message: "Error",
          description: "Failed to send OTP",
          className: "custom-class",
          style: {
            top: 70,
          },
        });
      });
  };

  let background;
  let error;
  let modalText = (
    <div class="card-body px-5 pt-4 light-green-gray-bg">
      <p class="mw-confirm-transfer-caption">
        You are about to make a transfer with the following parameters. Confirm
        Details before you proceed.
      </p>
    </div>
  );

  const resetPin = (values) => {
    setIsPinResetLoading(true);

    if (values.pin === values.pin2) {
      const resetPinUrl = `${config.api.base_url}wallet/reset/pin/`;

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.api.token}`,
        },
        mode: "cors",
      };

      const payload = JSON.stringify({
        passphrase: values.passphrase,
        new_pin: values.pin,
        otp: values.otp,
      });

      axios
        .post(resetPinUrl, payload, headers)
        .then((res) => {
          if (res.status === 200) {
            setIsPinResetLoading(false);
            setIsModalVisible(true);
            setIsResetPinModalVisible(false);
            notification.success({
              message: "Success",
              description: "Pin reset was successful",
              className: "custom-class",
              style: {
                top: 70,
              },
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error.response.data.info.errors.message,
            className: "custom-class",
            style: {
              top: 70,
            },
          });
          setIsPinResetLoading(false);
          setTimeout(() => {}, 1000);
        });

      activeRow.values = values;

      setState({ ...state, activeRow });
    } else {
      setButtonLoad(false);
      setTimeout(() => {}, 1000);

      setIsPinResetLoading(false);
      notification.error({
        message: "Error",
        description: "Make sure your pin match",
        className: "custom-class",
        style: {
          top: 70,
        },
      });
    }
  };

  const onFinish = (values) => {
    setButtonLoad(true);

    if ((balance) => beneficiary_amount) {
      const lookUpUrl = `${config.api.base_url}wallet/lookup/Merchant/${values.phoneNumber}/`;

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.api.token}`,
        },
        mode: "cors",
      };

      axios
        .get(lookUpUrl, headers)
        .then((res) => {
          if (res.status === 200) {
            setIsUserFound(res.data.data.name);
            setButtonLoad(false);
            setIsModalVisible(true);
          }
        })
        .catch((error) => {
          setButtonLoad(false);
          setIsFailModalVisible(true);
          setTimeout(() => {}, 1000);
        });

      activeRow.values = values;

      setState({ ...state, activeRow });
    } else {
      setButtonLoad(false);
      setTimeout(() => {}, 1000);
      notification.error({
        message: "Error",
        description: "Amount cannot be greater than balance",
        className: "custom-class",
        style: {
          top: 70,
        },
      });
    }
  };

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleResetPinCancel}
        visible={isResetPinModalVisible}
        width={750}
      >
        <div>
          <div style={background} class="card-body mw-card-services-card-bg">
            {error}
            <div class="d-flex align-items-center mw-card-services-title">
              <img
                src="./assets/wallet/images/mw-buttons/confirm-transfer-danger-icon.svg"
                alt=""
                class="img-fluid"
                width="70"
              />
              <h4 class="ms-3 mw-cs-title-name">Your Pin Was Blocked</h4>

              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <div class="card mt-3 border-0">
              <div class="card-body px-5 pt-4 light-green-gray-bg">
                <p class="mw-confirm-transfer-caption">
                  Your wallet account was blocked. Enter the details below to
                  unblock it
                </p>
              </div>
              <div class="card-footer  pt-1 pb-4 light-grey-bg border-0">
                <Spin spinning={isPinResetLoading} size="large">
                  <Form name="basic" onFinish={resetPin} autoComplete="off">
                    <br />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={resendOTP}
                      className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                      style={styles.shake}
                    >
                      Send me an OTP
                    </a>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        OTP**
                      </label>
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: "Please input the OTP!",
                          },
                        ]}
                      >
                        <input
                          type="number"
                          maxLength="6"
                          placeholder="Enter OTP"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        Pass Phrase*
                      </label>
                      <Form.Item
                        name="passphrase"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pass phrase!",
                          },
                        ]}
                      >
                        <input
                          type="text"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        New Pin*
                      </label>
                      <Form.Item
                        name="pin"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pin!",
                          },
                        ]}
                      >
                        <input
                          type="password"
                          maxLength="6"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        ReEnter Pin*
                      </label>
                      <Form.Item
                        name="pin2"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pin!",
                          },
                        ]}
                      >
                        <input
                          type="password"
                          maxLength="6"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                        />
                      </Form.Item>
                    </div>
                    <StyleRoot>
                      <button
                        htmlType="submit"
                        className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                        style={styles.shake}
                      >
                        Reset Pin
                      </button>
                    </StyleRoot>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        footer={false}
        closable={true}
        onCancel={handleSuccessCancel}
        visible={successModalVisible}
        width={750}
      >
        <div className="card p-0 border-0">
          <div className="card-body messages-bg p-0 text-white">
            <SuccessMessage
              reference={beneficiary_reference}
              amount={beneficiary_amount}
            />
          </div>
        </div>
      </Modal>

      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleFailCancel}
        visible={isFailModalVisible}
        width={750}
      >
        <div>
          <div style={background} class="card-body mw-card-services-card-bg">
            {error}
            <div class="d-flex align-items-center mw-card-services-title">
              <img
                src="./assets/wallet/images/mw-buttons/confirm-transfer-danger-icon.svg"
                alt=""
                class="img-fluid"
                width="70"
              />
              <h4 class="ms-3 mw-cs-title-name">Confirm Transfer</h4>

              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <div class="card mt-3 border-0">
              {modalText}
              <div class="card-footer  pt-1 pb-4 light-grey-bg border-0">
                <h4 class="mw-ct-transaction-details my-3">
                  Transaction Details
                </h4>
                <p class="mw-ct-transaction-details-item">
                  From Wallet: ${beneficiary_amount}
                </p>
                <p class="mw-ct-transaction-details-item">
                  To Beneficiary: ${beneficiary_amount}
                </p>
                <p class="mw-ct-transaction-details-item">
                  Reference: {beneficiary_reference}
                </p>
                <Form name="basic" autoComplete="off">
                  <StyleRoot>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={handleFailCancel}
                      class="mw-ct-card-btn-danger mt-3"
                      style={styles.shake}
                    >
                      Merchant Not Found
                    </a>
                  </StyleRoot>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        style={{ marginTop: "-20px" }}
        footer={false}
        closable={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        width={750}
      >
        <div>
          <div style={background} class="card-body mw-card-services-card-bg">
            {error}
            <div class="d-flex align-items-center mw-card-services-title">
              <img
                src="./assets/wallet/images/mw-buttons/confirm-transfer-icon.svg"
                alt=""
                class="img-fluid"
                width="70"
              />
              <h4 class="ms-3 mw-cs-title-name">Confirm Transfer</h4>

              <div class="ms-auto mw-cs-title-line"></div>
            </div>
            <div class="card mt-3 border-0">
              {modalText}
              <div class="card-footer  pt-1 pb-4 light-grey-bg border-0">
                <h4 class="mw-ct-transaction-details my-3">
                  Transaction Details
                </h4>

                <p class="mw-ct-transaction-details-item">
                  From Wallet: ${beneficiary_amount}
                </p>
                <p class="mw-ct-transaction-details-item">
                  To Merchant: ${beneficiary_amount}
                </p>
                <p class="mw-ct-transaction-details-item">
                  Merchant Name: {isUserFound}
                </p>
                <p class="mw-ct-transaction-details-item">
                  Reference: {beneficiary_reference}
                </p>
                <Spin spinning={isPinResetLoading} size="large">
                  <Form
                    name="basic"
                    onFinish={sendPeerMoney}
                    autoComplete="off"
                  >
                    <div className="my-3">
                      <label className="fs-16px mb-2 text-green-2 fw-600">
                        Pin*
                      </label>
                      <Form.Item
                        name="pin"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pin!",
                          },
                        ]}
                      >
                        <input
                          type="password"
                          maxLength="6"
                          className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                          placeholder="******"
                        />
                      </Form.Item>
                    </div>
                    <button
                      htmlType="submit"
                      className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                    >
                      Confirm Transfer
                    </button>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="col-sm-12 col-lg-6">
        <div className="card mt-3 border-0">
          <div class="card-body px-5 pt-4 light-green-gray-bg">
            <h5 class="fw-700 text-green-2">Make payment to a merchant</h5>
          </div>

          <Spin spinning={isButtonLoading} size="large">
            <Form name="basic" onFinish={onFinish} autoComplete="off">
              <div className="card-footer px-5 pt-1 pb-4 light-grey-bg border-0">
                <div className="my-3">
                  <label className="fs-16px mb-2 text-green-2 fw-600">
                    Amount*
                  </label>
                  <Form.Item
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please input amount!",
                      },
                      // {
                      //   max: balance,
                      //   message: "Amount cannot be greater than balance!",
                      // },
                    ]}
                  >
                    <input
                      type="number"
                      step=".01"
                      max={balance}
                      className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                      placeholder="10.00"
                    />
                  </Form.Item>
                </div>

                <div className="mb-3">
                  <label className="fs-16px mb-2 text-green-2 fw-600">
                    Merchant Code*
                  </label>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: `Please input merchant code!`,
                      },
                    ]}
                  >
                    <input
                      type="text"
                      className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                      placeholder="10012"
                    />
                  </Form.Item>
                </div>

                <div className="my-3">
                  <label className="fs-16px mb-2 text-green-2 fw-600">
                    Reference*
                  </label>
                  <Form.Item
                    name="reference"
                    rules={[
                      {
                        required: true,
                        message: `Please input reference!`,
                      },
                    ]}
                  >
                    <input
                      type="text"
                      className="fw-600 form-control border-2-green border-radius-1 form-control-lg py-2 fs-13px bg-transparent text-green-2"
                      placeholder="3XR67J"
                    />
                  </Form.Item>
                </div>

                <button
                  className="btn btn-lg text-uppercase text-white fw-800 letter-spacing-3 blue-gradient-bg-1 border-0 w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Proceed
                </button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  );
};

MakePaymentForm.defaultProps = {
  balance: 0,
};

MakePaymentForm.propTypes = {
  sendMoney: PropTypes.func.isRequired,
  closePages: PropTypes.func.isRequired,
  lookup: PropTypes.func.isRequired,
  removeMessageWallet: PropTypes.func,
  balance: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  pageName: PropTypes.string,
  usage: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  showErrorMessage: PropTypes.string,
  foundUser: PropTypes.string,
  showSuccessMessage: PropTypes.bool,
};

export default MakePaymentForm;
